import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../../components/Loader';
import { API, fetchData, getUser } from '../../services';

class AcademiesItem extends Component {
  render() {
    const listItems = this.props.data.map((item, index) => {
      let style = {
        background: item.color,
      };
      return (
        <div className="academiesItem" key={index} style={style}>
          <img className="backgroundImage" src={item.background_image} alt="" />
          <div className="logo">
            <div className="logo_border">
              <img src={item.logo} alt="" />
            </div>
          </div>
          <div className="description">
            <svg width="170" height="70" className="svg">
              <polygon points="0,60 0,5 225,85" fill={item.color} />
            </svg>
            <h4 className="header4">{item.title}</h4>
            {/* TODO: not to render <a> tag if item.link is empty */}
            {/*<a href={item.link} target="_blank"><i className="fa fa-link"/> Official website</a>*/}
            <p>{item.address}</p>
          </div>
          {this.props.is_roster_view_allowed && <Link className="readMore" to={'/academies/' + item.id + '/' + 1}>
            Academy profile <i className="fas fa-arrow-right" />
          </Link>}

        </div>
      );
    });

    return <div className="academiesList">{listItems}</div>;
  }
}

class AcademyList extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      list: '',
      is_roster_view_allowed: false
    };
  }

  componentDidMount() {
    fetchData(API.academies).then(response => {
        const user = getUser();
        if (
          user &&
          user.is_roster_view_allowed &&
          user.is_roster_view_allowed === true
        ) {
          this.setState({ is_roster_view_allowed: true });
        }
        this.setState({ list: response, isLoading: false });
    }
    );
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }
    return (
      <div className="base">
        <div className="emptyHeader" />

        <div className="title">
          <h2 className="header2 container">ACADEMIES</h2>
        </div>

        <div className="container">
          <AcademiesItem data={this.state.list} is_roster_view_allowed={this.state.is_roster_view_allowed}/>
        </div>
      </div>
    );
  }
}

export default AcademyList;
