import React, { Component } from 'react';

import Loader from '../../../components/Loader';
import {API, fetchData, fetchDetail, createObject} from '../../../services';
import {POSITIONS} from '../../../constants';

// class StatusRequest extends Component {
//   statuses = {
//     1: {classList: 'fa fa-refresh', text: 'In progress'},
//     2: {classList: 'far fa-check-circle', text: 'Confirmed'},
//     3: {classList: 'fa fa-ban', text: 'Denied'}
//   };
//
//   render () {
//     const status = this.statuses[this.props.status];
//     return (
//       <div className="other"><h4><i className={status.classList} /> <span>{status.text}</span></h4></div>
//     )
//   }
// }


class TeamsList extends Component {
  render() {
    const list = this.props.data.map((item, index) => {
      return (
        <div className="requestItem" key={index}>
          <div className="logo"><div className="logo_border"> <img src={item.team.logo} alt=""/></div></div>
          <div className="team"><h4>{item.team.title}</h4></div>
          <div className="other">
            <h4>Position:
              <span>
                {item.main_position !== '' && item.position !== '' ?
                  ' '+item.main_position +'/'+item.position :
                  item.main_position !== '' ?
                    ' '+item.main_position :
                    ' '+item.position
                }
              </span>
            </h4>
          </div>
          <div className="other"><h4>Number: <span>{item.number}</span></h4></div>
        </div>
      )
    });

    return (
      <div className="resultsRequest">
        {list}
      </div>
    )
  }
}

class SelectForPlayer extends Component {
  render () {
    const options = this.props.data.map((item, index) => {
      return <option value={item.id} key={index}>{item.title}</option>
    });

    return(
      <select className={this.props.showErrorBorder ? 'formsSelect error' : 'formsSelect'} value={this.props.value} placeholder="Position" onChange={this.props.onChange}>
        <option>----</option>
        {options}
      </select>
    )
  }

}

//TODO: need endpoint for playerTeams
export class PlayerTeam extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      modalVisible: false,
      teamsRequest: '',
      teamsList: [
        {
          id: 651,
          logo: "http://127.0.0.1:8000/media/cache/ea/0f/ea0f50068fe019b3b5f4bb3bd5a523a0.png",
          main_position: 'GK',
          position: 'CM',
          number: -1,
          team: ''
        }
      ],
      academy: '',
      team: '',
      main_position: '',
      position: '',
      number: ''
    }
  }


  componentDidMount() {
    if(this.props.loggedIn) {
      fetchData('player-team-profiles')
        .then(response => {
          this.setState({teamsList: response})
        })
        // .then(() =>
        //   fetchData(API.academyList)
        //     .then((response) => this.setState({academyList: response, isLoading: false})))
        .catch(error => console.warn('error', error))
    }
  }

  // showErrors(error) {
  //   let errors = [];
  //   for(let item in error) {
  //     errors.push(item);
  //     this.setState({errors: errors, error_text: error[item]});
  //   }
  //   if(this.state.errors.length > 1) {
  //     this.setState({error_text: 'Check the entered data'});
  //   }
  // }

  // showErrorBorder (item) {
  //   for(let key in this.state.errors) {
  //     if(this.state.errors[key] === item) {
  //       return true;
  //     }
  //   }
  // }

  getTeamsList = index => {
    // this.setState({isLoading: true});
    // fetchDetail(API.academyList, index)
    //   .then(response => this.setState({teamsList: response, isLoading: false}))
    //   .catch(e => catchErrors(e));
  };

  // postDataRequest() {
  //   let requestData = {
  //     team: this.state.team,
  //     main_position: this.state.main_position,
  //     position: this.state.position,
  //     number: this.state.number
  //   };
  //
  //   createObject(API.profile.player_team, requestData)
  //     .then(() => {
  //       this.setState({
  //         modalVisible: true,
  //         team: '',
  //         main_position: '',
  //         position: '',
  //         number:'',
  //         academy: '',
  //         teamsList: [],
  //         error_text: '',
  //         errors: []
  //       });
  //
  //       fetchData(API.profile.player_team)
  //         .then((response) =>this.setState({teamsRequest: response}));
  //     })
  //     .catch((error) => this.showErrors(error));
  // }

  closeModal() {
    this.setState({modalVisible: !this.state.modalVisible});
  }

  render() {
    if(this.state.isLoading) {
      return <Loader/>
    }

    return (
      <div>
        <div className="container">

          <div className="info_profile">
            {/*<h4 className="label">Coming soon</h4>*/}
            {/*<SelectForPlayer data={this.state.academyList}*/}
                             {/*value={this.state.academy}*/}
                             {/*showErrorBorder={this.showErrorBorder('team')}*/}
                             {/*onChange={(text) => {*/}
                               {/*this.setState({academy: parseInt(text.target.value, 10)});*/}
                               {/*this.getTeamsList(parseInt(text.target.value, 10));*/}
                             {/*}*/}
                             {/*}*/}
            {/*/>*/}
            {/*<h4 className="label">Team</h4>*/}
            {/*<SelectForPlayer data={this.state.teamsList}*/}
                             {/*value={this.state.team}*/}
                             {/*showErrorBorder={this.showErrorBorder('team')}*/}
                             {/*onChange={(text) => {this.setState({team: text.target.value})}}*/}
            {/*/>*/}
            {/*<h4 className="label">Main Position</h4>*/}
            {/*<SelectForPlayer data={POSITIONS}*/}
                             {/*value={this.state.main_position}*/}
                             {/*onChange={(text) => {this.setState({main_position: text.target.value})}}*/}
            {/*/>*/}
            {/*<h4 className="label">Second Position</h4>*/}
            {/*<SelectForPlayer data={POSITIONS}*/}
                             {/*value={this.state.position}*/}
                             {/*onChange={(text) => {this.setState({position: text.target.value})}}*/}
            {/*/>*/}
            {/*<input type="text"*/}
                   {/*className={this.showErrorBorder('number') ? 'formsInput error' : 'formsInput'}*/}
                   {/*placeholder="Number"*/}
                   {/*value={this.state.number}*/}
                   {/*onChange={(text) => this.setState({number: text.target.value})}/>*/}
            {/*<span className="error_field">{this.state.error_text}</span>*/}
            {/*<button className="formsButton" onClick={() => this.postDataRequest()}>Send</button>*/}
          </div>
          <TeamsList data={this.state.teamsList}/>
        </div>
      </div>
    );
  }
}