import React, {Component} from 'react';

import Loader from '../components/Loader';
import { fetchData } from '../services/index';


class SimplePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      details: '',
      idPage: props.match.params.id
    };
  }

  componentDidMount() {
    this.getData(this.state.idPage)
  }

  getData(idPage) {
    fetchData(`pages/${idPage}`)
      .then((details) => {
        this.setState({
          details: details,
          isLoading: false
        })
      })
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.idPage !== nextProps.match.params.id) {
      this.setState({ idPage: nextProps.match.params.id });
      this.getData(nextProps.match.params.id);
    }
  }

  render() {
    if(this.state.isLoading) {
      return <Loader/>
    }

    return (
      <div className="base">
        <div className="emptyHeader" />

        <div className="title">
          <h2 className="header2 container">{this.state.details.title}</h2>
        </div>

        <div className="container containerForOtherPages">
          <div className="text_article" dangerouslySetInnerHTML={{__html: this.state.details.text}} />
          <div className="pdf-links">
            {
              this.state.details.pdfs.map((item, index) => {
                return (
                  <a href={item.link} target="_blank" rel="noopener noreferrer" key={index}><i className="fas fa-file-pdf"/><span>{item.title}</span></a>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default SimplePage;
