import React, { Component } from 'react';
import '../style/style.css';

class Loader extends Component {
  render() {
    return (
      <div className="bg_modal">
        <div className="modal">
          <h3>Load<span>ing...</span></h3>
        </div>
      </div>
    );
  }
}

export default Loader;
