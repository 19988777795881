import React, { Component } from 'react';

import Loader from '../../../components/Loader';

import Modal from '../../../components/ModalSuccessful';

import {API, fetchData, createObject, updateObject} from '../../../services';

export class Insurance extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      response: {
        doc: null
      },
      fileSrc: {
        fileUrl: null
      },
      modalVisible: false,
    }
  }

  componentDidMount() {
    if(this.props.loggedIn) {
      fetchData(API.insurance)
      .then((response) => this.setState({template: response, isLoading: false}));
      fetchData(API.myInsurance)
        .then((response) => this.setState({response: response.hasOwnProperty('doc') ? response : { "doc": null}}))
        .catch((error) => console.warn('error', error))
    }
  }

  showErrors = (error) => {
    if(error.doc[0] === 'This field may not be null.') {
      this.setState({error_text: 'You have not added a file'});
    } else {
      this.setState({error_text: error.doc});
    }

  }

  postDataRequest = () => {
    let dataRequest = {
      doc: this.state.fileSrc.fileUrl
    };
    if(this.state.response.doc === null) {
      createObject(API.myInsurance, dataRequest)
        .then(() => {
          this.setState({
            modalVisible: true,
            error_text: '',
            fileSrc: {fileUrl: null}
          })})
        .catch((error) => this.showErrors(error));
    } else {
      updateObject(API.myInsurance, dataRequest)
        .then(() => {
          this.setState({
            modalVisible: true,
            error_text: '',
            fileSrc: {fileUrl: null}
          })})
        .catch((error) => this.showErrors(error));
    }
  };

  closeModal = () => this.setState(({ modalVisible }) => ({
    modalVisible: !modalVisible,
    typeModal: ''
  }));

  render() {
    const {
      isLoading,
      modalVisible,
      template,
      response,
      error_text
    } = this.state;

    if(isLoading) {
      return <Loader/>
    }

    return (
      <div>
        <div className="container">
          <Modal
            modalVisible={modalVisible}
            onClick={this.closeModal}
          />

          <div className="minContainer insuranceContainer">
            <h4>Description:</h4>
            <p className="descriptionForInsurance">{template.description}</p>
            <a href={template.file} className="formsButton" download>Download template for insurance</a>
            <div className="formAddFile">
              <div className="pickFile">
                <div className="addFile">
                  <div className="formsButton">Add your file</div>
                  <input type="file" onChange={(event) =>{
                      let reader = new FileReader(),
                        file = event.target.files[0];
                      reader.onloadend = () => {
                        this.setState({fileSrc: {
                          file: file,
                          fileUrl: reader.result
                        }});
                      };
                      reader.readAsDataURL(file);
                    }}
                  />
                </div>
                <a href={response.doc}
                  className={response.doc !== null ? 'formsButton' : 'notVisible'}
                target="_blank" rel="noopener noreferrer">
                  Your insurance
                </a>
              </div>
              <span className="error_field">{error_text}</span>
              <button className="formsButton" onClick={this.postDataRequest}>Save</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}