import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import {SelectBirth} from '../containers/Profile/ProfilePlayer';
import '../style/style.css';

class ModalSuccessful extends Component {
  render() {
    if(this.props.type) {
      if(this.props.type === 'Not found') {
        return (
          <div className={this.props.modalVisible ? 'bg_modal' : 'notVisible'}>
            <div className="modal">
              <h3 className="header3">Sorry,<span> not found <i className="fas fa-meh"/></span></h3>
              <div className="formsButton" onClick={this.props.onClick}>Reload page</div>
            </div>
          </div>
        );
      }
      if(this.props.type === 'pop-up') {
        return (
          <div className={this.props.modalVisible ? 'bg_modal' : 'notVisible'}>
            <img src={this.props.picture} alt="" />
            <div className="pop-up">
              <div className="formsButton" onClick={this.props.onClick}>Close</div>
            </div>
          </div>
        );
      }
      if(this.props.type === 'passwordReset') {
        return (
          <div className={this.props.modalVisible ? 'bg_modal' : 'notVisible'}>
            <div className="modal">
              <h3 className="header3">Instructions were sent on your email.</h3>
              <div className="formsButton" onClick={this.props.onClick}>Close</div>
            </div>
          </div>
        );
      }
      if(this.props.type === 'not confirmed') {
        return (
          <div className={this.props.modalVisible ? 'bg_modal' : 'notVisible'}>
            <div className="modal">
              <h3 className="header3">Wait for account activation.</h3>
              <div className="formsButton" onClick={this.props.onClick}>Close</div>
            </div>
          </div>
        );
      }
      if(this.props.type === 'go to profile') {
        return (
          <div className={this.props.modalVisible ? 'bg_modal' : 'notVisible'}>
            <div className="modal">
              <h3 className="header3">Check your profile</h3>
              {/*<div className="formsButton" onClick={this.props.onClick}>Go to profile</div>*/}
              <Link to="/profile" className="formsButton">Go to profile</Link>
            </div>
          </div>
        );
      }
      if(this.props.type === 'custom') {
        return (
          <div className={this.props.modalVisible ? 'bg_modal' : 'notVisible'}>
            <div className="modal">
              {this.props.text.fails && <p>Fails: {this.props.text.fails.map((f, i) => <span key={`${i}f`}>{f} </span>)}</p>}
              {this.props.text.imported && <p>Imported: {this.props.text.imported.map((f, i) => <span key={`${i}f`}>{f} </span>)}</p>}
              {/*{ (this.props.text instanceof Array) && this.props.text.map((i, k) => <p key={`${k}i`}>{i}</p>)}*/}
              <div className="formsButton" onClick={this.props.onClick}>Close</div>
            </div>
          </div>
        );
      }
      if(this.props.type === 'give Access') {
        const { changeName, firstName, lastName, days, months, pickedDay, pickedMonth, pickedYear, submitInfo, errorPopUp } = this.props;
        return (
          <div className={this.props.modalVisible ? 'bg_modal' : 'notVisible'}>
            <div className="modal modal-player-give-access">
              <h5>Photo Release Waiver.
                  By entering BCSAA and participating in the ASL, I hereby grant BCSAA on behalf of myself and my ward(s),
                  the irrevocable right and permission to photograph and/or record me in connection with BCSAA and ASL and to use the photograph
                  and/or recording for all purposes, including advertising and promotional purposes, in any manner and in any media now or hereafter known,
                  in perpetuity throughout the world, without restriction as to alteration. I waive any right to inspect or approve
                  the use of the photograph and/or recording and acknowledge and agree that the rights granted to this release are without
                  compensation of any kind.</h5>
              {/*<div className="formsButton" onClick={this.props.onClick}>Go to profile</div>*/}
              <input type="text" className='formsInput'
                  placeholder="First Name"
                  value={firstName}
                  onChange={(text) => changeName(text, 'name')}/>
              <input type="text" className='formsInput'
                placeholder="Last Name"
                value={lastName}
                onChange={(text) => changeName(text, 'surname')}/>
              <div className="date-birth-row">
                  <SelectBirth
                    type="days"
                    data={days}
                    value={pickedDay}
                    // showErrorBorder={this.showErrorBorder('date_birth')}
                    onChange={(text) => changeName(text, 'day')}
                  />
                  <SelectBirth
                    type="months"
                    data={months}
                    value={pickedMonth}
                    // showErrorBorder={this.showErrorBorder('date_birth')}
                    onChange={(text) => changeName(text, 'month')}
                  />
                  <SelectBirth
                    type="years"
                    value={pickedYear}
                    // showErrorBorder={this.showErrorBorder('date_birth')}
                    onChange={(text) => changeName(text, 'year')}
                  />
              </div>
              <div className="item-row">
                <p>
                  <input type="radio" id="agree" name="radio-group" onChange={(e) => changeName(e, 'accessibility', true)}/>
                  <label htmlFor="agree">Agree</label>
                </p>
                <p>
                  <input type="radio" id="disagree" name="radio-group" onChange={(e) => changeName(e, 'accessibility', false)} />
                  <label htmlFor="disagree">Disagree</label>
                </p>
              </div>
              {/*<Link to="/profile" className="formsButton">Go to profile</Link>*/}
              <p style={{ color: 'red' }}>{errorPopUp}</p>
              <div className="formsButton" onClick={submitInfo}>Submit</div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className={this.props.modalVisible ? 'bg_modal' : 'notVisible'}>
          <div className="modal">
            <h3 className="header3">Success<span>ful <i className="fas fa-check" /></span></h3>
            <div className="formsButton" onClick={this.props.onClick}>Close</div>
          </div>
        </div>
      );
    }
  }
}

export default ModalSuccessful;
