import React, {Component} from 'react';

import {API, createObject} from '../../services';
import Modal from '../../components/ModalSuccessful';


class PasswordRecovery extends Component {
  constructor() {
    super();

    this.state = {
      email: '',
      error: null,
      modalVisible: false,
      success: false,
      typeModal: ''
    }
  }

  checkEmail() {
    createObject(API.password.recovery, {email: this.state.email})
      .then(() => {
        this.setState({modalVisible: true, typeModal: 'passwordReset', success: true})
      })
      .catch((error) => {
        this.setState({error: error});
      })
  }

  closeModal() {
    this.setState({modalVisible: !this.state.modalVisible});
  }

  handleChange(event) {
    const newState = {error: null};
    newState[event.target.name] = event.target.value;

    this.setState(newState);
  }

  render() {
    return (
      <div className="base">
        <div className="emptyHeader" />

        <div className="title">
          <h2 className="header2">Password recovery</h2>
        </div>

        <div className="container">
          <Modal modalVisible={this.state.modalVisible} type={this.state.typeModal} onClick={() => this.closeModal()}/>

          <div className="reset_password_block">
            <input type="email"
                   name="email"
                   defaultValue={this.state.email}
                   className={this.state.error ? 'formsInput error' : 'formsInput'}
                   onChange={(event) => {this.handleChange(event)}} placeholder="Type your email address"
            />
            {this.state.error ? (
              <div className="error_text">
                {this.state.error.email.join(', ')}
              </div>) : ''
            }
            <button
              disabled={this.state.success}
              onClick={() => this.checkEmail()}
              className={this.state.success ? 'formsButton disabled' : 'formsButton'}
            >
              Send email
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default PasswordRecovery;
