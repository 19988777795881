import React, {Component} from 'react';
import {API, createObject} from '../../services';
import Modal from '../../components/ModalSuccessful';


class PasswordReset extends Component {
  constructor(props) {
    super();

    this.state = {
      uuid: props.match.params.id,
      password: '',
      confirmPassword: '',
      error: null,
      modalVisible: false
    };
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      error: null
    });
  }

  closeModal() {
    this.setState({modalVisible: !this.state.modalVisible});
  }

  isValidPassword() {
    return this.state.password === this.state.confirmPassword;
  }

  changePassword(event) {
    event.preventDefault();
    if (this.isValidPassword()) {
      if(this.state.password.length !== 0 || this.state.confirm_password !== 0) {
        this.setState({error: null});

        const data = {
          password: this.state.password,
          confirm_password: this.state.confirmPassword,
          uuid: this.state.uuid
        };

        createObject(API.password.reset, data)
          .then(() => {
            this.setState({
              password: '',
              confirm_password: '',
              modalVisible: true
            })
          })
          .catch((error) => {
            if (error.hasOwnProperty('uuid')) {
              this.props.history.push('/password/recovery')
            }

            console.log('error', error);
          })
      } else {
        this.setState({error: 'Missing data.'});
      }

    } else {
      this.setState({error: 'Passwords are not equal.'});
    }
  }


  render() {
    return (
      <div className="base">
        <div className="emptyHeader" />

        <div className="title">
          <h2 className="header2">Password reset</h2>
        </div>

        <div className="container">
          <Modal modalVisible={this.state.modalVisible} onClick={() => this.closeModal()}/>
          <form onSubmit={(event) => {this.changePassword(event)}} className="reset_password_block">
            <div className="form_block">
              <input type="password"
                     minLength={4}
                     required={true}
                     className="formsInput"

                     name="password"
                     placeholder="New password"
                     onChange={(event) => {this.handleChange(event)}}
              />
            </div>

            <div className="form_block">
              <input type="password"
                     minLength={4}
                     required={true}
                     className="formsInput"
                     name="confirmPassword"
                     placeholder="Confirm password"
                     onChange={(event) => {this.handleChange(event)}}
              />
            </div>

            {this.state.error ? (
              <p className="error_text">{this.state.error}</p>
            ) : ''}

            <button className="formsButton">Change password</button>
          </form>
        </div>
      </div>
    )
  }
}

export default PasswordReset;
