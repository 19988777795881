import React, { Component } from 'react';
import {Route} from 'react-router-dom';

import TeamsList from './TeamsList';
import TeamDetail from './TeamDetail';


class Teams extends Component {
  render() {
    return (
      <div>
        <Route exact path="/teams" component={TeamsList}/>
        <Route path="/teams/:gender/:id" component={TeamDetail}/>
      </div>
    );
  }
}

export default Teams;
