import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import App from './containers/Base';
import { unregister } from './registerServiceWorker';
// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
unregister();
