import React, { Component } from 'react';

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from 'react-share';

import Loader from '../../components/Loader';
import SidebarTeams from '../../components/SidebarTeams';
import SidebarGames from '../../components/SidebarGames';
import {API, fetchDetail} from '../../services';
import {fetchData} from "../../services/utils";


class NewsDetail extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      newsDetail: ''
    }
  }

  componentDidMount() {
    fetchDetail(API.news, this.props.match.params.id)
      .then((response) => {
        fetchData(API.sidebar)
          .then((sidebar) => {
            let newGamesArray = [], newTeamsArray = [];

            sidebar.games.forEach((item) => {
              if(item.games.length > 0) {
                newGamesArray.push(item);
              }
            });

            sidebar.teams.forEach((item) => {
              if(item.teams.table.length > 0) {
                newTeamsArray.push(item);
              }
            });
            this.setState({
              newsDetail: response,
              games: newGamesArray,
              teams: newTeamsArray,
              isLoading: false
            })
          })
      });
  }

  render() {
    const {
      isLoading,
      newsDetail,
      games,
      teams
    } = this.state;

    if(isLoading) {
      return <Loader/>
    }

    // const meta = {
    //   title: this.state.newsDetail.name,
    //   description: this.state.newsDetail.description_to_share,
    //   canonical: window.location.href,
    //   meta: {
    //     charset: 'utf-8',
    //     name: {
    //       keywords: 'react,meta,document,html,tags'
    //     }
    //   }
    // };
    return (
      <div className="base">
        {/*<MetaTags>*/}
          {/*<meta property="og:description" content={this.state.newsDetail.description_to_share} />*/}
          {/*<meta property="og:title" content={this.state.newsDetail.name} />*/}
          {/*<meta property="og:image" content={this.state.newsDetail.image_to_share} />*/}
          {/*<meta property="og:type" content="website" />*/}
          {/*/!*<meta name="twitter:card" content="summary" />*!/*/}
        {/*</MetaTags>*/}
        <div className="emptyHeader" />

        <div className="title">
          <h2 className="header2 container">LATEST NEWS</h2>
        </div>

        <div className="container home articleDetail">
          <div className="article">
            <img src={newsDetail.image} alt=""/>
            <h4 className="title_article">{newsDetail.name}</h4>
            <div className="date">
              <h4><i className="fas fa-clock" /> {newsDetail.datetime.date} - <span>{newsDetail.datetime.time} {newsDetail.datetime.am_pm}</span></h4>
              <div className="sharing">
                <FacebookShareButton
                  url={window.location.href}
                  quote={newsDetail.name}
                >
                  <FacebookIcon size={32} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={window.location.href}
                  title={newsDetail.name}
                >
                  <TwitterIcon size={32}/>
                </TwitterShareButton>
              </div>
            </div>
            <div className="text_article" dangerouslySetInnerHTML={{__html: newsDetail.text}} />
          </div>
          <div className="sidebar">
            <SidebarGames games={games} />
            <SidebarTeams teams={teams} />
          </div>
        </div>
      </div>
    );
  }
}

export default NewsDetail;
