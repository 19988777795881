import React, { Component, Fragment } from 'react';

import Loader from '../../components/Loader';
import { PlayerInfoForm } from "../../components/PlayerInfoForm";
import ModalWindow from '../../components/ModalSuccessful';
import { ImportPlayersForm } from "../../components/ImportPlayersForm";

import {API, createObject, deleteObject, fetchData, fetchDetail, updateObject} from "../../services";
import { getAges } from "../../services";

import DefaultAvatar from '../../images/default-avatar.png';
import {downloadFile} from "../../services/downloadFile";
import {API_URL} from "../../config";


const TeamsTabs = (props) => {
  const { currentAge, currentTeam, data, _onPickTeam} = props;
  let teams = [];
  data.map((item) => {
    if(item.id === currentAge) {
      teams = item.teams.map((team, index) =>
        <div
          className={`header-tabs-team-item ${team.id === currentTeam ? 'currentTeam' : ''}`}
          key={`index_tab_team${index}`}
          onClick={() => _onPickTeam(team.id)}
        >
          {team.title}
        </div>
      )
    }
  });
  return <div className='organizer-header-tabs'>
    {teams}
  </div>
};

const AgesTabs = (props) => {
  const { ages, _onPickAge, currentAge } = props;

  const list = ages.map((age, index) =>
    <div
      className={`tabs-age-item ${age.id === currentAge ? 'currentAge' : ''}`}
      key={`index_tab_age${index}`}
      onClick={() => _onPickAge(age.id)}
    >
      {age.age}
    </div>
  );

  return <div className='organizer-tabs-ages'>
    <div className='tabs-age-item empty-tab'>A / T</div>
    {list}
  </div>
};

const RosterList = (props) => {
  const { roster, _editPlayer } = props;

  const list = roster.map((item, index) =>
    <div className='roster-player-item player roster_view_for_mobile' key={`roster_player_index${index}`}>
      <img src={item.player.img || DefaultAvatar} alt='' />
      <h4 className='roster_player_name'>
        {item.player.name}
      </h4>
      <h4 className={`checkedIdDocument ${item.player.has_doc_photo ? '' : 'addOpacity0'}`}><i className='far fa-id-card'/></h4>
      <h4 className='roster_player_DOB'>
        {item.player.date_birth}
      </h4>
      <h4 style={{'width': '20%'}} className='hide_for_mobile_roster'>
        Position:
        <span>
            {item.main_position !== '' && item.position !== ''
              ? ' ' + item.main_position + '/' + item.position
              : item.main_position !== ''
                ? ' ' + item.main_position
                : ' ' + item.position}
          </span>
      </h4>
      <h4 style={{'width': '11%'}} className='hide_for_mobile_roster'>
        Number: <span>{item.number}</span>
      </h4>
      <div className='editBtn' onClick={() => _editPlayer(item.player.id)}>Edit</div>
    </div>
  )

  return <Fragment>
    {list}
  </Fragment>
};

class OrganizerMainContainer extends Component {
  render() {
    const { isLoadingForPart, data, _editPlayer } = this.props;

    if(isLoadingForPart) {
      return <Fragment>
        <div className='partlyLoader'>
          <h3>Loading...</h3>
        </div>
        <RosterList roster={data} />
      </Fragment>
    }

    return <Fragment>
        <RosterList
          roster={data}
          _editPlayer={_editPlayer}
        />
    </Fragment>
  }
};

const ManagerHeaderPanel = (props) => {
  const { showPanel, _createNewPlayer, _handleSelectFile, _toggleImportPlayersForm } = props;

  if(!showPanel) {
    return null
  }
  return <div className='organizer-header-manager-wrapper'>
    <h4>Manager: </h4>
    <div className='organizer-header-manager-block'>
      <div className='header-manager-item' onClick={() => _createNewPlayer()}>
        <i className="fas fa-plus"/>
        <span>Add player</span>
      </div>
      {/*<div className='header-manager-item'>*/}
        {/*<i className="fas fa-download"/>*/}
        {/*<span>Import Players</span>*/}
        {/*<input*/}
          {/*type="file"*/}
          {/*name="file"*/}
          {/*className="input_type_file"*/}
          {/*onChange={_handleSelectFile}*/}
        {/*/>*/}
      {/*</div>*/}
      <div className='header-manager-item' onClick={() => _toggleImportPlayersForm()}>
        <i className="fas fa-cloud-download-alt"/>
        <span>Import from other Teams</span>
      </div>
    </div>
  </div>
};

const RerenderWrapperForImportPlayersForm = (props) => {
  const {
    isShowing,
    _toggleImportPlayersForm,
    teams,
    currentTeamId,
    _getRoster
  } = props;

  if(!isShowing) {
    return null
  }

  return <ImportPlayersForm
    isShowing={isShowing}
    _toggleImportPlayersForm={_toggleImportPlayersForm}
    teams={teams}
    currentTeamId={currentTeamId}
    _getRoster={_getRoster}
  />
};

export class Rosters extends Component {
  state = {
    ages: [],
    teams: [],
    currentRosterForTeam: [],
    currentAge: null,
    currentTeam: null,
    isLoading: true,
    isLoadingForPartScreen: true,
    locations: [],
    provinces: [],
    toggleModalPUP: false,
    textForModal: '',
    typeForModal: '',

    showImportPlayersForm: false,
    showPlayerForm: false,
    isItEditingForm: false,

    playerId: '',
    email: '',
    first_name: '',
    last_name: '',
    gender: '',
    pickedDay: '',
    pickedMonth: '',
    pickedYear: '',
    parent: '',
    address: '',
    city: '',
    province: '',
    postal_code: '',
    description: '',
    imgSrc: null,
    docSrc: null,
    doc_photoSrc: null,
    file: null,
    teamplayers: [
      {id: -1, team: '', main_position: "", position: "", number: ''}
    ],

    errorsPlayerForm: []
  };

  componentDidMount() {
    let formattedData = [];

    getAges()
      .then(ages => {
        fetchData(API.profile.academyOwner.teams)
          .then(teams => {
            fetchData(API.locations)
              .then((locations) => {
                fetchData(API.provinces)
                  .then((provinces) => {
                    ages.forEach(age => {
                      formattedData = [...formattedData, {id: age.id, age: age.title, teams: teams.filter(team => team.age === age.id)}];
                    });

                    formattedData = formattedData.filter((itemData) => itemData.teams.length !== 0);


                    this.setState({
                      ages: formattedData,
                      currentAge: formattedData[0].id,
                      currentTeam: formattedData[0].teams[0].id,
                      locations: locations,
                      teams: teams,
                      provinces: provinces
                    }, () => this.getRoster(formattedData[0].teams[0].id));
                  })
                  .catch((error) => console.warn('error', error))
              })
              .catch((error) => console.warn('error', error));
          })
          .catch((error) => console.warn('error', error));
      })
      .catch((error) => console.warn('error ages', error));
  }

  getRoster = (id) => {
    fetchDetail(API.profile.academyOwner.teams, id)
      .then(response => {
        this.setState({
          currentRosterForTeam: response.team_to,
          isLoading: false,
          isLoadingForPartScreen: false
        })
      })
      .catch(error => console.warn('error', error))
  };

  _onPickAge = (id) => {
    const { ages } = this.state;

    this._ToggleFormPlayer(true, false);

    this.setState({
      currentAge: id,
      currentTeam: ages.filter(age => age.id === id)[0].teams[0].id,
      isLoadingForPartScreen: true
    }, () => this.getRoster(ages.filter(age => age.id === id)[0].teams[0].id))
  };

  _onPickTeam = (id) => {
    this._ToggleFormPlayer(true, false);

    this.setState({
      currentTeam: id,
      isLoadingForPartScreen: true
    }, () => this.getRoster(id))
  };

  onChangeValue = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    })
  };

  _onChangeValueAndConvert = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: parseInt(value, 10)
    });
  };

  onChangeValueFromArray = (e, name, typeDB='') => {
    const { locations, provinces, teams } = this.state;
    let db = locations;

    if(typeDB === 'provinces') {
      db = provinces;
    }

    if(typeDB === 'teams') {
      db = teams;
    }

    let v = parseInt(e.target.value, 10);
    this.setState({
      [name]: v
        ? db.filter(i => i.id === v)[0].id
        : null,
    });
  };

  onChangeValueFromArrayForTeamsPicker = (e, idObj) => {
    const { teams } = this.state;
    let db = teams;

    let v = parseInt(e.target.value, 10);

    this.setState(({teamplayers}) => ({
      teamplayers: teamplayers.map((item) => item.idForEdit === idObj ? ({...item, team: db.filter(i => i.id === v)[0].id}) : ({...item}))
    }));
  };

  onChangeTeamsOption = (e, idObj) => {
    const {name, value} = e.target;

    this.setState(({teamplayers}) => ({
      teamplayers: teamplayers.map((item) => item.idForEdit === idObj ? ({...item, [name]: value}) : ({...item}))
    }));
  };

  showErrorBorder = item => {
    for (let key in this.state.errorsPlayerForm) {
      if (this.state.errorsPlayerForm[key] === item) {
        return true;
      }
    }
  };

  showErrors = (error, name)=> {
    let errors = [];
    for (let item in error) {
      errors.push(item);
      this.setState({ [name]: errors, error_text: error[item] });
    }
  };

  _ToggleFormPlayer = (priority = '', value = '') => {
    if(priority) {
      this.setState({
        showPlayerForm: value
      })
    } else {
      this.setState(({ showPlayerForm }) => ({
        showPlayerForm: !showPlayerForm
      }))
    }
  };

  toggleModalWindow = (type='', value = '') => {
    if(type && value) {
      this.setState({
        toggleModalPUP: value,
        typeForModal: type
      });
    } else {
      this.setState(({ toggleModalPUP }) => ({
        toggleModalPUP: !toggleModalPUP
      }));
    }
  };

  downloadInsurance = () => downloadFile(this.state.docSrc.doc);

  handleImagePicker = img => {
    let reader = new FileReader(),
      imgSrc = img.target.files[0];
    reader.onloadend = () =>
      this.setState({
        imgSrc: {
          file: imgSrc,
          imagePreviewUrl: reader.result,
        },
      });
    reader.readAsDataURL(imgSrc);
  };

  handleDocPicker = (e, name) => {
    let reader = new FileReader(),
      imgSrc = e.target.files[0];
    reader.onloadend = () =>
      this.setState({
        [name]: {
          file: imgSrc,
          doc: reader.result,
        },
      });
    reader.readAsDataURL(imgSrc);
  };

  clearForm = () => {
    this.setState({
      typeForModal: null,
      playerId: '',
      isItEditingForm: false,
      first_name: '',
      last_name: '',
      email: '',
      gender: '',
      imgSrc: null,
      errorsPlayerForm: [],
      modalText: '',
      pickedDay: '',
      pickedMonth: '',
      pickedYear: '',
      parent: '',
      address: '',
      city: '',
      province: '',
      postal_code: '',
      description: '',
      docSrc: null,
      doc_photoSrc: null,
      file: null,
      error_text: '',
      teamplayers: [
        {team: '', main_position: "", position: "", number: '', idForEdit: -1}
      ],
    });
  };

  _getMethodForm = body => {
    return this.state.isItEditingForm
      ? updateObject(`${API.coaches.player}/${this.state.playerId}`, body)
      : createObject(API.coaches.createPlayer, body);
  };

  _deletePlayerFromRoster = id => {
    if (window.confirm('Are you sure delete player from team?')) {
      const body = {
        teamplayers: [],
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        gender: this.state.gender,
        // img:
        //   this.state.imgSrc && this.state.imgSrc.hasOwnProperty('imagePreviewUrl')
        //     ? this.state.imgSrc.imagePreviewUrl
        //     : null,
        // doc:
        //   this.state.docSrc && this.state.docSrc.file
        //     ? this.state.docSrc.doc
        //     : null,
        // doc_photo:
        //   this.state.doc_photoSrc && this.state.doc_photoSrc.file
        //     ? this.state.doc_photoSrc.doc
        //     : null,
        date_birth: `${this.state.pickedDay}/${this.state.pickedMonth}/${this.state.pickedYear}`,
        parent: this.state.parent,
        address: this.state.address,
        city: this.state.city ? this.state.city.id : null,
        province: this.state.province ? this.state.province.id : null,
        postal_code: this.state.postal_code,
      };
      updateObject(`${API.coaches.player}/${id}`, body)
        .then(() => {
          this.getRoster(this.state.currentTeam);
          this._ToggleFormPlayer();
        });
      // deleteObject(`${API.deletePlayer}/${id}`).then(res => {
      //   this.getRoster(this.state.currentTeam);
      //   this._ToggleFormPlayer();
      // });
    }
  };

  _createNewPlayer = () => {
    this.clearForm();
    this.setState({
      showPlayerForm: true
    });
  };

  //work with CSV file
  handleSelectFile = e => {
    let file = e.target.files[0];
    this.setState({ file }, this.sendFile);
  };

  sendFile = () => {
    if (this.state.file) {
      let { file } = this.state;
      let formdata = new FormData();
      formdata.append('file', file, file.name);

      let headers = { Authorization: `JWT ${localStorage.getItem('token')}` };
      headers['Accept'] = 'application/json';

      return fetch(`${API_URL}/${API.importFile}/${this.state.currentTeam}/`, {
        method: 'POST',
        body: formdata,
        headers: headers,
      }).then(res => {
        if (res.ok) {
          res
            .json()
            .then(json => {
              if (json.success) {
                this.getRoster(this.state.currentTeam);
              }
              if (json.imported || json.fails) {
                this.setState({
                  modalVisible: true,
                  modalText: json,
                });
              }
            })
            .catch(e => console.log(e));
        }
        this.setState({ file: null });
      });
    }
  };

  _editPlayer = (playerId) => {
    fetchDetail(API.coaches.playerEdit, playerId)
      .then(response => {
        let date_birth = [null, null, null];
        if(response.date_birth !== null) {
          date_birth = response.date_birth.split('/');
        }

        response.pickedDay = date_birth[0];
        response.pickedMonth = date_birth[1];
        response.pickedYear = date_birth[2];
        response.teamplayers.map(item => item.idForEdit = item.id);

        this.setState(state => ({
          playerId: playerId,
          first_name: response.first_name,
          last_name: response.last_name,
          email: response.email,
          gender: response.gender,
          imgSrc: { ...state.imgSrc, img: response.img },
          doc_photoSrc: { ...state.doc_photoSrc, doc: response.doc_photo },
          docSrc: { ...state.docSrc, doc: response.doc },
          showPlayerForm: true,
          isItEditingForm: true,
          description: response.description,
          city: response.city,
          address: response.address,
          parent: response.parent,
          postal_code: response.postal_code,
          province: response.province,
          // typeModal: '',
          teamplayers: response.teamplayers,
          pickedDay: response.pickedDay || '',
          pickedMonth: response.pickedMonth || '',
          pickedYear: response.pickedYear || '',
          errorsPlayerForm: []
        }), () => window.scrollTo({top: 0, behavior: "smooth"}));

      })
      .catch(error => console.warn('error', error));
  };

  _savePlayerInfo = (e) => {
    e.preventDefault();

    let teamplayers_list = this.state.teamplayers;

    teamplayers_list.map((item) => {
      if(item.number === '') {
        item.number = null;
      }
    });

    let body = {
      teamplayers: teamplayers_list,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      gender: this.state.gender,
      // img:
      //   this.state.imgSrc && this.state.imgSrc.hasOwnProperty('imagePreviewUrl')
      //     ? this.state.imgSrc.imagePreviewUrl
      //     : null,
      // doc:
      //   this.state.docSrc && this.state.docSrc.file
      //     ? this.state.docSrc.doc
      //     : null,
      // doc_photo:
      //   this.state.doc_photoSrc && this.state.doc_photoSrc.file
      //     ? this.state.doc_photoSrc.doc
      //     : null,
      date_birth: `${this.state.pickedDay}/${this.state.pickedMonth}/${this.state.pickedYear}`,
      parent: this.state.parent,
      address: this.state.address,
      city: this.state.city ? this.state.city : null,
      province: this.state.province ? this.state.province : null,
      postal_code: this.state.postal_code,
    };

    if(this.state.imgSrc && this.state.imgSrc.hasOwnProperty('imagePreviewUrl')) {
      body['img'] = this.state.imgSrc.imagePreviewUrl;
    }

    if(this.state.docSrc && this.state.docSrc.hasOwnProperty('file')) {
      body['doc'] = this.state.docSrc.doc;
    }

    if(this.state.doc_photoSrc && this.state.doc_photoSrc.hasOwnProperty('file')) {
      body['doc_photo'] = this.state.doc_photoSrc.doc;
    }

    this._getMethodForm(body)
      .then(response => {
        if(response) {
          this._ToggleFormPlayer();
          this.clearForm();
          this.toggleModalWindow(null, true);
          this.getRoster(this.state.currentTeam);
        }
      })
      .catch(error => {
        console.warn('error', error);
        this.showErrors(error, 'errorsPlayerForm');
      });
  };

  _toggleImportPlayersForm = (priority = '', value = '') => {
    if(priority) {
      this.setState({
        showImportPlayersForm: value
      })
    } else {
      this.setState(({ showImportPlayersForm }) => ({
        showImportPlayersForm: !showImportPlayersForm
      }))
    }
  };

  addTemplateTeamPlayers = () => {
    this.setState(({ teamplayers }) => ({
      teamplayers: [...teamplayers, {idForEdit: ((teamplayers.length + 1) * -1), team: '', main_position: "", position: "", number: ''}]
    }));
  };

  deleteTeamFormTeamPlayers = (id) => {
    this.setState(({ teamplayers }) => ({
      teamplayers: teamplayers.filter(item => item.idForEdit !== id)
    }))
  };

  render() {
    const {
      isLoading,
      ages,
      currentAge,
      currentTeam,
      currentRosterForTeam,
      isLoadingForPartScreen,
      toggleModalPUP,
      textForModal,
      typeForModal,
      isItEditingForm,

      showImportPlayersForm,

      showPlayerForm,
      playerId,
      imgSrc,
      email,
      first_name,
      last_name,
      gender,
      doc_photoSrc,
      parent,
      address,
      locations,
      city,
      provinces,
      province,
      postal_code,
      docSrc,
      description,

      pickedDay,
      pickedMonth,
      pickedYear,

      teams,
      teamplayers,
    } = this.state;

    if(isLoading) {
      return <Loader/>
    }

    return <div className='container'>
      <ModalWindow
        modalVisible={toggleModalPUP}
        type={textForModal ? 'custom' : typeForModal}
        text={textForModal}
        picture={doc_photoSrc && doc_photoSrc.doc}
        onClick={this.toggleModalWindow}
      />

      <RerenderWrapperForImportPlayersForm
        isShowing={showImportPlayersForm}
        _toggleImportPlayersForm={this._toggleImportPlayersForm}
        teams={teams}
        currentTeamId={currentTeam}
        _getRoster={this.getRoster}
      />

      <div className='team_detail rosters-page'>
        <div className='organizer'>

          <AgesTabs
            ages={ages}
            _onPickAge={this._onPickAge}
            currentAge={currentAge}
          />

          <div className='organizer-main-wrapper'>
            <TeamsTabs
              data={ages}
              currentAge={currentAge}
              currentTeam={currentTeam}
              _onPickTeam={this._onPickTeam}
            />
            <div className='organizer-main-container'>
              <ManagerHeaderPanel
                showPanel={!showPlayerForm}
                _createNewPlayer={this._createNewPlayer}
                _handleSelectFile={this.handleSelectFile}
                _toggleImportPlayersForm={this._toggleImportPlayersForm}
              />
              <PlayerInfoForm
                currentTeamTab={currentTeam}
                isItEditingForm={isItEditingForm}

                playerId={playerId}
                showPlayerForm={showPlayerForm}
                _ToggleFormPlayer={this._ToggleFormPlayer}
                showErrorBorder={this.showErrorBorder}
                imgSrc={imgSrc}
                email={email}
                first_name={first_name}
                last_name={last_name}
                gender={gender}
                doc_photoSrc={doc_photoSrc}
                parent={parent}
                address={address}
                locations={locations}
                city={city}
                provinces={provinces}
                province={province}
                postal_code={postal_code}
                docSrc={docSrc}
                description={description}
                pickedDay={pickedDay}
                pickedMonth={pickedMonth}
                pickedYear={pickedYear}
                teams={teams}
                teamplayers={teamplayers}

                handleImagePicker={this.handleImagePicker}
                handleDocPicker={this.handleDocPicker}
                toggleModalWindow={this.toggleModalWindow}
                downloadInsurance={this.downloadInsurance}
                onChangeValue={this.onChangeValue}
                _onChangeValueAndConvert={this._onChangeValueAndConvert}
                onChangeValueFromArray={this.onChangeValueFromArray}
                _savePlayerInfo={this._savePlayerInfo}
                _deletePlayerFromRoster={this._deletePlayerFromRoster}
                addTemplateTeamPlayers={this.addTemplateTeamPlayers}
                deleteTeamFormTeamPlayers={this.deleteTeamFormTeamPlayers}
                onChangeValueFromArrayForTeamsPicker={this.onChangeValueFromArrayForTeamsPicker}
                onChangeTeamsOption={this.onChangeTeamsOption}
              />
              <OrganizerMainContainer
                isLoadingForPart={isLoadingForPartScreen}
                data={currentRosterForTeam}
                _editPlayer={this._editPlayer}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}
