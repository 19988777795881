import React, { Component } from 'react';

import Loader from '../../components/Loader';

import {
  Link
} from 'react-router-dom';

import {fetchDetail} from '../../services';

class TeamItem extends Component {
  render () {
    let index = 0,
      item = this.props.data.map((item) => {
        return (
          <Link to={'/teams/'+ item.gender+ '/' +item.id} className="teamItem" key={index++}>
            <div className="logo">
              <div className="logo_border">
                <img src={item.logo ? item.logo : this.props.logoDefault} alt=""/>
              </div>
            </div>
            <div className="description">
              <div className="header_description">
                <span className="teamName">{item.title}</span>
                {item.head_coach && <span>Head Coach: {item.head_coach.first_name} {item.head_coach.last_name}</span>}
                {item.assistant_coach && <span>Assistant Coach: {item.assistant_coach.first_name} {item.assistant_coach.last_name}</span>}
              </div>
              <p>{item.description}</p>
            </div>
          </Link>
        )
      });
    return (
      <div className="team">
        {item}
      </div>
    )
  }
}

class TeamList extends Component {
  constructor() {
    super();
    this.state = {
    }
  }

  render () {

    let index = 0, list;
    if(this.props.sortTeams !== undefined) {
      list = this.props.sortTeams.map((item, i) => {
         return (
           <div className="teamAge" key={index++}>
             <div className="age">{this.props.ages[i]}</div>
             <TeamItem data={item} logoDefault={this.props.logoDefault}/>
           </div>
         )
      });
    }
    return (
      <div>
        {list}
      </div>
    )
  }
}

class AcademyDetail extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      academyDetail: '',
      gender: 1
    }
  }

  componentDidMount() {
    fetchDetail(`academies/${this.props.match.params.id}/${1}`)
      .then((response) => this.setState({academyDetail: response, isLoading: false}))
      .then(() => {
        this.sorterForTeams();
      });
  }

  showTeamsByGender(gender) {
    fetchDetail(`academies/${this.props.match.params.id}/${gender}`)
      .then((response) => this.setState({academyDetail: response, gender: gender, isLoading: false}))
      .then(() => {
        this.sorterForTeams();
      });
  }

  sorterForTeams() {
    let teams = [];
        for(let key in this.state.academyDetail.ages) {
          teams[key] =[];
          for(let team in this.state.academyDetail.teams) {
            if(this.state.academyDetail.teams[team].age === this.state.academyDetail.ages[key]) {
              teams[key].push(this.state.academyDetail.teams[team]);
            }
          }
        }
        this.setState({sortTeams: teams});
  }


  render() {
    if(this.state.isLoading) {
      return <Loader/>
    }

    return (
      <div className="base">
        <div className="emptyHeader" />

        <div className="title">
          <h2 className="header2 container">{this.state.academyDetail.title}</h2>
        </div>

        <div className="container">
          <div className="academyDetail">
            <div className="details">
              <div className="logo academyLogo">
                <div className="logo_border">
                  <img src={this.state.academyDetail.logo} alt=""/>
                </div>
              </div>
              <div className="description">
                <div className="aboutAcademy">
                  <p>
                    {this.state.academyDetail.description}
                  </p>
                </div>
                <div className="personal_info">
                  <h4>
                    <a href={this.state.academyDetail.link} target="_blank" rel="noopener noreferrer">
                      <i className="fas fa-link"/> Official website
                    </a>
                  </h4>
                  <h4>Academy Administrator: <span className="aa-email">{this.state.academyDetail.owner}</span></h4>
                  <h4>City: <span>{this.state.academyDetail.address}</span></h4>
                  <div className="row">
                    <div className='homeTshirt' style={{fill: this.state.academyDetail.home_color}}>
                      <svg viewBox="0 0 512 512">
                        <path className="homeTshirt__border" d="M362.672,42.66l-53.328,0c0,29.414 -23.938,53.336 -53.344,53.336c-29.406,
                          0 -53.328,-23.922 -53.328,-53.336l-53.336,0l-149.336,85.336l42.672,117.336l64,-21.336l0,245.344l298.672,0l0,
                          -245.344l64,21.336l42.656,-117.336l-149.328,-85.336Zm93.703,175.867l-44.297,-14.766l-28.078,-9.366l0,
                          253.602l-256,0l0,-224l0,-29.602l-28.078,9.367l-44.297,14.766l-29.43,-80.93l128.805,-73.602l29.43,
                          0c9.18,30.844 37.75,53.336 71.57,53.336c33.828,0 62.391,-22.492 71.562,-53.336l29.438,
                          0l128.812,73.602l-29.437,80.929Z"
                        />
                        <path d="M456.375,218.527l-44.297,-14.766l-28.078,-9.366l0,253.602l-256,0l0,-224l0,-29.602l-28.078,
                          9.367l-44.297,14.766l-29.43,-80.93l128.805,-73.602l29.43,0c9.18,30.844 37.75,53.336 71.57,53.336c33.828,
                          0 62.391,-22.492 71.562,-53.336l29.438,0l128.812,73.602l-29.437,80.929Z"
                        />
                      </svg>
                    </div>
                    <div className='guestTshirt' style={{fill: this.state.academyDetail.guest_color}}>
                      <svg viewBox="0 0 512 512">
                        <path className="homeTshirt__border" d="M362.672,42.66l-53.328,0c0,29.414 -23.938,53.336 -53.344,53.336c-29.406,
                          0 -53.328,-23.922 -53.328,-53.336l-53.336,0l-149.336,85.336l42.672,117.336l64,-21.336l0,245.344l298.672,0l0,
                          -245.344l64,21.336l42.656,-117.336l-149.328,-85.336Zm93.703,175.867l-44.297,-14.766l-28.078,-9.366l0,
                          253.602l-256,0l0,-224l0,-29.602l-28.078,9.367l-44.297,14.766l-29.43,-80.93l128.805,-73.602l29.43,
                          0c9.18,30.844 37.75,53.336 71.57,53.336c33.828,0 62.391,-22.492 71.562,-53.336l29.438,
                          0l128.812,73.602l-29.437,80.929Z"
                        />
                        <path d="M456.375,218.527l-44.297,-14.766l-28.078,-9.366l0,253.602l-256,0l0,-224l0,-29.602l-28.078,
                          9.367l-44.297,14.766l-29.43,-80.93l128.805,-73.602l29.43,0c9.18,30.844 37.75,53.336 71.57,53.336c33.828,
                          0 62.391,-22.492 71.562,-53.336l29.438,0l128.812,73.602l-29.437,80.929Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="teams">
              <h2>Academy Teams</h2>
              <div className="nextNavigation navigationCalendar">
                <div className={this.state.gender === 1 ? 'nextTab clicked' : 'nextTab'} onClick={() => this.showTeamsByGender(1)}>Boys</div>
                <div className={this.state.gender === 2 ? 'nextTab clicked' : 'nextTab'} onClick={() => this.showTeamsByGender(2)}>Girls</div>
              </div>
              <TeamList
                sortTeams={this.state.sortTeams}
                ages={this.state.academyDetail.ages}
                logoDefault={this.state.academyDetail.logo}
                gender={this.state.gender}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AcademyDetail;
