import React, { Component } from 'react';
import Loader from '../../../components/Loader';

import { API, fetchData, createObject } from '../../../services/';

class RefereePicker extends Component {
  render () {
    const { status, takeStatusGame, idItem } = this.props;

    if(status !== null) {
      if(status === 1) {
        return (
          <div className="referee-picker">
            <div className="hover-referee-chooses">
              <div className="referee-chooses">
                <div className="referee-chooses-item" onClick={() => takeStatusGame(idItem, 1)}>Going</div>
                <div className="referee-chooses-item" onClick={() => takeStatusGame(idItem, 3)}>Maybe</div>
                <div className="referee-chooses-item" onClick={() => takeStatusGame(idItem, 2)}>No</div>
              </div>
            </div>
            <div className="status-referee-pick referee-want-judged">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 960" className="Icon" width="16" height="16" fill="#ffffff">
                <path d="M915 178.9c8 6.7 12.3 15.5 13 26.5s-2.3 21.2-9 30.5c-252.7 360.7-389.3 555.3-410 584-21.3 28.7-48.8 43.2-82.5 43.5S365 849.2 343 819.9l-278-391c-6.7-9.3-9.7-19.7-9-31s5-20.3 13-27c34-29.3 72-55.7 114-79 8.7-4.7 18.3-5.3 29-2s19.3 9.7 26 19l188 264 321-456c6.7-9.3 15.2-15.5 25.5-18.5s20.2-2.2 29.5 2.5c44 24 81.7 50 113 78z"></path>
              </svg>
            </div>
          </div>
        )
      }

      if(status === 2) {
        return (
          <div className="referee-picker">
            <div className="hover-referee-chooses">
              <div className="referee-chooses">
                <div className="referee-chooses-item" onClick={() => takeStatusGame(idItem, 1)}>Going</div>
                <div className="referee-chooses-item" onClick={() => takeStatusGame(idItem, 3)}>Maybe</div>
                <div className="referee-chooses-item" onClick={() => takeStatusGame(idItem, 2)}>No</div>
              </div>
            </div>
            <div className="status-referee-pick referee-not-want-judged">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 960" className="Icon" width="16" height="16" fill="#ffffff">
                <path d="M628 480l189 190c8.7 8 13.7 17.5 15 28.5s-1.3 20.2-8 27.5c-28.7 36.7-61.3 69.3-98 98-7.3 6.7-16.5 9.3-27.5 8s-20.8-6-29.5-14L480 628 290 818c-8 8-17.5 12.7-28.5 14s-20.5-1.3-28.5-8c-36-28.7-68.3-61-97-97-6.7-8-9.3-17.5-8-28.5 1.3-11 6-20.5 14-28.5l190-190-189-189c-8.7-8.7-13.7-18.5-15-29.5-1.3-11 1.3-20.2 8-27.5 28-36 60.3-68.7 97-98 8-6.7 17.5-9.3 28.5-8s20.5 6.3 28.5 15l190 181 189-181c8.7-8.7 18.5-13.7 29.5-15s20.2 1.3 27.5 8c36.7 28.7 69.3 61.3 98 98 6.7 7.3 9.3 16.5 8 27.5s-6.3 20.8-15 29.5L628 480z"></path>
              </svg>
            </div>
          </div>
        )
      }

      if(status === 3) {
        return (
          <div className="referee-picker">
            <div className="hover-referee-chooses">
              <div className="referee-chooses">
                <div className="referee-chooses-item" onClick={() => takeStatusGame(idItem, 1)}>Going</div>
                <div className="referee-chooses-item" onClick={() => takeStatusGame(idItem, 3)}>Maybe</div>
                <div className="referee-chooses-item" onClick={() => takeStatusGame(idItem, 2)}>No</div>
              </div>
            </div>
            <div className="status-referee-pick referee-does-know">
              ?
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className="referee-picker">
          <div className="hover-referee-chooses">
            <div className="referee-chooses">
              <div className="referee-chooses-item" onClick={() => takeStatusGame(idItem, 1)}>Going</div>
              <div className="referee-chooses-item" onClick={() => takeStatusGame(idItem, 3)}>Maybe</div>
              <div className="referee-chooses-item" onClick={() => takeStatusGame(idItem, 2)}>No</div>
            </div>
          </div>
        </div>
      )
    }
  }
}

class GamesGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      weekId: props.currentWeek,
      games: [],
      isLoading: true
    };
  }

  getData = (weekId) => {
    fetchData(`profile/referee/games/${weekId}`)
      .then((response) => {
        this.setState({
          games: response,
          isLoading: false
        })
    });
  };

  componentDidMount() {
    this.getData(this.state.weekId);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.weekId !== nextProps.currentWeek) {
      this.setState({
        weekId: nextProps.currentWeek,
        isLoading: true
      });
      this.getData(nextProps.currentWeek);
    }

    return true
  }

  takeStatusGame = (id, status) => {
    let requestData = {
      status: status
    };
    createObject(`profile/referee/games/offer/${id}`, requestData)
      .then(() => {
        this.getData(this.state.weekId);
      })
  };

  render() {
    const {
      isLoading,
      games,

    } = this.state;

    if(isLoading) {
      return <Loader/>
    }
    return (
      <div>
        <div className="game-list-for-referee">
          {
            games.map((item, index) => {
              return (
                <div className="game-item-for-referee" key={index}>
                  <div className="item-row">
                    <div className="team-title">
                      <img src={item.team1_img} alt="" />
                      <h2>{item.team1}</h2>
                    </div>
                    <div className="team-title">
                      <h2 style={{ textAlign: 'right', marginRight: '10px' }}>{item.team2}</h2>
                      <img src={item.team2_img} alt="" style={{ marginRight: 0 }}/>
                    </div>
                  </div>

                  <span className="important-info">{item.age}</span>
                  <span className="place">{item.field ? <a href={item.field_url} target="_blank" rel="noopener noreferrer">{item.field}</a> : '-'}</span>
                  <span>{item.datetime}</span>
                  <div className="list-referees-in-game-block">
                    <div className={item.game_referee_staff.length !== 0 ? "referee-was-picked" : "notVisible"}>
                      <h5>Referee(s):</h5>
                      {
                        item.game_referee_staff.map((referee, index) => {
                          if(referee.approved === false) {
                            return <span key={index}/>
                          } else {
                            return (
                              <div key={index} className="game-referee-row referee-item">
                                <span>{referee.referee}</span>
                                <p>{referee.qualification === 1 ? 'HR' : 'AR'}</p>
                              </div>
                            )
                          }
                        })
                      }
                    </div>
                    <div className="referee-wants">
                      <RefereePicker
                        status={item.status}
                        idItem={item.id}
                        takeStatusGame={this.takeStatusGame}
                      />
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export class RefereeProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentWeek: parseInt(props.match.params.id, 10),
      weeks: [],
      games: []
    }
  }

  componentDidMount() {
      fetchData(API.weeks)
        .then((weeks) => {
          this.setState({
            weeks: weeks,
            isLoading: false,
          })
        })

  }


  setCurrentWeek = (id) => {
    this.setState({currentWeek: id});
  };

  render() {
    const {
      isLoading,
      currentWeek,
      weeks
    } = this.state;

    if(isLoading) {
      return <Loader/>
    }

    return (
      <div className="container">
        <div className="nextNavigation">
          <div className="teams_filter">
            {weeks.map((week, index) => (
              <div
                key={index}
                onClick={() => this.setCurrentWeek(week.id)}
                className={`nextTab ${currentWeek === week.id ? 'clicked' : ''}`}
              >{week.week}</div>
            ))}
          </div>
        </div>
          <GamesGroup
            currentWeek={currentWeek}
          />
      </div>
    );
  }
}