import React, {Component} from 'react';

import Loader from '../../components/Loader';
import {API, fetchDetail} from '../../services';

import DefaultAvatar from '../../images/default-avatar.png';
import {catchErrors} from "../../services/catchErrors";


class PlayerDetail extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      detail: {}
    }
  }

  componentDidMount() {
    fetchDetail(API.details.player, this.props.match.params.id)
      .then((detail) => {
        //Show only one team
        detail.player_to = detail.player_to.filter(item => item.team_id === parseInt(this.props.match.params.teamId, 10))[0];

        this.setState({
          isLoading: false,
          detail: detail,
          teamId: parseInt(this.props.match.params.teamId, 10)
        })
      })
      .catch((e) => {
        catchErrors(e);
        this.setState({isLoading: false})
      })
  }

  render() {
    if (this.state.isLoading) {
      return <Loader/>
    }

    return (
      <div className="base">
        <div className="emptyHeader" />
        <div className="title">
          <h2 className="header2 container">INFORMATION</h2>
        </div>
        <div className="container">
          <div className="info changesInfo">
            <div className="player_avatar">
              <img src={this.state.detail.img || DefaultAvatar} alt={this.state.detail.first_name} />
              <div className="corner_team">
                <img src={this.state.detail.player_to.logo} alt=""/>
              </div>
            </div>
            <div className="twoRows">
              <h3>{this.state.detail.first_name || ''} {this.state.detail.last_name || ''}</h3>
              <div className="info_player">
                <div className="description">
                  <p>{this.state.detail.description}</p>
                </div>
                <div className="personal_info">
                  <div className="row">
                  <h4><span>Date of Birth: </span>{this.state.detail.date_birth || ''}</h4>
                  <h4>
                    <span>Position</span>
                    {this.state.detail.player_to.main_position !== '' && this.state.detail.player_to.position !== '' ?
                      ' '+this.state.detail.player_to.main_position +'/'+this.state.detail.player_to.position :
                      this.state.detail.player_to.main_position !== '' ?
                        ' '+this.state.detail.player_to.main_position :
                        ' '+this.state.detail.player_to.position
                    }
                  </h4>
                  </div>
                  <div className="row">
                    <h4><span>Team</span>{this.state.detail.player_to.team}</h4>
                    <h4><span>Number</span>#{this.state.detail.player_to.number}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlayerDetail;
