import React from 'react';

export const TShirt = () => {
  return (
    <svg viewBox="0 0 512 512">
      <path className="homeTshirt__border" d="M362.672,42.66l-53.328,0c0,29.414 -23.938,53.336 -53.344,53.336c-29.406,
                          0 -53.328,-23.922 -53.328,-53.336l-53.336,0l-149.336,85.336l42.672,117.336l64,-21.336l0,245.344l298.672,0l0,
                          -245.344l64,21.336l42.656,-117.336l-149.328,-85.336Zm93.703,175.867l-44.297,-14.766l-28.078,-9.366l0,
                          253.602l-256,0l0,-224l0,-29.602l-28.078,9.367l-44.297,14.766l-29.43,-80.93l128.805,-73.602l29.43,
                          0c9.18,30.844 37.75,53.336 71.57,53.336c33.828,0 62.391,-22.492 71.562,-53.336l29.438,
                          0l128.812,73.602l-29.437,80.929Z"
      />
      <path d="M456.375,218.527l-44.297,-14.766l-28.078,-9.366l0,253.602l-256,0l0,-224l0,-29.602l-28.078,
                          9.367l-44.297,14.766l-29.43,-80.93l128.805,-73.602l29.43,0c9.18,30.844 37.75,53.336 71.57,53.336c33.828,
                          0 62.391,-22.492 71.562,-53.336l29.438,0l128.812,73.602l-29.437,80.929Z"
      />
    </svg>
  )
};