import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Route,
  NavLink, Switch
} from 'react-router-dom';

import { ProfilePlayer } from './ProfilePlayer';
import { ProfileForOther } from './ProfileForOther';
import { ProfileForReferee } from './ProfileForReferee';
import { ChangePassword } from './ChangePassword';
import { Schedule } from "./Schedule";

import { ModeratorProfile } from './Moderator/ModeratorProfile';
import { Referees } from './Moderator/Referees';
import Emails from './Moderator/Emails';
import SendEmail from './Moderator/SendEmail';
import EmailDetail from './Moderator/EmailDetail';
import RefereeGamesStatistic from './Moderator/RefereeGamesStatistic';

import { RefereeProfile } from './Referee/RefereeProfile';

import { PlayerTeam } from './Player/PlayerTeam';
import { Insurance } from './Player/Insurance';

import { ProfileAcademy } from './AcademyOwner/ProfileAcademy';
import ProfileTeams from './AcademyOwner/ProfileTeams';
import {ProfileCoaches} from "./AcademyOwner/ProfileCoaches";

import { Rosters } from "./Rosters";

import {API, fetchData} from "../../services";
import Loader from "../../components/Loader";


class ProfileNavigation extends Component {
  render() {
    const {currentWeek, history, user, loggedIn} = this.props;

    switch (user.user_type) {
      case 1:
        return (
          <Router>
          <div>
            <div className="title">
              <div className="navigationProfile container">
                <NavLink to="/profile"
                         isActive={(match, location) => match && match.url === location.pathname}
                         className="formsButton" activeClassName="clicked">Personal Info</NavLink>
                <NavLink to="/profile/academy" className="formsButton" activeClassName="clicked">Academy</NavLink>
                <NavLink to="/profile/teams" className="formsButton" activeClassName="clicked">Teams</NavLink>
                <NavLink to="/profile/rosters" className="formsButton" activeClassName="clicked">Rosters</NavLink>
                <NavLink to="/profile/coaches" className="formsButton" activeClassName="clicked">Staff</NavLink>
                <NavLink to="/profile/schedule" className="formsButton" activeClassName="clicked">My schedule</NavLink>
              </div>
            </div>
            <Switch>
              <Route path="/profile/academy" component={props => <ProfileAcademy {...props} user={user} loggedIn={loggedIn}/>}/>
              <Route path="/profile/teams" component={props => <ProfileTeams {...props} user={user} loggedIn={loggedIn}/>}/>
              <Route path="/profile/coaches" component={props => <ProfileCoaches {...props} user={user} loggedIn={loggedIn}/>}/>
              <Route path='/profile/rosters' component={props => <Rosters {...props} user={user} loggedIn={loggedIn}/>} />
              <Route path='/profile/schedule' component={props => <Schedule {...props} user={user} loggedIn={loggedIn}/>} />
              <Route exact path="/profile" component={props => <ProfileForOther {...props} user={user} loggedIn={loggedIn}/>}/>
            </Switch>
          </div>
          </Router>
        );
      case 2:
        return (
          <Router>
          <div>
            <div className="title">
              <div className="navigationProfile container">
                <NavLink to="/profile" isActive={(match, location) => match && match.url === location.pathname}
                         className="formsButton" activeClassName="clicked">Personal Info</NavLink>
                <NavLink to="/profile/change" className="formsButton" activeClassName="clicked">Change
                  Password</NavLink>
                <NavLink to={"/profile/referee/" + currentWeek} className="formsButton"
                         activeClassName="clicked">Games</NavLink>
              </div>
            </div>
            <Switch>
              <Route exact path="/profile" component={props => <ProfileForReferee {...props} user={user} loggedIn={loggedIn}/>} />
              <Route path="/profile/change" component={props => <ChangePassword {...props} user={user} loggedIn={loggedIn}/>} />
              <Route path="/profile/referee/:id" component={props => <RefereeProfile {...props} user={user} loggedIn={loggedIn} />}/>
            </Switch>
          </div>
          </Router>
        );
      case 3:
        return (
          <Router>
          <div>
            <div className="title">
              <div className="navigationProfile container">
                <NavLink to="/profile" isActive={(match, location) => match && match.url === location.pathname}
                         className="formsButton" activeClassName="clicked">Personal Info</NavLink>
                <NavLink to="/profile/change" className="formsButton" activeClassName="clicked">Change
                  Password</NavLink>
                <NavLink to="/profile/team" className="formsButton" activeClassName="clicked">My Teams</NavLink>
                <NavLink to="/profile/insurance" className="formsButton" activeClassName="clicked">Insurance</NavLink>
              </div>
            </div>
            <Switch>
              <Route exact path="/profile" component={props => <ProfilePlayer {...props} user={user} loggedIn={loggedIn}/>}/>
              <Route path="/profile/change" component={props => <ChangePassword {...props} loggedIn={loggedIn}/>}/>
              <Route path="/profile/team" component={props => <PlayerTeam {...props} user={user} loggedIn={loggedIn}/>}/>
              <Route path="/profile/insurance" component={props => <Insurance {...props} user={user} loggedIn={loggedIn}/>}/>
            </Switch>
          </div>
          </Router>
        );
      case 4:
        return (
          <Router>
          <div>
            <div className="title">
              <div className="navigationProfile container">
                <NavLink to="/profile" isActive={(match, location) => match && match.url === location.pathname}
                         className="formsButton" activeClassName="clicked">Personal Info</NavLink>
                <NavLink to={"/profile/games/" + currentWeek} className="formsButton"
                         activeClassName="clicked">Games</NavLink>
                <NavLink to="/profile/referees" className="formsButton" activeClassName="clicked">Referees</NavLink>
                <NavLink to="/profile/emails" className="formsButton" activeClassName="clicked">Emails</NavLink>
              </div>
            </div>
            <Switch>
              <Route exact path="/profile" component={props => <ProfileForOther {...props} loggedIn={loggedIn}/>}/>
              <Route path="/profile/games/:id" component={props => <ModeratorProfile {...props} loggedIn={loggedIn} />}/>
              <Route path="/profile/referees" component={props => <Referees {...props} loggedIn={loggedIn}/>} />
              <Route path="/profile/emails" component={props => <Emails {...props} loggedIn={loggedIn} />} />
              <Route path="/profile/send-email" component={props => <SendEmail {...props} loggedIn={loggedIn}/>} />
              <Route path="/profile/email/:id" component={props => <EmailDetail {...props} loggedIn={loggedIn} />} />
              <Route path="/profile/statistic/:id" component={props => <RefereeGamesStatistic {...props} loggedIn={loggedIn}/>}/>
            </Switch>
          </div>
          </Router>
        );
      case 5:
        return (
          <Router>
            <div>
              <div className="title">
                <div className="navigationProfile container">
                  <NavLink to="/profile" isActive={(match, location) => match && match.url === location.pathname}
                           className="formsButton" activeClassName="clicked">Personal Info</NavLink>
                  <NavLink to="/profile/teams" className="formsButton" activeClassName="clicked">Teams</NavLink>
                  <NavLink to="/profile/rosters" className="formsButton" activeClassName="clicked">Rosters</NavLink>
                  <NavLink to="/profile/schedule" className="formsButton" activeClassName="clicked">Schedule</NavLink>
                </div>
              </div>
              <Switch>
                <Route exact path="/profile" component={props => <ProfileForOther {...props} user={user}/>}/>
                <Route path="/profile/teams"
                       component={props => <ProfileTeams {...props} user={user} history={history}/>}/>
                <Route path='/profile/rosters' component={props => <Rosters {...props} user={user} loggedIn={loggedIn}/>} />
                <Route path='/profile/schedule' component={props => <Schedule {...props} user={user} loggedIn={loggedIn}/>} />
              </Switch>
            </div>
          </Router>
        );
      default:
        return (
          <Router>
          <div>
            <div className="title">
              <div className="navigationProfile container">
                <NavLink to="/profile" isActive={(match, location) => match && match.url === location.pathname}
                         className="formsButton" activeClassName="clicked">Personal Info</NavLink>
              </div>
            </div>
            <Switch>
              <Route exact path="/profile" component={props => <ProfileForOther {...props} user={user}/>}/>
            </Switch>
          </div>
          </Router>
        )
    }
  }
}

class Profile extends Component {

  state = {
    isLoading: true,
    profile: {},
    requests_user: -1,
    weeksForReferee: []
  };

  componentDidMount() {
    if (this.props.user_type === 2 || this.props.user_type === 4) {
      fetchData(API.weeks)
        .then((weeks) => {
          this.setState({
            weeksForReferee: weeks,
            isLoading: false
          })
        })
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  render() {
    const {isLoading, weeksForReferee} = this.state;
    const {history, user, loggedIn} = this.props;
    if (isLoading) {
      return <Loader/>
    }

    return (
      <div className="base">
        <div className="emptyHeader"/>
        <ProfileNavigation
          currentWeek={weeksForReferee.length !== 0 ? weeksForReferee[0].id : null}
          history={history}
          user={user}
          loggedIn={loggedIn}
        />
      </div>
    );
  }
}

export default Profile;

