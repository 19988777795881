import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import Loader from '../../components/Loader';
import VideoItem from '../../components/VideoItem';
import {API, fetchData} from '../../services';


class GalleryList extends Component {
  render () {
    const list = this.props.data.map((item, index) => {
      return (
        <div className="video" key={index}>
          <h2>{item.name}</h2>
          <VideoItem url={item.url}/>
        </div>
      )
    });

    return(
      <div className="gallery">
        {list}
      </div>
    )
  }
}

class GalleryVideos extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      list: []
    }
  }

  componentDidMount() {
    fetchData(API.galleryVideos)
      .then((response) => this.setState({list: response, isLoading: false}));
  }

  render() {
    if(this.state.isLoading) {
      return <Loader/>
    }

    return (
      <div className="base">
        <div className="emptyHeader" />

        <div className="title">
          <h2 className="header2 container">GALLERY VIDEOS</h2>
        </div>

        <div className="container">
          <div className="goToLink">
            <Link to="/gallery" className="formsButton">go to Photos</Link>
          </div>
          <GalleryList data={this.state.list}/>
        </div>
      </div>
    );
  }
}

export default GalleryVideos;
