import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import Loader from '../../../components/Loader';
import DefaultAvatar from '../../../images/default-avatar.png';

import {API, fetchData} from '../../../services/index';


class RefereesList extends Component {
  render () {
    const { data } = this.props;

    const list = data.map((item, index) => {
      let statistic = item.statistic.map((stat, indexstat) => {
        let week_stats = stat.count.map((week_stat, week_index) => {
          return (
            <div key={week_index}>
              <span className="week-stat-count">{week_stat.referee_type}: {week_stat.total}</span>
            </div>
          )
        });

        return(
          <div key={indexstat} className="item-row week-statistic">
            <h5>{stat.week}:</h5>
            {week_stats}
          </div>
        )
      });

      return (
        <div className="referees-list-item item-row" key={index}>
          <div className="item-row">
            <img src={item.img ? item.img :DefaultAvatar} alt=""/>
            <span>{item.first_name ? item.first_name : ''}</span>
            <span>{item.last_name ? item.last_name : ''}</span>
            <span className="araz-email-block">{item.email ? item.email : ''}</span>
            <span>{item.phone !== '' ? item.phone : ''}</span>
            <span>{item.qualification ? item.qualification === 1 ? 'Category 1' : 'Category 2' : ''}</span>
          </div>
          <Link to={`/profile/statistic/${item.id}`} className='link-to-statistic'><h5 className="title-stat">Statistics:</h5></Link>
          <div className="item-row referee-statistic">
            {statistic}
          </div>
        </div>
      )
    });

    return (
      <div className="referees-list-block">
        {list}
      </div>
    )
  }
}

export class Referees extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      data: ''
    }
  }
  componentDidMount() {
    fetchData(API.profile.moderator.referees)
    .then((response) =>this.setState({data: response, isLoading: false}));
  }
  render() {
    const {
      isLoading,
      data
    } = this.state;

    if(isLoading) {
      return (
        <Loader/>
      )
    }
    return (
      <div>
        <div className="container">
          <div className="referees-head-block">
            <Link to='/profile/send-email'>Send to email</Link>
          </div>
          <RefereesList
            data={data}
          />
        </div>
      </div>
    )
  }
}