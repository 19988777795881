import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {API, fetchData} from '../../services';
import Loader from "../../components/Loader";
import {catchErrors} from "../../services/catchErrors";

class LeaguesArchive extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      leagues: ''
    }
  }

  componentDidMount() {
    fetchData(API.tournaments)
      .then((response) => this.setState({
        leagues: response,
        isLoading: false
      }))
      .catch((e) => {
        catchErrors(e);
        this.setState({isLoading: false});
      })
  }

  render() {
    if(this.state.isLoading) {
      return <Loader/>
    }

    return (
      <div className="base">
        <div className="emptyHeader" />

        <div className="title">
          <h1 className="header2 container">ARCHIVE</h1>
        </div>

        <div className="container">
          <div className="leaguesList">
            {this.state.leagues.map((item, index) =>
              <div className="leagueItem" key={index}>
                <h4>{item.tournament}</h4>
                <Link to={'/league/' + item.id} className="formsButton">Read more</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default LeaguesArchive;
