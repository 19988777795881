import React, { Component } from 'react';
import {Route} from 'react-router-dom';

import GalleryPhotos from '../Gallery/GalleryPhotos';
import GalleryVideos from '../Gallery/GalleryVideos';


class Gallery extends Component {
  render() {
    return (
      <div>
        <Route exact path="/gallery" component={GalleryPhotos}/>
        <Route path="/gallery/videos" component={GalleryVideos}/>
      </div>
    );
  }
}

export default Gallery;
