import React, { Component } from 'react';

import Modal from '../../components/ModalSuccessful';

import {API, updateObject} from '../../services';

export class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      old_password: '',
      password: '',
      confirm_password: '',
      modalVisible: false
    }
  }

  closeModal = () => {
    this.setState(({ modalVisible }) => ({
      modalVisible: !modalVisible
    }))
  };

  showErrors = (error) => {
    let errors = [];
    if(error.hasOwnProperty('non_field_errors')){
      if(error.non_field_errors[0] === 'wrong password') {
        this.setState({error_text: error.non_field_errors[0], errors: ["old_password"]});
      }
      if(error.non_field_errors[0] === 'different passwords') {
        this.setState({error_text: error.non_field_errors[0], errors: ["password", "confirm_password"]});
      }
    } else{
      for(let item in error) {
        errors.push(item);
        this.setState({errors: errors, error_text: error[item]});
      }
    }
  };

  showErrorBorder = (item) => {
    for(let key in this.state.errors) {
      if(this.state.errors[key] === item) {
        return true;
      }
    }
  };

  onChangeValue = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  };

  postDataRequest = () => {
    let dataRequest = {
      old_password: this.state.old_password,
      password: this.state.password,
      confirm_password: this.state.confirm_password
    };
    updateObject(API.changePassword, dataRequest)
      .then(() => this.setState({modalVisible: true, password: '', old_password: '', confirm_password: ''}))
      .catch((error) => this.showErrors(error));
  };

  render() {
    const {
      modalVisible,
      old_password,
      password,
      confirm_password,
      error_text
    } = this.state;
    return (
      <div>
        <div className="container">
          <Modal modalVisible={modalVisible} onClick={this.closeModal}/>

          <div className="info_profile">
            <input type="password"
              className={`formsInput ${this.showErrorBorder('old_password') ? 'error' : ''}`}
              placeholder="Old Password"
              value={old_password}
              name='old_password'
              onChange={this.onChangeValue}
            />
            <input type="password"
              className={`formsInput ${this.showErrorBorder('password') ? 'error' : ''}`}
              placeholder="New Password"
              value={password}
              name='password'
              onChange={this.onChangeValue}
            />
            <input type="password"
              className={`formsInput ${this.showErrorBorder('confirm_password') ? 'error' : ''}`}
              placeholder="Confirm Password"
              value={confirm_password}
              name='confirm_password'
              onChange={this.onChangeValue}
            />

            <span className="error_field">{error_text}</span>
            <button className="formsButton" onClick={this.postDataRequest}>Save</button>
          </div>
        </div>
      </div>
    );
  }
}