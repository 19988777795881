import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import countdown from 'countdown';
import Slider from 'react-slick';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import InstagramEmbed from 'react-instagram-embed';

import VideoItem from '../components/VideoItem';
import Loader from '../components/Loader';
import SidebarTeams from '../components/SidebarTeams';
import SidebarGames from '../components/SidebarGames';
import { API, fetchData } from '../services';

import { iframeFacebookFeed, iFrameFacebookFeedPart1, iFrameFacebookFeedPart2, defaultPostInstagram } from "../constants";
import newsLogo from '../images/bg.jpg';

import {catchErrors} from "../services/catchErrors";

//TODO: will change
class NextGameTimer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: `${props.date_begin_league} 00:00:00`,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  }

  componentDidMount() {
    const self = this;
    if(this.state.date) {
      countdown(
        new Date(this.state.date),
        (ts) => {
          self.setState({
            days: ts.days,
            hours: ts.hours,
            minutes: ts.minutes,
            seconds: ts.seconds
          })
        },
        countdown.DAYS|countdown.HOURS|countdown.MINUTES|countdown.SECONDS
      );
    }
  }

  render() {
    const {
      date_begin_league,
      name_league
    } = this.props;

    return (
      <div className="home_featureGame">
        <div className="info">
          <h4><i className="fas fa-info-circle" />{name_league}</h4>
          <h4 className="showDate">Start League: <span>{date_begin_league}</span></h4>
        </div>
        {this.state.date ?
          <div className="timer">
            <div className="section">
              <h3>{this.state.days}</h3>
              <h4>Days</h4>
            </div>
            <div className="section time-divder">
              <h3>:</h3>
            </div>
            <div className="section dateNumber">
              <h3>{this.state.hours}</h3>
              <h4>Hours</h4>
            </div>
            <div className="section time-divder">
              <h3>:</h3>
            </div>
            <div className="section dateNumber">
              <h3>{this.state.minutes}</h3>
              <h4>Minutes</h4>
            </div>
            <div className="section time-divder">
              <h3>:</h3>
            </div>
            <div className="section dateNumber">
              <h3>{this.state.seconds}</h3>
              <h4>Seconds</h4>
            </div>
          </div>
          :
          <div className="timer">
            <h4 className="comingSoon"> Coming Soon</h4>
          </div>
        }
      </div>
    )
  }
}

class HomeHeaderNewsWithPictures extends Component {
  render() {
    if(this.props.data.length === 0) {
      return(
        <div className='header-slider-news-with-pictures-container'/>
      )
    } else {
      let settings_with_img = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      };

      return <div className='header-slider-news-with-pictures-wrapper'>
        <Slider className="header-slider-news-with-pictures-container" {...settings_with_img}>
          {
            this.props.data.map((item, index) => {
              return (
                <Link to={`/news/${item.id}`} key={index} className="header-slider-news-with-pictures-item">
                  <img src={item.image} alt=""/>
                  <p>{item.name}</p>
                </Link>
              )
            })
          }
        </Slider>
      </div>
    }

  }
}

class HomeHeaderNews extends Component {

  render() {
    if(this.props.data.length === 0) {
      return(
        <div className="header-news"/>
      )
    } else {
      let settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        pauseOnHover: false,
        vertical: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
          }
          }
        ]
      };
      return (
        <Slider className="header-news" {...settings}>
          {
            this.props.data.map((item, index) => {
              return (
                <Link to={`/news/${item.id}`} key={index} className="header-news-item">
                    <span>{item.date}</span>
                    <p>{item.name}</p>
                </Link>
              )
            })
          }
        </Slider>
      )
    }
  }
}

class HomeSidebarNews extends Component {

  render() {
    if(this.props.data.length === 0) {
      return(
        <div className="header-news"/>
      )
    } else {
      let settings_without_img = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        vertical: true,
        pauseOnHover: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        afterChange: function (currentSlide, nextSlide) {
           let element = document.getElementsByClassName('side-header-news-item slick-current');
           element[0].classList.remove('sidebar-news-active');
           element[0].classList.add('sidebar-news-active');

        },
      };
      let settings_with_img = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1
      };

      return (
        <div className="section recent_Result">
          <div className="title_section">
            <h3>Today on ASL</h3>
          </div>
          <Slider className="sidebar-news-slider-img" {...settings_with_img}>
            {
              this.props.data.map((item, index) => {
                return (
                  <Link to={`/news/${item.id}`} key={index} className="sidebar-news-item">
                    <img src={item.image} alt=""/>
                    <p>{item.name}</p>
                    <span className="border-bottom"/>
                  </Link>
                )
              })
            }
          </Slider>
          <div className="container-for-sidebar-news">
            <Slider className="header-news sidebar-news" {...settings_without_img}>
              {
                this.props.data.map((item, index) => {
                  return (
                    <Link to={`/news/${item.id}`} key={index} className="side-header-news-item for_correctTime">
                        <span>{item.time}</span>
                        <p>{item.name}</p>
                    </Link>
                  )
                })
              }
            </Slider>
          </div>
        </div>
      )
    }
  }
}

const HomePartnersBlock = (props) => {
  const { data } = props;
  if(data && data.length > 0) {
    return (
      <div className='container home-container-partners'>
        <h2 className="home_title">Partners</h2>
        <div className="partners-list">
          {
            props.data.map((item, index) => {
              return (
                <a href={item.url} className='partners-list-item' key={`slide_index_${index}`} target='_blank'>
                  <img src={item.img} alt=''/>
                </a>
              )
            })
          }
        </div>
      </div>
    )
  } else {
    // return (
    //   <Fragment>
    //     <h2 className="home_title">Partners</h2>
    //     <div className='partners-list'/>
    //   </Fragment>
    // )
    return null
  }
};

const HomePageNews = (props) => {
  let { news } = props;

  if(news.length === 5) {
    news = news.slice(0,3);
  }

  return (
    <div className="newsList">
      {news.map((item, index) => (
        <Link to={`/news/${item.id}`} key={index} className={index === 0 ? 'newsItem bestNews' : 'newsItem simpleNews'}>
          <img src={index === 0 ? item.image || newsLogo : item.square_image || newsLogo} alt="" />
          <div className="item_description">
            <h4 className="header4">{item.name}</h4>
            <div className="dateItem">
              <h5><i className="fas fa-clock" /> {item.datetime}</h5>
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
};

const HomePageVideos = (props) => {
  const { videos } = props;

  return (
    <div className="home_video_block">
      <h2 className="home_title">Videos</h2>
      <div className="home_video_list">
        {videos.map((video, index) => (
          <div className="home_video_item" key={index}>
            <VideoItem url={video.url}/>
          </div>
        ))}
      </div>
    </div>
  )
};

const SliderAcademies = (props) => {
  const {
    data
  } = props;

  if(data.length === 0) {
    return null
  } else {
    let settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      arrows: false,
      autoplaySpeed: 5000,
      speed: 1000,
      pauseOnHover: false,
      // vertical: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
    return (
      <Slider
        className="academies-list-slider"
        {...settings}
      >
        {
          data.map((item, index) => {
            return (
              <Link
                to={`/academies/${item.id}/1`}
                key={index}
                className="academies-list-slider-item"
              >
                <img src={item.logo} alt='' />
              </Link>
            )
          })
        }
      </Slider>
    )
  }
};

class Home extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      nextGameDate: '',
      place: '',
      team1: '',
      team2: '',
      referee: '',
      news: [],
      games: [],
      teams: [],
      videos: [],
      partnersData: [],
      academies: [],
      instagramLoading: true,
      social: {
        facebook: '',
        instagram: '',
        // instagram: defaultPostInstagram,
        twitter: ''
      }
    }
  }

  componentDidMount() {
    fetchData(API.home)
      .then((response) => {
        // fetchData(API.news.sidebar)
        //   .then((sidebar_news) => {
        //     fetchData(API.news.header)
        //       .then((header_news) => {
        fetchData(API.academies)
          .then((academies) => {
            fetchData(API.partners)
              .then((partners) => {
                fetchData(API.social_network)
                  .then((social) => {
                    this.setState({
                      social: social
                    })
                  })
                  .catch((error) => console.warn('error social links', error));

                let newGamesArray = [], newTeamsArray = [];

                response.games && response.games.forEach((item) => {
                  if(item.games.length > 0) {
                    newGamesArray.push(item);
                  }
                });

                response.teams && response.teams.forEach((item) => {
                  if(item.teams.table.length > 0) {
                    newTeamsArray.push(item);
                  }
                });

                this.setState({
                  isLoading: false,

                  date_begin_league: response.date_begin,
                  name_league: response.name,

                  news: response.news,
                  games: newGamesArray,
                  teams: newTeamsArray,
                  videos: response.video,
                  academies: academies,
                  partnersData: partners || [],
                }, () => setTimeout(
                  () => this.loederInstagram(), 1000));
              })
              .catch((error) => console.warn('error', error));
          })
          .catch(error => console.warn('error', error));
      })
          // })
      // })
      .catch(e => catchErrors(e));
  }

  loederInstagram = () => {
    this.setState({
      instagramLoading: false
    })
  };

  render() {
    const {
      isLoading,
      news,
      games,
      teams,
      academies,
      videos,
      partnersData,
      date_begin_league,
      name_league,
      social,
      instagramLoading
    } = this.state;

    if(social && social.facebook) {
      // social.facebookNameGroup = social.facebook.split('https://www.facebook.com/')[1];
      social.facebookNameGroup = social.facebook.split('https://www.facebook.com/')[1].split('/')[0];
    }

    if(social && social.twitter) {
      // social.twitterNameGroup = social.twitter.split('https://twitter.com/')[1];
      social.twitterNameGroup = social.twitter.split('https://twitter.com/')[1].split('/')[0];
    }

    if (isLoading) {
      return <Loader/>;
    }

    return (
      <div className="base">
        <div className="emptyHeader" />
        <div className="background place-for-timer-new-version">
          <div className="container feature-game">
            {date_begin_league ?
              <NextGameTimer
                date_begin_league={date_begin_league}
                name_league={name_league}
              /> :
              <SliderAcademies data={academies}/>
              }

          </div>
        </div>

        <div className="container home">
          <div className="home_page_news">
            <h2 className="home_title">News</h2>
            <HomePageNews news={news} />
            <h2 className="home_title only-text-title">Follow us</h2>
          </div>
          <div className="sidebar" style={{display: 'none'}}>
            <SidebarGames games={games} />
            <SidebarTeams teams={teams} />
          </div>
        </div>
        <div className='container'>
          {
            social.facebook || social.instagram || social.twitter ? <div className='social-networks-wrapper'>
              <div className='social-networks-container'>
                <div className='social-section section'>
                  <div className="title_section">
                    <h3>Facebook</h3>
                    <div className="recent_Navigation">
                      <span><i className="fab fa-facebook-square"/></span>
                    </div>
                  </div>
                </div>
                <div dangerouslySetInnerHTML={{
                  __html: social.facebookNameGroup ?
                    `${iFrameFacebookFeedPart1}${social.facebookNameGroup}${iFrameFacebookFeedPart2}`
                    : iframeFacebookFeed
                }} />
              </div>
              <div className='social-networks-container'>
                <div className='social-section section'>
                  <div className="title_section">
                    <h3>Instagram</h3>
                    <div className="recent_Navigation">
                      <span><i className="fab fa-instagram"/></span>
                    </div>
                  </div>
                </div>
                {instagramLoading ? <div>
                  <h3 className='instagram_h3'>Load<span className='instagram_h3_span'>ing...</span></h3>
                </div> : <InstagramEmbed
                  url={social.instagram || defaultPostInstagram}
                  maxWidth={320}
                  hideCaption={false}
                  containerTagName='div'
                  protocol=''
                  injectScript
                  onLoading={() => {}}
                  onSuccess={() => {}}
                  onAfterRender={() => {}}
                  onFailure={() => {}}
                />}
              </div>
              <div className='social-networks-container'>
                <div className='social-section section'>
                  <div className="title_section">
                    <h3>Twitter</h3>
                    <div className="recent_Navigation">
                      <span><i className="fab fa-twitter-square"/></span>
                    </div>
                  </div>
                </div>
                <TwitterTimelineEmbed
                  sourceType="profile"
                  screenName={social.twitterNameGroup || ''}
                  options={{height: 500, width: 320}}
                />
              </div>
            </div> : null
          }
        </div>
        <div className='container home-container-partners'>
          <HomePartnersBlock data={partnersData}/>
        </div>

        <div className="container">
          <HomePageVideos videos={videos}/>
        </div>
      </div>
    );
  }
}

export default Home;
