import React, {Component, Fragment} from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import TeamPDF from '../containers/Teams/TeamPDF';


export class GeneratorPdfTeamRoster extends Component{

  state = {
    teamplayer_profiles: [],
    assistant_coach: null,
    head_coach: null,
    generatePDF: false
  };

  componentDidMount() {
    const { data} = this.props;
    this.setState({
      teamplayer_profiles: data.teamplayer_profiles,
      assistant_coach: data.assistant_coach,
      assistant_coach_picked: data.assistant_coach_picked,
      head_coach: data.head_coach,
      head_coach_picked: data.head_coach_picked,
      title: data.title,
      academy: data.academy
    })
  }

  switchOnPDFGeneration = () => {
    this.setState(prevState =>
      prevState.generatePDF ? null : { ...prevState, generatePDF: true }
    );
  };

  _selectAll = () => {
    this.setState(({ teamplayer_profiles }) => ({
      teamplayer_profiles: teamplayer_profiles.map((item) => ({...item, picked: true})),
      assistant_coach_picked: true,
      head_coach_picked: true,
      generatePDF: false,
    }))
  };

  _clearAll = () => {
    this.setState(({ teamplayer_profiles }) => ({
      teamplayer_profiles: teamplayer_profiles.map((item) => ({...item, picked: false})),
      assistant_coach_picked: false,
      head_coach_picked: false,
      generatePDF: false,
    }))
  };

  selectCurrentItem = (type, id = '') => {
    if(type === 'player') {
      this.setState(({ teamplayer_profiles }) => ({
        teamplayer_profiles: teamplayer_profiles.map((item) => item.id === id ? ({...item, picked: !item.picked}) : ({...item})),
        generatePDF: false,
      }))
    }

    if(type === 'assistant_coach') {
      this.setState(({ assistant_coach_picked }) => ({
        assistant_coach_picked: !assistant_coach_picked,
        generatePDF: false,
      }))
    }

    if(type === 'head_coach') {
      this.setState(({ head_coach_picked }) => ({
        head_coach_picked: !head_coach_picked,
        generatePDF: false,
      }))
    }
  };

  readyForGeneratePdf = () => {
    const {assistant_coach_picked, head_coach_picked, teamplayer_profiles} = this.state;

    return assistant_coach_picked || head_coach_picked || teamplayer_profiles.some((item) => item.picked === true)
  };

  render() {
    const { visibility, toggleEvent } = this.props;
    const { teamplayer_profiles, generatePDF, title } = this.state;

    const list = teamplayer_profiles.map(
      (item, index) =>
        <PDFRosterItem key={`roster_item_${index}`} data={item} type='player' event={this.selectCurrentItem}/>
    );

    if(!visibility) {
      return null
    }

    return <div className='bg_modal'>
      <div className='modal info_picker_for_pdf'>
        <div
          className="closeSignIn"
        >
          <button
            className="close"
            onClick={() => {
              toggleEvent()
            }}
          >
            <i className="fas fa-times"/>
          </button>
        </div>
        <div className='action_panel'>
          <div className='action_panel_btn' onClick={this._selectAll}>
            Select all
          </div>
          <div className='action_panel_btn' onClick={this._clearAll}>
            Clear all
          </div>
        </div>
        <div className='pdf_roster_list'>
          <StaffList data={this.state} event={this.selectCurrentItem}/>
          <h5>Roster</h5>
          {list}
        </div>
        <div className='gen_pdf_roster_btn_container'>
          <button
            disabled={!this.readyForGeneratePdf()}
            style={!this.readyForGeneratePdf() ? { backgroundColor: '#ededed', borderColor: '#ededed', color: '#000'} : {}}
            className="PDFDownloadBTN"
            onClick={this.switchOnPDFGeneration}
          >
            {generatePDF ? (
              <PDFDownloadLink
                document={
                  <TeamPDF team={this.state} fileName={`${title}.pdf`} />
                }
              >
                {({ loading }) =>
                  loading
                    ? 'Generating roster PDF'
                    : 'Dowload roster PDF'
                }
              </PDFDownloadLink>
            ) : (
              <span>Generate Roster PDF</span>
            )}
          </button>
        </div>
      </div>
    </div>
  }
}

const StaffList = (props) => {
  const { data, event } = props;

  if(data.head_coach || data.assistant_coach) {
    return <Fragment>
      <h5>Staff</h5>
      {data.head_coach ? <PDFRosterItem data={data} type='head_coach' event={event}/> : null}
      {data.assistant_coach ? <PDFRosterItem data={data} type='assistant_coach' event={event}/> : null}
    </Fragment>
  }

  return null
};

class PDFRosterItem extends Component {

  render() {
    const { data, type, event } = this.props;

    if(type === 'player') {
      return <div className='pdf_roster_item' onClick={() => event(type, data.id)}>
        <div className='pdf_roster_isPicked'>
          {data.picked ?
            <div style={{ width: 10, height: 10, backgroundColor: '#fec722' }} className='pdf_roster_isPicked'/> :
            null
            }
        </div>
        <span>
          {data.player.first_name} {data.player.last_name}
        </span>
      </div>
    }

    if(type === 'head_coach') {
      return <div className='pdf_roster_item' onClick={() => event(type)}>
        <div className='pdf_roster_isPicked'>
          {data.head_coach_picked ?
            <div style={{ width: 10, height: 10, backgroundColor: '#fec722' }} className='pdf_roster_isPicked'/> :
            null
            }
        </div>
        <span className='icon_staff_pdf_team_roster'>HC</span>
        <span>
          {data.head_coach.first_name} {data.head_coach.last_name}
        </span>
      </div>
    }

    if(type === 'assistant_coach') {
      return <div className='pdf_roster_item' onClick={() => event(type)}>
        <div className='pdf_roster_isPicked'>
          {data.assistant_coach_picked ?
            <div style={{ width: 10, height: 10, backgroundColor: '#fec722' }} className='pdf_roster_isPicked'/> :
            null
            }
        </div>
        <span className='icon_staff_pdf_team_roster'>AC</span>
        <span>
          {data.assistant_coach.first_name} {data.assistant_coach.last_name}
        </span>
      </div>
    }

    return <div className='pdf_roster_item'>
      <span>
        {data.player.first_name} {data.player.last_name}
      </span>
    </div>
  }
}
