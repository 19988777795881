import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
// import { PDFViewer } from '@react-pdf/renderer';

import Loader from '../../components/Loader';
import { fetchDetail, getUser } from '../../services';

import DefaultAvatar from '../../images/default-avatar.png';
import { TShirt } from '../../components/T-shirt';
import { GeneratorPdfTeamRoster } from "../../components/GeneratorPdfTeamRoster";
import TeamPDF from './TeamPDF';

class TeamDetail extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      team: {},
      hasPDFPermission: false,
      generatePDF: false,
      toggleModalPDF: false
    };
  }

  componentDidMount() {
    const user = getUser();
    if (
      user &&
      user.has_additional_perms &&
      user.has_additional_perms === true
    ) {
      this.setState({ hasPDFPermission: true });
    }
    fetchDetail(
      // `teams/${this.props.match.params.gender}/${this.props.match.params.id}`
      `teams/${this.props.match.params.id}`
    )
      .then(team => {
        team.head_coach_picked = team.head_coach ? false : null;
        team.assistant_coach_picked = team.assistant_coach ? false : null;
        team.teamplayer_profiles.map((item) => item.picked = false);
        this.setState({
          isLoading: false,
          team: team,
        });
      })
      .catch(error => {
        console.log('error', error);
        this.setState({ isLoading: false });
      });
  }

  switchOnPDFGeneration = () => {
    this.setState(prevState =>
      prevState.generatePDF ? null : { ...prevState, generatePDF: true }
    );
  };

  toggleModalForRosterPDF = () => {
    this.setState(({ toggleModalPDF }) => ({
      toggleModalPDF: !toggleModalPDF
    }))
  };

  render() {
    const { team, hasPDFPermission, generatePDF, toggleModalPDF } = this.state;
    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <div className="base">
        <div className="emptyHeader" />

        <div className="title">
          <h2 className="header2 container">{team.title}</h2>
        </div>
        {toggleModalPDF ?
          <GeneratorPdfTeamRoster data={team} visibility={toggleModalPDF} toggleEvent={this.toggleModalForRosterPDF}/>
          :
          null
        }

        <div className="container">
          <div className="team_detail">
            <div className="head_detail">
              <div className="logo">
                <div className="logo_border">
                  <img src={team.logo} alt="" />
                </div>
              </div>
              <div className="team_description">
                {/* <PDFViewer
                  style={{
                    position: 'absolute',
                    width: '100vw',
                    height: '100vh',
                    top: 0,
                    left: 0,
                    zIndex: 100,
                  }}
                >
                  <TeamPDF team={team} fileName={`${team.title}.pdf`} />
                </PDFViewer> */}

                {/*{hasPDFPermission && (*/}
                  {/*<button*/}
                    {/*className="PDFDownloadBTN"*/}
                    {/*onClick={this.switchOnPDFGeneration}*/}
                  {/*>*/}
                    {/*{generatePDF ? (*/}
                      {/*<PDFDownloadLink*/}
                        {/*document={*/}
                          {/*<TeamPDF team={team} fileName={`${team.title}.pdf`} />*/}
                        {/*}*/}
                      {/*>*/}
                        {/*{({ loading }) =>*/}
                          {/*loading*/}
                            {/*? 'generating roster PDF'*/}
                            {/*: 'Dowload roster PDF'*/}
                        {/*}*/}
                      {/*</PDFDownloadLink>*/}
                    {/*) : (*/}
                      {/*<span>generate Roster PDF</span>*/}
                    {/*)}*/}
                  {/*</button>*/}
                {/*)}*/}
                {hasPDFPermission && (<button className="PDFDownloadBTN" onClick={this.toggleModalForRosterPDF}>Generate Roster PDF</button>)}

                <div className="description">
                  <p>{team.description}</p>
                </div>
                <div className="info_block">
                  <div className="row">
                    <h4>
                      Academy: <span>{team.academy}</span>
                    </h4>
                    {team.head_coach && (
                      <h4>
                        Head Coach:{' '}
                        <span>
                          {team.head_coach.first_name}{' '}
                          {team.head_coach.last_name}
                        </span>
                      </h4>
                    )}
                    {team.assistant_coach && (
                      <h4>
                        Assistant Coach:{' '}
                        <span>
                          {team.assistant_coach.first_name}{' '}
                          {team.assistant_coach.last_name}
                        </span>
                      </h4>
                    )}
                  </div>
                  <div className="row">
                    <h4>
                      Gender:{' '}
                      <span>
                        {this.state.team.gender === 1 ? 'Boys' : 'Girls'}
                      </span>
                    </h4>
                    <div
                      className="homeTshirt"
                      style={{ fill: team.home_color }}
                    >
                      <TShirt />
                    </div>
                    <div
                      className="guestTshirt"
                      style={{ fill: team.guest_color }}
                    >
                      <TShirt />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 className="header3">Team Roster</h4>
            <div className="minContainer">
              {team.teamplayer_profiles.map((player, index) => (
                <Link
                  to={'/player/detail/' + player.player.id + '/'+ team.id }
                  className="player"
                  key={index}
                >
                  <img
                    src={player.player.img || DefaultAvatar}
                    alt={player.player.name}
                  />
                  <h4>{player.player.name}</h4>
                  <h4 className='hide-info-for-mobile'>
                    Pos:
                    <span>
                      {player.main_position !== '' && player.position !== ''
                        ? ' ' + player.main_position + '/' + player.position
                        : player.main_position !== ''
                        ? ' ' + player.main_position
                        : ' ' + player.position}
                    </span>
                  </h4>
                  <h4 className='hide-info-for-mobile'>
                    #<span>{player.number}</span>
                  </h4>
                  {
                    hasPDFPermission ? <h4>
                      {player.player.date_birth}
                    </h4> : null
                  }
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamDetail;
