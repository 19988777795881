import React, { Component, Fragment } from 'react';

import {API, fetchDetail, updateObject} from "../services";

export class ImportPlayersForm extends Component {

  state = {
    isLoading: true,
    teams: [],
    filteredTabs: [],
    currentTeamId: null,
    pickedPlayerToRoster: [],
    defTeamRoster: [],
    currentCollapsibleTeamRoster: [],
    loadingCollapsibleRoster: false
  };

  componentDidMount() {
    const { teams, currentTeamId } = this.props;

    this.setState({
      isLoading: false,
      teams: teams,
      currentTeamId: currentTeamId,
      filteredTabs: teams.filter((team) => team.id !== currentTeamId).map((item) => ({...item, opened: false}))
    }, () => this._getRosterInImportPLayersForm(currentTeamId, 'defTeamRoster'))
  }

  _toggleCollapsibleRoster =(id) => {
    this.setState(({ filteredTabs }) => ({
      filteredTabs: filteredTabs.map((item) => item.id === id ? ({...item, opened: !item.opened}) : ({...item, opened: false}))
    }), () => this._getRosterInImportPLayersForm(id, 'currentCollapsibleTeamRoster'))
  };

  _getRosterInImportPLayersForm = (id, name) => {
    this.setState({
      loadingCollapsibleRoster: true
    });


    fetchDetail(API.profile.academyOwner.teams, id)
      .then((response) => {
        let { pickedPlayerToRoster, defTeamRoster } = this.state;
        let newData = [];
        if(name !== 'defTeamRoster') {

          response.team_to.forEach((i) => {
            if(defTeamRoster.findIndex(fI => fI.player.id === i.player.id) < 0) {
              newData.push(i);
            }
          });

          newData.map((item) => item.pickedToOtherRoster = pickedPlayerToRoster.filter(i => i.id === item.player.id).length > 0);

          this.setState({
            [name]: newData,
            loadingCollapsibleRoster: false
          });
        } else {
          this.setState({
            [name]: response.team_to,
            loadingCollapsibleRoster: false
          })
        }

      })
      .catch((error) => console.warn('error', error));
  };

  _actionWithPlayerForOtherRoster = (player) => {
    const {
      pickedPlayerToRoster
    } = this.state;

    if(pickedPlayerToRoster.filter(i => i.id === player.player.id).length > 0) {
      this.setState(({ pickedPlayerToRoster, currentCollapsibleTeamRoster }) => ({
        pickedPlayerToRoster: pickedPlayerToRoster.filter(i => i.id !== player.player.id),
        currentCollapsibleTeamRoster: currentCollapsibleTeamRoster.map(pl => pl.player.id === player.player.id ? ({...pl, pickedToOtherRoster: false}) : ({...pl}))
      }));
    } else {
      this.setState(({ pickedPlayerToRoster, currentCollapsibleTeamRoster }) => ({
        pickedPlayerToRoster: [...pickedPlayerToRoster, {id: player.player.id, name: player.player.name, teamId: player.team}],
        currentCollapsibleTeamRoster: currentCollapsibleTeamRoster.map(pl => pl.player.id === player.player.id ? ({...pl, pickedToOtherRoster: true}) : ({...pl}))
      }))
    }
  };

  _ImportPLayerAndSave = () => {
    const {pickedPlayerToRoster } = this.state;
    const { currentTeamId, _getRoster, _toggleImportPlayersForm } = this.props;

    let getStringParams, newArray = [];

    pickedPlayerToRoster.map((i) => {
      newArray.push(i.id);

    });

    getStringParams = newArray.join(',');

    updateObject(`${API.profile.academyOwner.teams.import}${currentTeamId}/?ids=${getStringParams}`,{}, '', 'delete_/')
      .then(response =>
        {
          _toggleImportPlayersForm();
          _getRoster(currentTeamId);
        }
      )
      .catch(error => console.warn('error', error))

  };

  render() {

    const { _toggleImportPlayersForm } = this.props;


    const {
      isLoading,
      filteredTabs,
      currentCollapsibleTeamRoster,
      loadingCollapsibleRoster,
      pickedPlayerToRoster
    } = this.state;

    const { teams } = this.props;


    if(isLoading) {
      return null
    }

    return <div className='bg_modal'>
      <div className='modal import-players-wrapper'>
        <div
          className="closeSignIn"
        >
          <button
            className="close"
            onClick={() => {
              _toggleImportPlayersForm()
            }}
          >
            <i className="fas fa-times"/>
          </button>
        </div>
        <div className='import-container-row'>
          <div className='import-players-container'>
            <CollapsibleListOfTeams
              data={filteredTabs}
              _toggleCollapsibleRoster={this._toggleCollapsibleRoster}
              currentCollapsibleTeamRoster={currentCollapsibleTeamRoster}
              loadingCollapsibleRoster={loadingCollapsibleRoster}
              _actionWithPlayerForOtherRoster={this._actionWithPlayerForOtherRoster}
            />
          </div>
          <div className='import-players-container picked-players-to-roster'>
            <PlayersListFromOtherRosters data={pickedPlayerToRoster} teams={teams}/>
          </div>
        </div>
        {/*</div>*/}
        <div className='import-container-btn' onClick={this._ImportPLayerAndSave}>
          <span>
            Import
          </span>
        </div>
      </div>

    </div>
  }
}

const CollapsibleListOfTeams = (props) => {
  const {
    data,
    _toggleCollapsibleRoster,
    currentCollapsibleTeamRoster,
    loadingCollapsibleRoster,
    _actionWithPlayerForOtherRoster
  } = props;

  const list = data.map((item, index) =>
    {
      return <div key={`tab_in_form_index${index}`} className='collapsible-team-item'>
      <div className={`collapsible-team-item-title ${item.opened ? 'current-coll-tab' : ''}`} onClick={() => _toggleCollapsibleRoster(item.id)}>
        <span>
          {item.title}
        </span>

        <i className='fas fa-chevron-down extra_class'/>
      </div>
      <CollapsibleRosterForTeam
        opened={item.opened}
        data={currentCollapsibleTeamRoster}
        loadingCollapsibleRoster={loadingCollapsibleRoster}
        _actionWithPlayerForOtherRoster={_actionWithPlayerForOtherRoster}
      />
    </div>}
  );

  return <Fragment>
    {list}
  </Fragment>
};

const CollapsibleRosterForTeam = (props) => {
  const {
    opened,
    data,
    loadingCollapsibleRoster,
    _actionWithPlayerForOtherRoster
  } = props;

  const list = data.map((item, index) =>
    <div key={`coll_roster_team_player_item_index${index}`} className='coll-team-roster-player' onClick={() => _actionWithPlayerForOtherRoster(item)}>
      <div className='coll_team-roster-player-radiobutton'>
        {item.pickedToOtherRoster ? <div className='coll_team-roster-player-radiobutton-content'/> : null}
      </div>
      {item.player.name}
    </div>);

  if(!opened) {
    return null
  }

  if(loadingCollapsibleRoster) {
    return <div>
      <div className="lds-ellipsis">
        <div/>
        <div/>
        <div/>
        <div/>
      </div>
    </div>
  }

  return <div className='collapsible-team-item-roster'>
    {list}
  </div>
};

const PlayersListFromOtherRosters = (props) => {
  const { data, teams } = props;

  const list = data.map((item, index) =>
    <div className='picked-players-to-roster-item' key={`picked_player_item_${index}`}>
      <span className='picked-player-name'>
        {item.name}
      </span>
      <span className='picked-player-team'>
        ({teams.filter(i => i.id === item.teamId)[0].title})
      </span>
    </div>
  )

  return <Fragment>
    {list}
  </Fragment>
};