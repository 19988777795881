import {API_URL} from '../config';

function getAuthHeader() {
  if(localStorage.getItem('token')) {
    return {Authorization: `JWT ${localStorage.getItem('token')}`}
  } else {
    return {}
  }
}

export function registration(path, data){
  if(path.hasOwnProperty('login')) {
    path = path.login;

    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/${path}/`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(response =>
          response.json().then(json =>
            response.ok ? resolve(json) : reject(json)
          )
        ).catch(e => console.log(e))
    });
  }
}

export function fetchData(path, withoutSlash = false) {
  if (path.hasOwnProperty('data')) {
    path = path.data;
  }
  let fullUrl = `${API_URL}/${path}/`;

  if(withoutSlash) {
    fullUrl = `${API_URL}/${path}`;
  }

  return new Promise((resolve, reject) => {
    fetch(fullUrl,{headers: getAuthHeader()})
      .then((response) => {
        if (response.status === 401) {
          return reject(Error("logout"));
        }
        return response.json().then(json =>
          response.ok ? resolve(json) : reject(json))
    })
      .catch(e => console.log(e))
  })
}

export function fetchDetail(path, id) {
  if (path.hasOwnProperty('details')) {
    path = path.details;
    path = path.replace('{id}', id);
  }
  let fullUrl = `${API_URL}/${path}/`;

  return new Promise((resolve, reject) => {
    fetch(fullUrl,{headers: getAuthHeader()})
      .then(response =>
        response.json().then(json =>
          response.ok ? resolve(json) : reject(json)))
      .catch(e => console.log(e))
  })
}

export function createObject(path, obj) {
  if (path.hasOwnProperty('create')) {
    path = path.create;
  }
  let fullUrl = `${API_URL}/${path}/`;
  let headers = getAuthHeader();
  headers['Accept'] = 'application/json';
  headers['Content-Type'] = 'application/json';

  return new Promise((resolve, reject) => {
    fetch(fullUrl, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: headers
    })
      .then((response) => {
        if (response.status === 204) {
          resolve(true);
        } else {
          return response.json().then(json =>
            response.ok ? resolve(json) : reject(json))
        }
      });
  });
}

export function updateObject(path, obj, id='', options='') {
  if (path.hasOwnProperty('update')) {
    path = path.update;
    if(id !== '') {
      path = path.replace('{id}', id);
    }
  }
  let fullUrl = `${API_URL}/${path}/`;

  if(options === 'delete_/') {
    fullUrl = `${API_URL}/${path}`;
  }

  let headers = getAuthHeader();
  headers['Accept'] = 'application/json';
  headers['Content-Type'] = 'application/json';

  return new Promise((resolve, reject) => {
    fetch(fullUrl, {
      method: 'PATCH',
      body: JSON.stringify(obj),
      headers: headers
    })
      .then((response) =>
        response.json()
          .then(json =>
            response.ok ? resolve(json) : reject(json))
      );
  });
}

export function patchObject(path, obj, id='') {
  if (path.hasOwnProperty('patch')) {
    path = path.patch;
    if(id !== '') {
      path = path.replace('{id}', id);
    }
  }
  let fullUrl = `${API_URL}/${path}/`;
  let headers = getAuthHeader();
  headers['Accept'] = 'application/json';
  headers['Content-Type'] = 'application/json';

  return new Promise((resolve, reject) => {
    fetch(fullUrl, {
      method: 'PATCH',
      body: JSON.stringify(obj),
      headers: headers
    })
      .then(response =>
        response.json()
          .then(json =>
            response.ok ? resolve(json) : reject(json))
      );
  });
}

export function deleteObject(path, id='') {
  if (path.hasOwnProperty('delete')) {
    path = path.delete;
    if(id !== '') {
      path = path.replace('{id}', id);
    }
  }
  let fullUrl = `${API_URL}/${path}/`;
  let headers = getAuthHeader();
  headers['Accept'] = 'application/json';
  headers['Content-Type'] = 'application/json';

  return new Promise((resolve, reject) => {
    fetch(fullUrl, {
      method: 'DELETE',
      headers: headers
    })
      .then(() => resolve(true));
  });
}

export function validateEmail (email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}