export const hackySplitString = (str, maxChunkLength) => {
  if (str.length <= maxChunkLength) return [str];
  const firstPartRaw = str.substring(0, maxChunkLength);
  const lastWhiteSpace1 = firstPartRaw.lastIndexOf(' ');
  if (lastWhiteSpace1 > 0) {
    const head = str.substring(0, lastWhiteSpace1);
    const tail = str.substring(lastWhiteSpace1).trim();
    return [head, tail];
  }
  return [str];
};
