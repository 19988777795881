import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import Loader from '../../components/Loader';
import {API, fetchData} from '../../services';

class TeemListItem extends Component {
  render() {
    const team = this.props.team;

    return (
      <Link to={/teams/+ this.props.gender+ '/' + team.id} className="team_item">
        <div className="logo">
          <div className="logo_border">
            <img src={team.logo} alt={team.title}/>
          </div>
        </div>
        <div className="team_info">
          <div className="head_info">
            <div className="row">
              <h4>{team.title}</h4>
              <h4>Academy: <span>{team.academy}</span></h4>
            </div>
            <div className="row">
              <h4>Age: <span>{team.league}</span></h4>
              {team.head_coach && <h4>Head Coach: <span>{team.head_coach.first_name} {team.head_coach.last_name}</span></h4>}
            </div>
          </div>
          <h4>{team.description}</h4>
        </div>
      </Link>
    )
  }
}

class TeamsList extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      ages: [],
      teams: [],
      gender: 1,
      sortedBy: 'U8',
      filtered_teams: []
    }
  }

  componentDidMount() {
    fetchData(API.ages)
      .then((ages) => {
        ages = ages.filter(age => age.team_exists === true);
        fetchData(`teams/?gender=${this.state.gender}`, true)
          .then((teams) => {
            teams.forEach((team) => {
              team['league'] = ages.filter((age) =>team.age === age.id)[0].title;
            });
            let defaultFilterAgesId = ages[0].id;
            let defaultFilterAgesTitle = ages[0].title;
            let filteredTeams = teams.filter((team) => team.age === defaultFilterAgesId);
            this.setState({
              isLoading: false,
              teams: teams,
              filtered_teams: filteredTeams,
              ages: ages,
              defaultAge: defaultFilterAgesTitle,
              sortedBy: defaultFilterAgesTitle,
              defaultAgeId: defaultFilterAgesId
            })

          });
      });
  }


  filterTeams(key) {
    let filteredBy = [{id: 0, title: 'all'}];
    if(key !== null) {
      filteredBy = this.state.ages.filter((age) => age.id === key);
    }
    this.setState({
      filtered_teams: key ? this.state.teams.filter((team) => team.age === key) : this.state.teams,
      sortedBy: filteredBy[0].title
    })
  }

  showTeamsByGender(gender) {
    this.setState({gender: gender});
    fetchData(API.ages)
      .then((ages) => {
        fetchData(`teams/?gender=${this.state.gender}`, true)
          .then((teams) => {
            teams.forEach((team) => {
              team['league'] = ages.filter((age) =>team.age === age.id)[0].title;
            });
            let defaultAge = this.state.defaultAge;
            let filteredTeams = teams.filter((team) => team.age === this.state.defaultAgeId);
            this.setState({
              isLoading: false,
              teams: teams,
              filtered_teams: filteredTeams,
              sortedBy: defaultAge,
              ages: ages
            })

          });
      });
  }

  render() {
    if(this.state.isLoading) {
      return (
        <Loader/>
      )
    }

    let teams = (<div className="teams_list minContainer">
      <h3 className="notFoundTeams">There are no teams in this age</h3>
    </div>);

    if (this.state.filtered_teams.length) {
      teams = (
        <div className="teams_list minContainer">
          {this.state.filtered_teams.map((team, index) => <TeemListItem key={index} team={team} gender={this.state.gender} />)}
        </div>
      )
    }

    return (
      <div className="base">
        <div className="emptyHeader" />

        <div className="title">
          <h2 className="header2 container">TEAMS</h2>
        </div>

        <div className="container">
          <div className="nextNavigation navigationCalendar">
            <div className={this.state.gender === 1 ? 'nextTab clicked' : 'nextTab'} onClick={() => this.showTeamsByGender(1)}>Boys</div>
            <div className={this.state.gender === 2 ? 'nextTab clicked' : 'nextTab'} onClick={() => this.showTeamsByGender(2)}>Girls</div>
          </div>
          <div className="nextNavigation">
            <div className="teams_filter">
              {this.state.ages.map((age, index) => (
                <div
                  className={this.state.sortedBy === age.title ? 'nextTab clicked' : 'nextTab'}
                  key={index}
                  onClick={() => {this.filterTeams(age.id)}}
                >
                  {age.title}
                </div>
              ))}
              <div
                className={this.state.sortedBy === 'all' ? 'nextTab clicked' : 'nextTab'}
                onClick={() => this.filterTeams(null)}
              >
                All
              </div>
            </div>
          </div>
          {teams}
        </div>
      </div>
    );
  }
}

export default TeamsList;
