export const POSITIONS = [
  {id: 'GK', title: 'GK'},
  {id: 'CB', title: 'CB'},
  {id: 'RB', title: 'RB'},
  {id: 'LB', title: 'LB'},
  {id: 'CM', title: 'CM'},
  {id: 'RM', title: 'RM'},
  {id: 'LM', title: 'LM'},
  {id: 'CF', title: 'CF'}
];

export const months = [
  {
    text : 'January',
    value : '01'
  },
  {
    text : 'February',
    value : '02'
  },
  {
    text : 'March',
    value : '03'
  },
  {
    text : 'April',
    value : '04'
  },
  {
    text : 'May',
    value : '05'
  },
  {
    text : 'June',
    value : '06'
  },
  {
    text : 'July',
    value : '07'
  },
  {
    text : 'August',
    value : '08'
  },
  {
    text : 'September',
    value : '09'
  },
  {
    text : 'October',
    value : '10'
  },
  {
    text : 'November',
    value : '11'
  },
  {
    text : 'December',
    value : '12'
  }
];

export const days = [
  {
    text : '01',
    value : '01'
  },
  {
    text : '02',
    value : '02'
  },
  {
    text : '03',
    value : '03'
  },
  {
    text : '04',
    value : '04'
  },
  {
    text : '05',
    value : '05'
  },
  {
    text : '06',
    value : '06'
  },
  {
    text : '07',
    value : '07'
  },
  {
    text : '08',
    value : '08'
  },
  {
    text : '09',
    value : '09'
  },
  {
    text : '10',
    value : '10'
  },
  {
    text : '11',
    value : '11'
  },
  {
    text : '12',
    value : '12'
  },
  {
    text : '13',
    value : '13'
  },
  {
    text : '14',
    value : '14'
  },
  {
    text : '15',
    value : '15'
  },
  {
    text : '16',
    value : '16'
  },
  {
    text : '17',
    value : '17'
  },
  {
    text : '18',
    value : '18'
  },
  {
    text : '19',
    value : '19'
  },
  {
    text : '20',
    value : '20'
  },
  {
    text : '21',
    value : '21'
  },
  {
    text : '22',
    value : '22'
  },
  {
    text : '23',
    value : '23'
  },
  {
    text : '24',
    value : '24'
  },
  {
    text : '25',
    value : '25'
  },
  {
    text : '26',
    value : '26'
  },
  {
    text : '27',
    value : '27'
  },
  {
    text : '28',
    value : '28'
  },
  {
    text : '29',
    value : '29'
  },
  {
    text : '30',
    value : '30'
  },
  {
    text : '31',
    value : '31'
  }
];

export const OLD_URL = 'bcasl.com';

export const iframeFacebookFeed = '<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FAcademySuperLeagueSoccer%2F&tabs=timeline&width=324&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2051452638454305" width="324" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>';
export const iFrameFacebookFeedPart1 = '<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F';
export const iFrameFacebookFeedPart2 = '%2F&tabs=timeline&width=320&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2051452638454305" width="320" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>';
export const defaultPostInstagram = 'https://www.instagram.com/p/By0o-NeAv3e/';
