import React, { Component, Fragment } from 'react';

import Loader from '../../../components/Loader';

import { API_URL } from "../../../config";
import {API, fetchData, createObject, updateObject} from '../../../services/';
import {catchErrors} from "../../../services/catchErrors";


const ICONCrossDeleteReferee = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 960" className="Icon" width="12" height="12" fill="#ffffff">
    <path d="M628 480l189 190c8.7 8 13.7 17.5 15 28.5s-1.3 20.2-8 27.5c-28.7 36.7-61.3 69.3-98 98-7.3 6.7-16.5 9.3-27.5 8s-20.8-6-29.5-14L480 628 290 818c-8 8-17.5 12.7-28.5 14s-20.5-1.3-28.5-8c-36-28.7-68.3-61-97-97-6.7-8-9.3-17.5-8-28.5 1.3-11 6-20.5 14-28.5l190-190-189-189c-8.7-8.7-13.7-18.5-15-29.5-1.3-11 1.3-20.2 8-27.5 28-36 60.3-68.7 97-98 8-6.7 17.5-9.3 28.5-8s20.5 6.3 28.5 15l190 181 189-181c8.7-8.7 18.5-13.7 29.5-15s20.2 1.3 27.5 8c36.7 28.7 69.3 61.3 98 98 6.7 7.3 9.3 16.5 8 27.5s-6.3 20.8-15 29.5L628 480z"></path>
  </svg>;
};

const RolePickerReferee = (props) => {
  const { item, game, takePickedReferee, role_for_game } = props;

  const roles = {
    1: 'HR',
    2: 'AR'
  };

  return <div
    className={`pick-referee-for-head-referee ${item.referee_type === role_for_game ? '' : 'picked-referee-for-opacity'}`}
    onClick={() => takePickedReferee(game, item, game.type, role_for_game)}
  >
    <span>{roles[role_for_game]}</span>
  </div>
};

const StatusAssignReferee = (props) => {
  const { ref_status } = props;

  const templateStatus1 = <div className="status-assign-referee want-judged">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 960" className="Icon" width="12" height="12" fill="#ffffff">
      <path d="M915 178.9c8 6.7 12.3 15.5 13 26.5s-2.3 21.2-9 30.5c-252.7 360.7-389.3 555.3-410 584-21.3 28.7-48.8 43.2-82.5 43.5S365 849.2 343 819.9l-278-391c-6.7-9.3-9.7-19.7-9-31s5-20.3 13-27c34-29.3 72-55.7 114-79 8.7-4.7 18.3-5.3 29-2s19.3 9.7 26 19l188 264 321-456c6.7-9.3 15.2-15.5 25.5-18.5s20.2-2.2 29.5 2.5c44 24 81.7 50 113 78z"></path>
    </svg>
  </div>;
  const templateStatus2 = <div className="status-assign-referee not-want-judged">
    <ICONCrossDeleteReferee/>
  </div>;
  const templateStatus3 = <div className="status-assign-referee maybe-judged">
    ?
  </div>;

  const Templates = {
    1: templateStatus1,
    2: templateStatus2,
    3: templateStatus3
  };

  return <Fragment>{Templates[ref_status]}</Fragment>
};

const TemplateTypeRefereeForGame = (props) => {
  const { game, takePickedReferee, item } = props;
  if(game.type === '2') {
    return <Fragment>
      <RolePickerReferee item={item} takePickedReferee={takePickedReferee} game={game} role_for_game={1}/>
    </Fragment>
  } else {
    if(item.qualification === 1) {
      return <Fragment>
        <RolePickerReferee item={item} takePickedReferee={takePickedReferee} game={game} role_for_game={1}/>
        <RolePickerReferee item={item} takePickedReferee={takePickedReferee} game={game} role_for_game={2}/>
      </Fragment>
    } else {
      return <RolePickerReferee item={item} takePickedReferee={takePickedReferee} game={game} role_for_game={2}/>
    }
  }
};

class AssignRefereePicker extends Component {

  takePickedReferee = (game, referee, type_game, type_referee_for_game) => {
    let dataRequest = {
      game_referee_staff: []
    };

    const { _addError } = this.props;

    if(type_game === '2') {
      dataRequest = {
        game_referee_staff: [
          {referee: referee.referee_id, qualification: type_referee_for_game }
        ]
      };
    } else {
      let data = [];
      game.assign_referee.map((item) => {
        if(item.referee_id === referee.referee_id && item.referee_type === null) {
          data.push({
            referee: item.referee_id,
            qualification: type_referee_for_game
          });
        } else {
          if(item.referee_type !== null) {
            if(item.referee_id !== referee.referee_id) {
              data.push({
                referee: item.referee_id,
                qualification: item.referee_type
              });
            } else {
              data.push({
                referee: item.referee_id,
                qualification: type_referee_for_game
              })
            }
          }
        }
      });

      dataRequest = {
        game_referee_staff: data
      };
    }

    updateObject(API.profile.moderator, dataRequest, game.id)
      .then(() => {
        this.props.afterPickedReferee()
      })
      .catch((error) =>
        _addError(game.id, error.referee[0])
      );

  };

  deletePickedReferee = (game, referee, type_game) => {
    let dataRequest = {
      game_referee_staff: []
    };

    const { _addError } = this.props;

    if(type_game === '1') {
      let data = [];
      game.assign_referee.map((item) => {
        if(item.referee_id !== referee.referee_id) {
          data.push({
            referee: item.referee_id,
            qualification: item.referee_type
          })
        }
      });
      dataRequest.game_referee_staff = data;
    }

    updateObject(API.profile.moderator, dataRequest, game.id)
      .then(() => {
        this.props.afterPickedReferee();
      })
      .catch((error) => _addError(game.id, error.referee[0]));
  };

  render () {
    const { data, game, _deleteError } = this.props;

    const list = data.map((item, index) => {
      return (
        <div className={`assign-referees-item ${item.referee_type ? ' has-role' : ''}`} key={index}>
          <StatusAssignReferee ref_status={item.status} />
          <span>{item.referee}</span>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <TemplateTypeRefereeForGame game={game} takePickedReferee={this.takePickedReferee} item={item}/>
            <div
              className='status-assign-referee not-want-judged delete-pick-for-moder'
              onClick={() => this.deletePickedReferee(this.props.game, item, this.props.game.type)}
            >
              <ICONCrossDeleteReferee/>
            </div>
          </div>
        </div>
      )
    });

    return (
      <div className="assign-referees-block">
        {game.error ?
          <div className='mod_game_referee_error'>
            <div className='mod_game_referee_error_content'>
              <h5>Error</h5>
              <span>{game.error}</span>
              <div className='mod_game_referee_error_btn' onClick={() => _deleteError(game.id)}>Okay</div>
            </div>
          </div>
          : null}
        <h5>Assign referee(s):</h5>
        {list}
      </div>
    )
  }
}

class GamesGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      weekId: props.currentWeek,
      games: [],
      isLoading: true,
    };
  }

  getData(weekId) {
    fetchData(`profile/moderator/games/${weekId}`)
      .then((response) => {
        this.setState({
          games: response.map((item) => ({
            ...item,
            open: false,
            assign_referee: [
              ...item.assign_referee.filter(r1 => r1.status === 1),
              ...item.assign_referee.filter(r2 => r2.status === 3),
              ...item.assign_referee.filter(r3 => r3.status === 2)
            ]
          })),
          isLoading: false
        })
    });
  }

  _addError = (gameId, error_text)  => {
    this.setState(({ games }) => ({
      games: games.map(item => item.id === gameId ? ({...item, error: error_text}) : ({...item}))
    }));
  };

  _deleteError = (gameId) => {
    this.setState(({ games }) => ({
      games: games.map(item => item.id === gameId ? ({...item, error: null}) : ({...item}))
    }));
  };

  componentDidMount() {
    this.getData(this.state.weekId);
  }

  afterPickedReferee = () => {
    this.getData(this.state.weekId);
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.weekId !== nextProps.currentWeek) {
      this.setState({
        weekId: nextProps.currentWeek,
        isLoading: true
      });
      this.getData(nextProps.currentWeek);
    }

    return true;
  }

  saveAndAssign = () => {
    updateObject(API.profile.moderator.games, {}, this.state.weekId)
      .then(() => {
        this.getData(this.state.weekId);
      })
  };

  render() {
    const {
      isLoading,
      games,
      weekId
    } = this.state;

    if(isLoading) {
      return <Loader/>
    }

    return (
      <div>
        <div className="game-list-for-referee">
          {
            games.map((item, index) => {
              return (
                <div className="game-item-for-referee" key={index}>
                  <div className="item-row">
                    <div className="team-title">
                      <img src={item.team1_img} alt="" />
                      <h2>{item.team1}</h2>
                    </div>
                    <div className="team-title">
                      <h2 style={{ textAlign: 'right', marginRight: '10px' }}>{item.team2}</h2>
                      <img src={item.team2_img} alt="" style={{ marginRight: 0 }}/>
                    </div>
                  </div>
                  <span className="important-info">{item.age}</span>
                  <span className="place">{item.field ? <a href={item.field_url} target="_blank" rel="noopener noreferrer">{item.field}</a> : '-'}</span>
                  <span>{item.datetime}</span>
                  <div className="list-referees-in-game-block">
                    <div className={item.game_referee_staff.length !== 0 ? "referee-was-picked" : "notVisible"}>
                      <h5>Approved referee(s):</h5>
                      {
                        item.game_referee_staff.map((referee, index) => {
                          if(referee.approved === false) {
                            return (
                              <span key={index}/>
                            )
                          } else {
                            return (
                              <div key={index} className="game-referee-row referee-item">
                                <span>{referee.referee}</span>
                                <p>{referee.qualification === 1 ? 'HR' : 'AR'}</p>
                              </div>
                            )
                          }
                        })
                      }
                    </div>
                    <AssignRefereePicker
                      data={item.assign_referee}
                      type_game={item.type}
                      game={item}
                      afterPickedReferee={this.afterPickedReferee}
                      _addError={this._addError}
                      _deleteError={this._deleteError}
                    />
                    <SelectRefereeFromListOfReferees info={item} afterPickedReferee={this.afterPickedReferee} />
                  </div>
                </div>
              )
            })
          }
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '600px', margin: '0 auto' }}>
          <a href={`${API_URL}/profile/moderator/excel/${weekId}`} className="formsButton approve-you-pick">Download Excel</a>
          <div className="formsButton approve-you-pick" onClick={this.saveAndAssign}>Save and assign</div>
        </div>
      </div>
    )
  }
}

class SelectRefereeFromListOfReferees extends Component {
  render () {
    const { info, afterPickedReferee } = this.props;
    const list = info.recommended_referee.map((item, index) => {
      return <RefereeItemFromList data={item} key={`referee_item_${index}`} afterPickedReferee={afterPickedReferee} game_id={info.id}/>
    });
    return (
      <div className='head-referee-list-of-refs'>
        <span className='btn-select-from-list-of-referee'>Select referee <i className="fas fa-chevron-down"/></span>

          <div className='list-of-referee'>
            {list}
        </div>
      </div>
    )
  }
}

class RefereeItemFromList extends Component {

  _eventClick = () => {
    const { afterPickedReferee, data, game_id } = this.props;

    createObject('profile/moderator/assign/referee', {game: game_id, referee: data.id})
      .then(() => {
        afterPickedReferee();
      })
      .catch((e) => catchErrors(e))
  };

  render () {
    const { data } = this.props;
    return (
      <div className='item-for-list-of-referee' onClick={this._eventClick}>{data.referee}</div>
    )
  }
}
export class ModeratorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentWeek: parseInt(props.match.params.id, 10),
      weeks: [],
      games: []
    }
  }

  componentDidMount() {
      fetchData(API.weeks)
        .then((weeks) => {
          this.setState({
            weeks: weeks,
            isLoading: false
          })
        })
  }


  setCurrentWeek(id) {
    this.setState({currentWeek: id});
  }

  render() {
    const {
      isLoading,
      weeks,
      currentWeek
    } = this.state;

    if(isLoading) {
      return <Loader/>
    }

    return (
      <div className="container">
        <div className="nextNavigation">
          <div className="teams_filter">
            {weeks.map((week, index) => (
              <div
                key={index}
                onClick={() => this.setCurrentWeek(week.id)}
                className={`nextTab ${currentWeek === week.id ? 'clicked' : ''}`}
              >{week.week}</div>
            ))}
          </div>
        </div>
          <GamesGroup
            currentWeek={currentWeek}
          />
      </div>
    );
  }
}
