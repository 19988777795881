import React, {Component} from 'react';

import Loader from '../../components/Loader';
import {API, fetchDetail} from '../../services';

import DefaultAvatar from '../../images/default-avatar.png';
import {catchErrors} from "../../services/catchErrors";


class GamesList extends Component {
  render () {
    let list = this.props.data.map((item, index) => {
      return (
        <div className="game" key={index}>
          <div className="team">
            <div className="logo">
              <div className="logo_border">
                <img src={item.team1_logo} alt="" />
              </div>
            </div>
            <h3>{item.team1}</h3>
          </div>
          <div className="info_game">
            <h4><span className="label_h4">Tournament: </span>{item.tournament}</h4>
            <h4 className="gameAge"><span>{item.age}</span></h4>
            <h4 className="dateTime">{item.datetime}</h4>
            <h4 className="score">
              {item.team1_goals === null ? '' : item.team1_goals}
              <span>-</span>
              {item.team2_goals === null ? '' : item.team2_goals}
            </h4>
            <h4 className="place">
              <span>Location: </span>
              {item.field ? <a href={item.field_url} target="_blank" rel="noopener noreferrer">{item.field}</a> : '-'}
            </h4>
          </div>
          <div className="team">
            <h3>{item.team2}</h3>
            <div className="logo">
              <div className="logo_border">
                <img src={item.team2_logo} alt="" />
              </div>
            </div>
          </div>
        </div>
      )
    });

    return (
      <div className="games">
        <h4 className="header4 ref">Referee Games</h4>
        {list}
      </div>
    )
  }
}

class RefereeDetail extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      detail: {}
    }
  }

  componentDidMount() {
    fetchDetail(API.details.referee, this.props.match.params.id)
      .then((detail) => {
        this.setState({
          isLoading: false,
          detail: detail
        })
      })
      .catch((e) => {
        catchErrors(e);
        this.setState({isLoading: false})
      })
  }

  render() {
    if (this.state.isLoading) {
      return <Loader/>
    }

    return (
      <div className="base">
        <div className="emptyHeader" />
        <div className="title">
          <h2 className="header2 container">INFORMATION</h2>
        </div>

        <div className="container">
          <div className="info minContainer">
            <img src={this.state.detail.img || DefaultAvatar} alt={this.state.detail.first_name} />
            <div className="personal_info refereeInfo">
              <h4>Name: <span>{this.state.detail.first_name || ''}</span></h4>
              <h4>Surname: <span>{this.state.detail.last_name || ''}</span></h4>
              <h4>Email: <span>{this.state.detail.email || ''}</span></h4>
              <h4>Phone: <span>{this.state.detail.phone || ''}</span></h4>
            </div>
          </div>
          <div className="gamesList">
            <GamesList
              data={this.state.detail.games}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RefereeDetail;
