import React, {Component, Fragment} from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { API, fetchData, fetchDetail } from "../../services";

import Loader from '../../components/Loader';
import { GameFormPDF } from "../Teams/GameFormPDF";

import spinner_img from '../../images/spinner_img.png';
import download_file from '../../images/import_img.png';

export class Schedule extends Component {
  state = {
    data: [],
    teams: [],
    team: {},
    isLoading: true
  };

  componentDidMount() {
    fetchData(API.schedule)
      .then((response) => {
        fetchData(API.profile.academyOwner.teams)
          .then((teams) => {
            this.setState({
              data: response,
              teams: teams,
              isLoading: false
            });
          })
          .catch((error) => console.warn('error', error))
      })
      .catch((error) => console.warn('error', error));

    fetchDetail(`teams/73`)
      .then((team) => {
        this.setState({
          team: team
        })
      })
      .catch((error) => console.warn('error', error));
  }

  render() {
    const { data, teams, isLoading, team } = this.state;

    if(isLoading) {
      return <Loader/>
    }
    return <div className='container'>
      <MonthBlock data={data} ownerTeams={teams}/>
      <MobileScheduleVersion data={data} ownerTeams={teams}/>
    </div>
  }
}

//MOBILE VERSION
const MobileScheduleVersion = (props) => {
  const { data, ownerTeams } = props;

  const list = data.map((item, index) => <div key={`month_index_${index}`}>
    <span className='month_sch_header'>{item.month_year}</span>
    <MobileWeeksList data={item.weeks} ownerTeams={ownerTeams}/>
  </div>);
  return <div className='sch_container_425'>
    {list}
  </div>
};

const ListGameMobile = (props) => {
  const { data, ownerTeams } = props;

  const list = data.map((item, index) => <ScheduleMobileGameItem {...item} key={`mobile_game_schedule_index${index}`} ownerTeams={ownerTeams}/>);
  return <div>
    {list}
  </div>
};

const ScheduleMobileGameItem = (props) => {
  const {
    datetime,
    field,
    field_url,
    team1,
    team2,
    age,
    ownerTeams
  } = props;
  return <div className='gameItem_schedule_mobile'>
    <div className='sch_mob_column mob_sch_datetime'>
      <span style={{fontWeight: 'bold'}}>{datetime.date}</span>
      <span className={`day_color_${datetime.day}`}>{datetime.day}</span>
    </div>
    <div className='sch_mob_column mob_sch_gameInfo'>
      <div className='mob_sch_time_gameItem'>
        <div className='custom_sch_circle' style={{marginRight: 5}}>
          <div className='custom_sch_circle' style={{width: 8, height: 8, backgroundColor: '#fff'}}/>
        </div>
        <span style={{ fontWeight: 'bold' }}>{datetime.time} {datetime.am_pm}</span>
      </div>
      <div className='sch_mob_row_team space-btw_sch_mob'>
        <span className='defStateText_mob_sch'>{team1.short_title}</span>
        <DownloadPDFDayForm gameDate={datetime} team={team1} ownerTeams={ownerTeams} type='mobile'/>
      </div>
      <div className='sch_mob_row_team space-btw_sch_mob'>
        <span className='defStateText_mob_sch'>vs {team2.short_title}</span>
        <DownloadPDFDayForm gameDate={datetime} team={team2} ownerTeams={ownerTeams} type='mobile'/>
      </div>
      <a href={field_url} target='_blank'><i className="fas fa-map-marker-alt"/> {field}</a>
    </div>
    <div className='sch_mob_column mob_sch_division'>
      <span>{age}</span>
    </div>
  </div>
};

const MobileWeeksList = (props) => {
  const { data, ownerTeams } = props;

  const list = data.map((item, index) => <MobileWeekItem key={`week_index_${index}`} data={item} ownerTeams={ownerTeams}/>);

  return <div>
    {list}
  </div>
};

const MobileWeekItem = (props) => {
  const {data, ownerTeams} = props;

  return <Fragment>
    <span className='week_sch_header'>W{data.week}</span>
    <ListGameMobile data={data.games} ownerTeams={ownerTeams}/>
  </Fragment>
};

//MOBILE VERSION end
const MonthBlock = (props) => {
  const { data, ownerTeams } = props;

  const list = data.map((item, index) => <div key={`month_index_${index}`}>
    <span className='month_sch_header'>{item.month_year}</span>
    <WeeksList data={item.weeks} ownerTeams={ownerTeams}/>
  </div>);

  return <div className='month_sch_block'>
    {list}
  </div>
};

const WeeksList = (props) => {
  const { data, ownerTeams } = props;

  const list = data.map((item, index) => <WeekItem key={`week_index_${index}`} data={item} ownerTeams={ownerTeams}/>);

  return <div>
    {list}
  </div>
};

const WeekItem = (props) => {
  const {data, ownerTeams} = props;

  return <Fragment>
    <span className='week_sch_header'>W{data.week}</span>
    <ListGame data={data.games} ownerTeams={ownerTeams}/>
  </Fragment>
};

const ListGame = (props) => {
  const { data, ownerTeams } = props;

  const list = data.map((item, index) => <ScheduleGameItem {...item} key={`game_schedule_index${index}`} ownerTeams={ownerTeams}/>);
  return <div className='gameList_schedule'>
    {list}
  </div>
};

const ScheduleGameItem = (props) => {
  const {
    datetime,
    field,
    field_url,
    team1,
    team2,
    age,
    ownerTeams
  } = props;

  return <div className='gameItem_schedule'>
    <div className='column1'>
      <h4>{age}</h4>
    </div>
    <div className='column2'>
      <span><span className={`day_color_${datetime.day}`}>{datetime.day}</span> {datetime.date}</span>
    </div>
    <div className='column3'>
      <span>{datetime.time}{datetime.am_pm}</span>
    </div>
    <div className='column4'>
      <h4>{team1.short_title}</h4>
    </div>
    <div className='column5'>
      <h4>{team2.short_title}</h4>
    </div>
    <div className='column6'>
      {/*<a href={field_url} target='_blank'><i className="fas fa-map-marker-alt"/> {field}</a>*/}
      <DownloadPDFDayForm gameDate={datetime} team={team1} ownerTeams={ownerTeams}/>
      <DownloadPDFDayForm gameDate={datetime} team={team2} ownerTeams={ownerTeams}/>
    </div>
    <div className='column7'>
      <a href={field_url} target='_blank'><i className="fas fa-map-marker-alt"/> {field}</a>
    </div>
  </div>
};

class DownloadPDFDayForm extends Component {
  state = {
    generatePDF: false,
    teamData: {},
    isLoading: true
  };

  switchOnPDFGeneration = () => {
    const { team } = this.props;

    fetchDetail(`teams/${team.id}`)
      .then((response) => {
        this.setState({
          isLoading: false,
          teamData: response,
          generatePDF: true
        })
      })
      .catch((error) => console.warn('error', error));
  };

  render() {
    const {
      generatePDF,
      isLoading,
      teamData
    } = this.state;

    const { team, ownerTeams, gameDate, type } = this.props;

    if(ownerTeams.filter(item => item.id === team.id).length === 0) {
      // return type === 'mobile' ? null : <div style={{ width: 20, height: 20 }}/>
      return null
    }

    return <button
      className='downloadPDFBtn_sch'
      style={{ width: 20, height: 20, fontSize: 14 }}
      onClick={this.switchOnPDFGeneration}
    >
      {generatePDF ? (
        <PDFDownloadLink
          document={
            <GameFormPDF teamData={teamData} gameDate={gameDate}  fileName={`${team.title} gameday.pdf`} />
          }
        >
          {({ loading }) =>
            loading && !isLoading
              ? <img src={spinner_img} alt=''/>

              : <img src={download_file} alt=''/>
          }
        </PDFDownloadLink>
      ) : (
        <span><i className="fas fa-file-pdf"/></span>
      )}
    </button>
  }
};
