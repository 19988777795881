import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import NotFoundImg from '../images/404.png';

class NotFound extends Component {
  render() {
    return (
      <div className="base">
        <div className="emptyHeader" />
        <div className="background">
          <div className="container">
            <div className="over">
              <div className="minContainer notFound">
                <img src={NotFoundImg} className="notFoundImg" alt="Not Found" />
                <h2 className="header2">Sorry, Page Not Found</h2>
                <Link to="/" className="formsButton">Go to home page</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NotFound;
