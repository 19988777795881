import React, { Component } from 'react';

import Loader from '../../components/Loader';

import Modal from '../../components/ModalSuccessful';

import DefaultAvatar from '../../images/default-avatar.png';

import {API, fetchData, updateObject} from '../../services';

class SelectQualification extends Component {
  render() {
    const {
      name,
      data,
      showErrorBorder,
      value,
      onChange
    } = this.props;


    const list = data.map((item, index) => {
      return <option value={item.id} key={index}>{item.value}</option>;
    });

    return (
      <select
        className={showErrorBorder ? 'formsSelect error' : 'formsSelect'}
        value={value}
        onChange={onChange}
        name={name}
      >
        <option value="">Qualification</option>
        {list}
      </select>
    )
  }
}

export class ProfileForReferee extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      // profile: '',
      imgSrc: '',
      qualification: '',
      refereeCategories: [
        {id: 1, value: 'Category 1'},
        {id: 2, value: 'Category 2'}
      ]
    }
  }

  componentDidMount() {
    if(this.props.loggedIn) {
      fetchData(API.profile)
        .then((response) => {
          this.setState({
            isLoading: false,
            first_name: response.first_name,
            last_name: response.last_name,
            email: response.email,
            img: response.img || DefaultAvatar,
            phone: response.phone,
            qualification: response.qualification || ''
          })
        })
    }
  }

  closeModal = () => {
    this.setState(({ modalVisible }) => ({
      modalVisible: !modalVisible
    }));
  };

  showErrors = (error) => {
    let errors = [];
    for(let item in error) {
      errors.push(item);
      this.setState({errors: errors, error_text: error[item]});
    }

  };

  showErrorBorder = (item) => {
    for(let key in this.state.errors) {
      if(this.state.errors[key] === item) {
        return true;
      }
    }
  };

  postDataRequest = () => {
    let dataRequest = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      img: this.state.imgSrc.imagePreviewUrl,
      phone: this.state.phone,
      qualification: this.state.qualification
    };
    updateObject(API.profile, dataRequest)
      .then(() => this.setState({modalVisible: true, errors: [], error_text: ''}))
      .catch((error) => this.showErrors(error));
  };

  onChangeValue = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    })
  };

  render() {
    const {
      isLoading,
      modalVisible,
      imgSrc,
      img,
      email,
      first_name,
      last_name,
      phone,
      refereeCategories,
      qualification,
      error_text
    } = this.state;

    if(isLoading) {
      return <Loader/>
    }
    return (
      <div>
        <div className="container">
          <Modal modalVisible={modalVisible} onClick={this.closeModal}/>
          <div className="info_profile">
            <div className="row">
              <div className="avatarUser">
                <img src={imgSrc ? imgSrc.imagePreviewUrl : img} alt="" />
                <div className="pickImg">
                  <button className="formsButton">Upload photo</button>
                  <input type="file" id="upload-photo" onChange={(image) =>{
                    let reader = new FileReader(),
                      imgSrc = image.target.files[0];
                    reader.onloadend = () => {
                      this.setState({imgSrc: {
                        file: imgSrc,
                        imagePreviewUrl: reader.result
                      }});
                    };
                    reader.readAsDataURL(imgSrc);
                  }}
                  />
                </div>
              </div>
              <div className="column">
                <input type="text" className="formsInput"
                  placeholder="Email"
                  value={email}
                  readOnly={true}
                />
                <input
                  type="text"
                  className='formsInput'
                  placeholder="First Name"
                  value={first_name}
                  name='first_name'
                  onChange={this.onChangeValue}
                />
                <input
                  type="text"
                  className='formsInput'
                  placeholder="Last Name"
                  value={last_name}
                  name='last_name'
                  onChange={this.onChangeValue}
                />
                <input
                  type="text"
                  className={`formsInput ${this.showErrorBorder('phone') ? 'error' : ''}`}
                  placeholder="Phone"
                  value={phone}
                  name='phone'
                  onChange={this.onChangeValue}
                />
                <SelectQualification
                    data={refereeCategories}
                    value={qualification}
                    name='qualification'
                    showErrorBorder={this.showErrorBorder('qualification')}
                    onChange={this.onChangeValue}
                />
              </div>
            </div>
            <span className="error_field">{error_text}</span>
            <button className="formsButton" onClick={this.postDataRequest}>Save</button>
          </div>
        </div>
      </div>
    );
  }
}