import { API_URL } from '../config';
import { API } from '../services';

export function authenticate(data) {
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/${API.auth.login}/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(response => {
      return response.json().then(json => {
        response.ok ? resolve(json) : reject(json);
      });
    });
  });
}

export function userData(user) {
  localStorage.setItem('user', JSON.stringify(user));
}

export function setAgreementPlayer(boolean) {
  localStorage.setItem('agrement', boolean);
}

export function getAgreementPlayer(boolean) {
  localStorage.getItem('agrement', boolean);
  if (localStorage.getItem('agrement')) {
    return localStorage.getItem('agrement');
  } else {
    return 'false';
  }
}

export function getUser() {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch (e) {
    return {};
  }
}

export function login(token) {
  localStorage.setItem('token', token);
}

export function isLoggedIn() {
  return !!localStorage.getItem('token');
}

export function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('requests');
  localStorage.removeItem('agrement');
}
