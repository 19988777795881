import React, {Component, Fragment} from "react";
import DefaultAvatar from "../images/default-avatar.png";
import {SelectBirth, SelectLocation} from "../containers/Profile/ProfilePlayer";

import {POSITIONS} from "../constants";
import { days, months } from "../constants";


export class PlayerInfoForm extends Component {
  render() {
    const isScreen425 = window.screen.availWidth <= 425;
    const {
      currentTeamTab,
      isItEditingForm,

      playerId,
      imgSrc,
      email,
      first_name,
      last_name,
      gender,
      doc_photoSrc,
      parent,
      address,
      locations,
      city,
      provinces,
      province,
      postal_code,
      docSrc,
      description,

      pickedDay,
      pickedMonth,
      pickedYear,

      teams,
      teamplayers,

      showErrorBorder,
      showPlayerForm,
      _ToggleFormPlayer,
      handleImagePicker,
      handleDocPicker,
      toggleModalWindow,
      downloadInsurance,
      _onChangeValueAndConvert,
      onChangeValue,
      onChangeValueFromArray,
      _savePlayerInfo,
      _deletePlayerFromRoster,
      addTemplateTeamPlayers,
      deleteTeamFormTeamPlayers,
      onChangeValueFromArrayForTeamsPicker,
      onChangeTeamsOption
    } = this.props;

    if(!showPlayerForm) {
      return null
    }

    return (
      <div className="info_profile" style={{position: 'relative'}}>
        <div
          className="closeSignIn"
          style={{
            position: 'absolute',
            right: isScreen425 ? '' : '-40px',
          }}
        >
          <button
            className="close"
            onClick={() => {
              _ToggleFormPlayer();
              // this.clearForm();
            }}
          >
            <i className="fas fa-times"/>
          </button>
        </div>
        <div className='column-roster-form-edit-files'>
          <div className="avatarUser">
            <img
              src={
                imgSrc && imgSrc.img
                  ? imgSrc.img
                  : imgSrc &&
                  imgSrc.imagePreviewUrl
                  ? imgSrc.imagePreviewUrl
                  : DefaultAvatar
              }
              alt=""
            />
            <div className="pickImg">
              <button className="formsButton">Upload photo</button>
              <input
                type="file"
                id="upload-photo"
                onChange={(img) => handleImagePicker(img)}
              />
            </div>
          </div>
          <div className="avatarUser">
            <div
              className="flex row"
              // style={{
              //   width:
              //     doc_photoSrc && doc_photoSrc.doc ? 200 : 'auto',
              // }}
            >
              <div className="pickImg">
                <button className="formsButton">ID DOCUMENT</button>
                <input
                  type="file"
                  id="upload-doc"
                  onChange={(img) => handleDocPicker(img, 'doc_photoSrc')}
                />
                {/*{doc_photoSrc && doc_photoSrc.file && (*/}
                  {/*<p>{doc_photoSrc.file.name}</p>*/}
                {/*)}*/}
              </div>
              <button
                style={{ marginLeft: 10}}
                className={
                  doc_photoSrc && doc_photoSrc.doc
                    ? 'formsButton formsButtonWithIcon showIdImg'
                    : 'notVisible'
                }
                onClick={() =>
                  doc_photoSrc &&
                  doc_photoSrc.doc &&
                  toggleModalWindow('pop-up', true)
                }
              >
                <i className="fas fa-eye"/>
              </button>
            </div>
          </div>
          <div className="avatarUser">
            <div
              className="flex row"
              // style={{width: docSrc && docSrc.doc ? 200 : 'auto'}}
            >
              <div className="pickImg">
                <button className="formsButton">Insurance</button>
                <input
                  type="file"
                  id="upload-doc"
                  onChange={(img) => handleDocPicker(img, 'docSrc')}
                />
                {/*{docSrc && docSrc.file && <p>{docSrc.file.name}</p>}*/}
              </div>
              <button
                style={{ marginLeft: 10}}
                className={
                  docSrc && docSrc.doc
                    ? 'formsButton formsButtonWithIcon showIdImg'
                    : 'notVisible'
                }
                onClick={() =>
                  docSrc && docSrc.doc && downloadInsurance()
                }
              >
                <i className="fas fa-download"/>
              </button>
            </div>
          </div>
        </div>
        <div className="column">
          <input
            type="text"
            className={`formsInput fieldForRow ${showErrorBorder('email') ? 'error' : ''}`}
            placeholder="Email"
            value={email}
            name='email'
            onChange={onChangeValue}
          />
          <div className='date-birth-row'>
            <input
              type="text"
              className={`formsInput fieldForRow ${showErrorBorder('first_name') ? 'error' : ''}`}
              placeholder="First Name"
              value={first_name}
              name='first_name'
              onChange={onChangeValue}
            />
            <input
              type="text"
              className={`formsInput fieldForRow ${showErrorBorder('last_name') ? 'error' : ''}`}
              placeholder="Last Name"
              value={last_name}
              name='last_name'
              onChange={onChangeValue}
            />
            <select
              className={`formsSelect fieldForRow ${showErrorBorder('gender') ? 'error' : ''}`}
              // style={{'width': '15%'}}
              value={gender !== null && gender}
              name='gender'
              onChange={_onChangeValueAndConvert}
            >
              <option value="">Gender</option>
              <option value="1">Boy</option>
              <option value="2">Girl</option>
            </select>
          </div>

          <div className="date-birth-row">
            <SelectBirth
              type="days"
              data={days}
              value={pickedDay}
              showErrorBorder={showErrorBorder('date_birth')}
              name='pickedDay'
              onChange={onChangeValue}
            />
            <SelectBirth
              type="months"
              data={months}
              value={pickedMonth}
              showErrorBorder={showErrorBorder('date_birth')}
              name='pickedMonth'
              onChange={onChangeValue}
            />
            <SelectBirth
              type="years"
              value={pickedYear}
              showErrorBorder={showErrorBorder('date_birth')}
              name='pickedYear'
              onChange={onChangeValue}
            />
          </div>
          <input
            type="text"
            className={`formsInput ${showErrorBorder('parent') ? 'error' : ''}`}
            placeholder="Parent/Guardian Full Name"
            value={parent}
            name='parent'
            onChange={onChangeValue}
          />
          <div className="row">
            <div className="date-birth-row">
              <input
                type="text"
                className={`formsInput fieldForRow ${showErrorBorder('address') ? 'error' : ''}`}
                placeholder="Address"
                style={{'width': '30%'}}
                value={address}
                name='address'
                onChange={onChangeValue}
              />
              <SelectLocation
                data={locations}
                value={city}
                type="city"
                extraStyleClass='fieldForRow'
                style={{'width': '30$'}}
                showErrorBorder={showErrorBorder('city')}
                onChange={(e) => onChangeValueFromArray(e, 'city')}
              />
              <SelectLocation
                data={provinces}
                value={
                  province
                }
                style={{'width': '12%'}}
                type="province"
                extraStyleClass='fieldForRow'
                showErrorBorder={showErrorBorder('province')}
                onChange={(e) => onChangeValueFromArray(e, 'province', 'provinces')}
              />
              <input
                type="text"
                className={`formsInput postal-code-style fieldForRow ${showErrorBorder('postal_code') ? 'error' : ''}`}
                style={{'width': '13%'}}
                placeholder="Postal code"
                value={postal_code}
                name='postal_code'
                onChange={onChangeValue}
              />
            </div>
          </div>
          {/*<textarea*/}
            {/*className={`formsInput fieldForRow ${showErrorBorder('description') ? 'error' : ''}`}*/}
            {/*cols="30"*/}
            {/*rows="2"*/}
            {/*placeholder="About Player"*/}
            {/*value={description}*/}
            {/*name='description'*/}
            {/*onChange={onChangeValue}*/}
          {/*/>*/}
          {/*{user_type === 1 && (*/}
            {/*<>*/}
              {/*<h4 className="label-modal-editor">Academy</h4>*/}
              {/*<SelectForPlayer*/}
                {/*data={academies}*/}
                {/*position={academy && academy.id}*/}
                {/*onChange={this.handleAcademySelect}*/}
              {/*/>*/}
            {/*</>*/}
          {/*)}*/}
          <PlayerTeamsList
            data={teamplayers}
            teams={teams}
          // playerId,
            currentTeamTab={currentTeamTab}
            main_position={''}
            position={''}
            number={''}

            onChangeValue={onChangeValue}
            onChangeValueFromArrayForTeamsPicker={onChangeValueFromArrayForTeamsPicker}
            addTemplateTeamPlayers={addTemplateTeamPlayers}
            deleteTeamFormTeamPlayers={deleteTeamFormTeamPlayers}
            onChangeTeamsOption={onChangeTeamsOption}
          />

          <div className="row">
            <button
              style={{
                ...stylesPlayerInfoForm.coachActionBtn,
                flex: 1,
                backgroundColor: '#04a303',
                color: '#fff',
              }}
              onClick={(e)=> _savePlayerInfo(e)}
            >
              Save
            </button>
            {isItEditingForm ?
              <button
                style={{
                  ...stylesPlayerInfoForm.coachActionBtn,
                  flex: 1,
                  backgroundColor: '#ff0000',
                  color: '#fff',
                }}
                onClick={() => {
                  _deletePlayerFromRoster(playerId);
                }
                }
              >
                Delete Player
              </button>
              : null
            }
          </div>
        </div>
      </div>
    )
  }
}

const SelectForPlayer = props => {
  let data = props.data;

  const options =
    data &&
    data.map(({ id, title }, index) => (
      <option value={id} key={index}>
        {title}
      </option>
    ));
  return (
    <select
      className={props.showErrorBorder ? 'formsSelect error' : 'formsSelect'}
      value={props.position}
      placeholder="Position"
      name={props.name}
      onChange={props.onChange}
    >
      <option>----</option>
      {options}
    </select>
  );
};

const PlayerTeamsList = (props) => {
  const {
    data,
    teams,
    // playerId,
    currentTeamTab,
    onChangeValueFromArrayForTeamsPicker,
    onChangeTeamsOption,
    addTemplateTeamPlayers,
    deleteTeamFormTeamPlayers
  } = props;

  if(data.length === 1 && data[0].idForEdit === -1) {
    data[0].team = currentTeamTab;
  }

  const list = data.map((item, index) => <div className='date-birth-row' key={`player_team_add_to_${index}`}>
      <div className='fieldForRow style-for-select-inside' style={{'width': '25%'}}>
        <h4 className="label-modal-editor">Team</h4>
        <SelectForPlayer
          data={teams}
          position={item.team}
          onChange={(e) => onChangeValueFromArrayForTeamsPicker(e, item.idForEdit)}
        />
      </div>
      <div className='fieldForRow style-for-select-inside' style={{'width': '23%'}}>
        <h4 className="label-modal-editor">Main Position</h4>
        <SelectForPlayer
          data={POSITIONS}
          position={item.main_position}
          name='main_position'
          onChange={(e) => onChangeTeamsOption(e, item.idForEdit)}
        />
      </div>
      <div className='fieldForRow style-for-select-inside' style={{'width': '23%'}}>
        <h4 className="label-modal-editor">Second Position</h4>
        <SelectForPlayer
          data={POSITIONS}
          position={item.position}
          name='position'
          onChange={(e) => onChangeTeamsOption(e, item.idForEdit)}
        />
      </div>
      <div className='fieldForRow style-for-select-inside' style={{'width': '15%'}}>
        <h4 className="label-modal-editor">Number</h4>
        <input
          type="text"
          className="formsInput"
          placeholder="Number"
          value={item.number ? item.number : ''}
          name='number'
          onChange={(e) => onChangeTeamsOption(e, item.idForEdit)}
        />
      </div>
      <div
        className='spec-btn-for-manager-team'
        style={data.length > 1 ? {} : {'opacity': 0.3, 'cursor': 'auto'}}
        onClick={() => data.length > 1 ? deleteTeamFormTeamPlayers(item.idForEdit) : () =>{}}
      >
        <i className="fas fa-trash-alt"/>
      </div>
    </div>);

    return <div className='teams-player-block-manager'>
      {list}
      <div
        onClick={() => addTemplateTeamPlayers()}
        style={{
          ...stylesPlayerInfoForm.coachActionBtn,
          flex: 1,
          // backgroundColor: '#ff',
          color: '#000',
          width: '30%',
          margin: '0 auto',
          fontSize: 12,
          textAlign: 'center',
          textTransform: 'uppercase'
        }}
      >Add team for player</div>
    </div>
  // }

  // const list = data.map((item, index) => )
};

const stylesPlayerInfoForm = {
  coachActionBtn: {
    // width: '100%',
    borderRadius: '3px',
    backgroundColor: '#fec722',
    padding: 5,
    cursor: 'pointer',
    transition: 'all 0.5s',
    outline: 'none',
    boxShadow: 'none',
    border: 'none',
    marginBottom: 5
  },
};
