import React, {Component} from 'react';
import Youtube from 'react-youtube';

class VideoItem extends Component {
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  render() {
    const opts = {
      height: '100%',
      width: '100%',
    };
    let videoId = this.props.url.substring(32);
    videoId = videoId.substring(0,11);

    return (
      <Youtube
        videoId={videoId}
        opts={opts}
        onReady={this._onReady}
      />
    )
  }
}

export default VideoItem;
