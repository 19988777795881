import React, { Component } from 'react';

import {
  Route
} from 'react-router-dom';

import AcademyList from '../../containers/Academy/AcademyList';
import AcademyDetail from '../../containers/Academy/AcademyDetail';

class Academy extends Component {
  render() {
    return (
        <div>
          <Route exact path="/academies" component={AcademyList}/>
          <Route path="/academies/:id" component={AcademyDetail}/>
        </div>
    );
  }
}

export default Academy;
