import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import '../style/style.css';

class SidebarTeams extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 0,
      teams: props.teams
    }
  }

  render() {
    let data = [], table =[];
    if(this.state.teams !== null && this.state.teams.length > 0) {
      data = this.state.teams[this.state.active];
      table = data.teams.table;
    }
    return (
      <div className="section recent_Result">
        <div className="title_section">
          <h3>Team Ranking {data.age}</h3>
          <div className="recent_Navigation">
            <span onClick={() => {
              if (this.state.active > 0) {
                this.setState({active: this.state.active - 1})
              }
            }}><i className="fas fa-chevron-left" /></span>

            <span onClick={() => {
              // if(isLoggedIn()) {
                if (this.state.active < this.state.teams.length -1) {
                  this.setState({active: this.state.active + 1})
                }
              // }
            }}><i className="fas fa-chevron-right" /></span>
          </div>
        </div>
        <div className="list">
          {table.map((item, index) => (
            <Link key={index} to={`/teams/${item.id}`} className="teamItem">
              <div className="teamLogoAndName">
                <span>{index + 1}</span>
                <div className="logo">
                  <div className="logo_border">
                    <img src={item.logo} alt={item.title} />
                  </div>
                </div>
                <span>{item.title}</span>
              </div>
              <span>{item.points}</span>
            </Link>
          ))}
        </div>
      </div>
    )
  }
}

export default SidebarTeams;
