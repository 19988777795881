import React, {Component, Fragment} from 'react';

import Loader from '../../../components/Loader';
import Modal from '../../../components/ModalSuccessful';
import {TeamInfoForm} from "../../../components/TeamInfoForm";

import {
  API,
  fetchData,
  fetchDetail,
  createObject,
  getAges, updateObject, deleteObject
} from '../../../services';


const TeamsList = props => {

  const ages = props.ages;
  const list = props.data.map((item, index) => {
    const age = ages.filter(a => a.id === item.age)[0];
    return (
      <div
        className="ac_teamItem ref-teams-list-item"
        key={index}
      >
        <div className="row">
          <h4>{item.title}</h4>
          <h4>{age.title || ''}</h4>
          <h4>{item.gender === 1 ? 'Boys' : 'Girls'}</h4>
          <h4 className='name-surname-for-staff'>
            {item.head_coach ?
              <Fragment>
              <span>
                {item.head_coach.first_name}
              </span>
                <span>
                {item.head_coach.last_name}
              </span>
              </Fragment> :
              ''}
          </h4>
          <h4 className='name-surname-for-staff'>
            {item.assistant_coach ?
              <Fragment>
              <span>
                {item.assistant_coach.first_name}
              </span>
                <span>
                {item.assistant_coach.last_name}
              </span>
              </Fragment> :
              ''}
          </h4>
          <h4 className='name-surname-for-staff'>
            {item.manager ?
              <Fragment>
              <span>
                {item.manager.first_name}
              </span>
                <span>
                {item.manager.last_name}
              </span>
              </Fragment> :
              ''}
          </h4>
          <div
            className='ref-teams-list-item-btn-edit'
            onClick={() => props.getTeamInfoForEdit(item.id)}
          >Edit</div>
        </div>
      </div>
    )
  });
  return (
    <div className="container ref-teams-list-container">
      {props.data && props.data.length ? <Fragment>
        <div className="ac_teamItem ref-teams-list-item header-of-table">
          <div className="row">
            <h4><span>Team</span></h4>
            <h4><span>Age</span></h4>
            <h4><span>Gender</span></h4>
            <h4><span>Head Coach</span></h4>
            <h4><span>Assistant Coach</span>
            </h4>
            <h4><span>Manager</span></h4>
            <div className='ref-teams-list-item-btn-edit' style={{ 'opacity': 0, 'cursor': 'auto' }}>Edit</div>
          </div>
        </div>
        {list}
      </Fragment> :
      <h3>You haven't teems yet</h3>}
    </div>
  )
};


export default class ProfileTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      teams: [],
      teamIdForEdit: null,
      imgSrc: '',
      title: '',
      short_title: '',
      clothesType: 'home',
      home_color: '#ffffff',
      guest_color: '#ffffff',
      age: '',
      description: '',
      modalVisible: false,
      formEdit: false,
      idTeamForEdit: '',
      ages: [],

      academyLogo: '',

      user: props.user,

      playerAcademiesList: [],
      playerTeamsList: [],

      coaches: [],
      head_coach: {},
      assistant_coach: {},
      manager: {},

      showTeamInfoForm: false
    }
  }

  onChangeValue = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    })
  };

  _onChangeValueAndConvert = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: parseInt(value, 10)
    });
  };

  onChangeValueFromArray = (e, name, typeDB='') => {
    const { coaches } = this.state;
    let db = coaches;

    // if(typeDB === 'provinces') {
    //   db = provinces;
    // }
    //
    // if(typeDB === 'teams') {
    //   db = teams;
    // }

    let v = parseInt(e.target.value, 10);
    this.setState({
      [name]: v
        ? db.filter(i => i.id === v)[0]
        : null,
    });
  };

  _toggleTeamForm = (priority='', value = '') => {
    if(priority) {
      this.setState({
        showTeamInfoForm: value
      })
    } else {
      this.setState(({ showTeamInfoForm }) => ({
        showTeamInfoForm: !showTeamInfoForm
      }))
    }
  };

  componentDidMount() {
    if (this.props.user.user_type === 1 && this.props.user.academy_exists) {
      fetchData(API.profile.academyOwner.teams)
        .then((teams) =>
          fetchData(`${API.coaches.list}`)
            .then(coaches =>
              fetchData(API.profile.academyOwner.teams.defaultColor)
                .then((colors) => getAges()
                  .then(ages => {
                      this.setState({
                        ages,
                        teams,
                        defaultHomeColor: colors.home_color,
                        defaultGuestColor: colors.guest_color,
                        home_color: colors.home_color,
                        guest_color: colors.guest_color,
                        academyLogo: colors.logo,
                        gender: '',
                        isLoading: false,
                        coaches
                      })
                  })
                  .catch(() => {
                    this.setState({
                      teams,
                      defaultHomeColor: '#ffffff',
                      defaultGuestColor: '#ffffff',
                      home_color: '#ffffff',
                      guest_color: '#ffffff',
                      gender: '',
                      isLoading: false
                    })
                  })
                )
            )
        )
    } else if (this.state.user.user_type === 5) {
      fetchData(API.profile.academyOwner.teams)
        .then((teams) =>
          fetchData(API.profile.academyOwner.teams.defaultColor)
            .then((colors) => getAges()
              .then(ages => {
                this.setState({
                  ages,
                  teams,
                  defaultHomeColor: colors.home_color,
                  defaultGuestColor: colors.guest_color,
                  home_color: colors.home_color,
                  guest_color: colors.guest_color,
                  academyLogo: colors.logo,
                  gender: '',
                  isLoading: false,
                })
              })
              .catch(() => {
                this.setState({
                  teams,
                  defaultHomeColor: '#ffffff',
                  defaultGuestColor: '#ffffff',
                  home_color: '#ffffff',
                  guest_color: '#ffffff',
                  gender: '',
                  isLoading: false
                })
              })
            )
        )
    } else {
      this.setState({isLoading: false})
    }
  }

  showErrors = error => {
    let errors = [];
    for (let item in error) {
      errors.push(item);
      this.setState({errors: errors, error_text: error[item]});
    }
  };

  showErrorBorder = item => {
    for (let key in this.state.errors) {
      if (this.state.errors[key] === item) {
        return true;
      }
    }
  };

  closeModal = () => this.setState({modalVisible: !this.state.modalVisible});

  handleClothesColor = color => {
    if (this.state.clothesType === 'home') {
      this.setState({home_color: color.hex});
    } else {
      this.setState({guest_color: color.hex});
    }
  };

  pickTShirt = string => this.setState({clothesType: string});

  clearForm = (teams) => {
    this.setState({
      teams,
      showTeamInfoForm: false,
      modalVisible: true,
      errors: [],
      error_text: '',
      title: '',
      short_title: '',
      imgSrc: '',
      description: '',
      age: '',
      home_color: this.state.defaultHomeColor,
      guest_color: this.state.defaultGuestColor,
      clothesType: 'home',
      gender: '',
      head_coach: '',
      assistant_coach: '',
      manager: '',
      showForm: false,
      formEdit: false
    })
  };

  deleteTeam = () => {
    if (window.confirm('Are you sure delete team?')) {
      deleteObject(API.profile.academyOwner.teams, this.state.teamIdForEdit)
        .then(res => fetchData(API.profile.academyOwner.teams)
          .then((teams) => this.clearForm(teams))
      )
        .catch((error) => console.warn("error", error))
    }
  };

  cleanTeamInfoForm = () => {
    this.setState({
      errors: [],
      error_text: '',
      title: '',
      short_title: '',
      imgSrc: '',
      description: '',
      age: '',
      home_color: this.state.defaultHomeColor,
      guest_color: this.state.defaultGuestColor,
      clothesType: 'home',
      gender: '',
      head_coach: '',
      assistant_coach: '',
      showTeamInfoForm: false,
      formEdit: false
    })
  };

  getTeamInfoForEdit = (id) => {
    fetchDetail(`${API.profile.academyOwner.teams.data}/${id}`)
      .then(response => {
        this.setState({
          showTeamInfoForm: true,
          formEdit: true,
          teamIdForEdit: response.id,
          age: response.age.id,
          assistant_coach: response.assistant_coach || '',
          manager: response.manager || '',
          head_coach: response.head_coach || '',
          guest_color: response.guest_color,
          home_color: response.home_color,
          gender: response.gender,
          description: response.description,
          short_title: response.short_title,
          title: response.title,
        })
      })
      .catch((error) => console.warn('error', error))
  };

  postDataRequest = () => {
    let requestData = {
      title: this.state.title,
      short_title: this.state.short_title,
      description: this.state.description,
      age: this.state.age,
      home_color: this.state.home_color,
      guest_color: this.state.guest_color,
      gender: this.state.gender,
      head_coach: this.state.head_coach ? this.state.head_coach.id : null,
      assistant_coach: this.state.assistant_coach ? this.state.assistant_coach.id : null,
      manager: this.state.manager ? this.state.manager.id : null
    };

    if (this.props.user_type !== 5) {
      if(this.state.formEdit) {
        updateObject(`${API.profile.academyOwner.teams.data}/${this.state.teamIdForEdit}`, requestData)
          .then(() =>
            fetchData(API.profile.academyOwner.teams)
              .then((teams) => this.clearForm(teams)))
          .catch((error) => this.showErrors(error));
      } else {
        createObject(API.profile.academyOwner.teams, requestData)
          .then(() =>
            fetchData(API.profile.academyOwner.teams)
              .then((teams) => this.clearForm(teams)))
          .catch((error) => this.showErrors(error));
      }
    }
  };

  render() {
    const {
      formEdit,

      coaches,
      user,
      academyLogo,
      ages,
      showTeamInfoForm,

      clothesType,
      home_color,
      guest_color,
      title,
      short_title,
      gender,
      description,
      error_text,
      head_coach,
      assistant_coach,
      manager,
      age
    } = this.state;

    const {history} = this.props;

    switch (true) {
      case this.state.isLoading:
        return <Loader/>;

      //  рендер якщо не маєш академію, а також для тренера, що може не мати академії
      case (!user.academy_exists && (user.user_type !== 5 || user.academy_exists)):
        return (
          <div className="container">
            <h2 className="header2 notCreated">Academy not created</h2>
          </div>);

      case (user.user_type === 5):
        return (
          <div>
            <div className="container">
              <Modal modalVisible={this.state.modalVisible} onClick={() => this.closeModal()}/>

              <TeamInfoForm
                user_type={user.user_type}
                formEdit={formEdit}
                showTeamInfoForm={showTeamInfoForm}
                clothesType={clothesType}
                home_color={home_color}
                guest_color={guest_color}
                title={title}
                short_title={short_title}
                gender={gender}
                coaches={coaches}
                head_coach={head_coach}
                assistant_coach={assistant_coach}
                manager={manager}
                description={description}
                error_text={error_text}
                ages={ages}
                age={age}

                deleteTeam={this.deleteTeam}
                _toggleTeamForm={this._toggleTeamForm}
                pickTShirt={this.pickTShirt}
                handleClothesColor={this.handleClothesColor}
                showErrorBorder={this.showErrorBorder}
                onChangeValue={this.onChangeValue}
                _onChangeValueAndConvert={this._onChangeValueAndConvert}
                onChangeValueFromArray={this.onChangeValueFromArray}
                postDataRequest={this.postDataRequest}
                cleanTeamInfoForm={this.cleanTeamInfoForm}
              />

              <TeamsList
                ages={ages}
                academyLogo={academyLogo}
                data={this.state.teams}
                history={history}
                getTeamInfoForEdit={this.getTeamInfoForEdit}
              />
            </div>
          </div>
        );

      case (user.user_type === 1):
        return (
          <div>
            <div className="container">
              <Modal modalVisible={this.state.modalVisible} onClick={() => this.closeModal()}/>
              {!showTeamInfoForm ?
                <div className='organizer-header-manager-wrapper ref-teams-list'>
                  <h4>Manager: </h4>
                  <div className='organizer-header-manager-block'>
                    <div className='header-manager-item' onClick={() => this._toggleTeamForm(true, true)}>
                      <i className="fas fa-plus"/>
                      <span>Create Team</span>
                    </div>
                  </div>
                </div>
                : null}

              <TeamInfoForm
                formEdit={formEdit}
                showTeamInfoForm={showTeamInfoForm}
                clothesType={clothesType}
                home_color={home_color}
                guest_color={guest_color}
                title={title}
                short_title={short_title}
                gender={gender}
                coaches={coaches}
                head_coach={head_coach}
                assistant_coach={assistant_coach}
                manager={manager}
                description={description}
                error_text={error_text}
                ages={ages}
                age={age}

                deleteTeam={this.deleteTeam}
                _toggleTeamForm={this._toggleTeamForm}
                pickTShirt={this.pickTShirt}
                handleClothesColor={this.handleClothesColor}
                showErrorBorder={this.showErrorBorder}
                onChangeValue={this.onChangeValue}
                _onChangeValueAndConvert={this._onChangeValueAndConvert}
                onChangeValueFromArray={this.onChangeValueFromArray}
                postDataRequest={this.postDataRequest}
                cleanTeamInfoForm={this.cleanTeamInfoForm}
              />
              <TeamsList
                ages={ages}
                academyLogo={academyLogo}
                data={this.state.teams}
                history={history}
                getTeamInfoForEdit={this.getTeamInfoForEdit}
              />
            </div>
          </div>
        );
      default :
        return <Loader/>;
    }
  }
}
