import React, { Component } from 'react';

import Loader from '../../components/Loader';

import Modal from '../../components/ModalSuccessful';

import DefaultAvatar from '../../images/default-avatar.png';


import {API, fetchData, updateObject} from '../../services';
import {days, months} from "../../constants";


export const SelectLocation = (props) => {
  const {
    value,
    onChange,
    type,
    showErrorBorder,
    name,
    data,
    style,
    extraStyleClass
  } = props;

  const list = data.map((item, index) => <option value={item.id} key={index}>{item.title}</option>);

  return (
    <select
      className={`formsSelect ${extraStyleClass? extraStyleClass : ''} ${showErrorBorder ? 'error' : ''}`}
      value={!!value && value}
      name={name}
      onChange={onChange}
      style={style}
    >
      <option value="">{type === 'city' ? 'City' : 'Province'}</option>
      {list}
    </select>
  )
};

export class SelectBirth extends Component {
  render () {
    const { name, value } = this.props;

    let list, titleText = '';

    if(this.props.type === 'days') {
      titleText = 'Day';
      list = this.props.data.map((item, index) => <option value={item.value} key={index}>{item.text}</option>);
    }
    if(this.props.type === 'months') {
      titleText = 'Month';
      list = this.props.data.map((item, index) => <option value={item.value} key={index}>{item.text}</option>);
    }
    if(this.props.type === 'years') {
      titleText = 'Year';
      let date = new Date();
      let currentYear = date.getFullYear();
      let year = date.getFullYear()-50;
      let years = [];

      while (currentYear !== year) {
        years.push({text: currentYear, value: currentYear});
        currentYear--;
      }

      list = years.map((item, index) => <option value={item.value} key={index} >{item.text}</option>);
    }
    return (
      <select
        className={`formsSelect ${this.props.showErrorBorder ? 'error' : ''}`}
        value={!!value && value}
        onChange={this.props.onChange}
        name={name}
      >
        <option value="">{titleText}</option>
        {list}
      </select>
    )
  }
}

export class ProfilePlayer extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      profile: '',
      imgSrc: '',
      typeModal: '',
      city: '',
      imgID: {
        imagePreviewUrl: ''
      },
      pickedDay: '',
      pickedMonth: '',
      pickedYear: '',
      date_birth: null
    }
  }

  componentDidMount() {
    this.setState({
      days: days,
      months: months
    });
    if(this.props.loggedIn) {
      fetchData(API.profile)
      .then((response) =>{
        fetchData(API.locations)
          .then((locations) => {
            fetchData(API.provinces)
              .then((provinces) => {
                let date_birth = [null, null, null];
                if(response.date_birth !== null) {
                  date_birth = response.date_birth.split('/');
                }

                response.pickedDay = date_birth[0];
                response.pickedMonth = date_birth[1];
                response.pickedYear = date_birth[2];

                this.setState({
                  first_name: response.first_name,
                  last_name: response.last_name,
                  email: response.email,
                  img: response.img || DefaultAvatar,
                  address: response.address,
                  doc_photo: response.doc_photo,
                  parent: response.parent,
                  description: response.description,
                  main_position: response.main_position || '',
                  position: response.position || '',
                  number: response.number || '',
                  team: response.team,
                  gender: response.gender || '',
                  isLoading: false,
                  locations: locations,
                  provinces: provinces,
                  city: response.city || '',
                  province: response.province || '',
                  postal_code: response.postal_code,
                  pickedDay: response.pickedDay || '',
                  pickedMonth: response.pickedMonth || '',
                  pickedYear: response.pickedYear || ''
                })
              });
          });
      })
      .catch((error) =>{
        console.log('error', error);
        this.setState({isLoading: false});
      })
    }
  }

  closeModal = () => {
    this.setState(({ modalVisible }) => ({
      modalVisible: !modalVisible,
      typeModal: ''
    }));
  };

  toggleModalWithTypeForcibly = (type, value) => {
    this.setState({
      typeModal: type,
      modalVisible: value
    })
  };

  showErrors = (error) => {
    let errors = [];
    for(let item in error) {
      errors.push(item);
      this.setState({errors: errors, error_text: error[item]});
    }
  };

  showErrorBorder = (item) =>  {
    for(let key in this.state.errors) {
      if(this.state.errors[key] === item) {
        return true;
      }
    }
  };

  postDataRequest = () => {
    const {
      pickedDay,
      pickedMonth,
      pickedYear,
      imgID,
      imgSrc,
      first_name,
      last_name,
      email,
      parent,
      description,
      gender,
      address,
      postal_code,
      city,
      province
    } = this.state;
    let doc_photo = null, day = '', month = '', year = '';
    if(imgID.imagePreviewUrl !== '') {
      doc_photo = imgID.imagePreviewUrl;
    }

    if(pickedDay !== '') {
      day = pickedDay;
    }
    if(pickedMonth !== '') {
      month = pickedMonth;
    }
    if(pickedYear !== '') {
      year = pickedYear;
    }
    let pickedDate = day+'/'+month+'/'+year;

    let dataRequest = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      img: imgSrc.imagePreviewUrl,
      doc_photo: doc_photo,
      date_birth: pickedDate,
      parent: parent,
      description: description,
      gender: gender,
      address: address,
      postal_code: postal_code,
      city: city,
      province: province
    };
    updateObject(API.profile, dataRequest)
      .then(() => this.setState({modalVisible: true, errors: [], error_text: ''}))
      .catch((error) => this.showErrors(error));
  }

  onChangeValue = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  };

  _onChangeValueAndConvert = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: parseInt(value, 10)
    });
  };

  render() {
    const {
      isLoading,
      modalVisible,
      typeModal,
      imgID,
      doc_photo,
      imgSrc,
      img,
      email,
      first_name,
      last_name,
      gender,
      parent,
      locations,
      city,
      address,
      provinces,
      province,
      postal_code,
      description,
      team,
      main_position,
      position,
      number,
      error_text,
    } = this.state;

    if(isLoading) {
      return <Loader/>
    }

    return (
      <div>
        <div className="container">
          <Modal
            modalVisible={modalVisible}
            type={typeModal}
            picture={
              imgID.imagePreviewUrl === '' ?
                doc_photo
              : imgID.imagePreviewUrl
            }
            onClick={this.closeModal}
          />

          <div className="info_profile">
            <div className="row">
              <div className="avatarUser">
                <img src={imgSrc ? imgSrc.imagePreviewUrl : img} alt="" />
                <div className="pickImg">
                  <button className="formsButton">Upload photo</button>
                  <input type="file" id="upload-photo" onChange={(image) =>{
                    let reader = new FileReader(),
                    imgSrc = image.target.files[0];
                    reader.onloadend = () => {
                      this.setState({imgSrc: {
                        file: imgSrc,
                        imagePreviewUrl: reader.result
                      }});
                    };
                    reader.readAsDataURL(imgSrc);
                    this.setState({img:imgSrc});
                  }}
                  />
                </div>
                <div className="iDPhoto">
                  <div className="pickImg idImg">
                    <span className="profileHelpText">Passport or BC Care Card are acceptable</span>
                      <button className="formsButton">Add ID Document</button>
                      <input type="file" style={{ top: '29px' }} onChange={(image) =>{
                        let reader = new FileReader(),
                          imgSrc = image.target.files[0];
                          reader.onloadend = () => {
                            this.setState({imgID: {
                              file: imgSrc,
                              imagePreviewUrl: reader.result
                            }});
                          };
                        reader.readAsDataURL(imgSrc);
                        this.setState({doc_photo:imgSrc});
                      }}
                      />
                  </div>
                  <button
                    className={doc_photo !== null ? 'formsButton showIdImg' : 'notVisible'}
                    onClick={() => {this.toggleModalWithTypeForcibly('pop-up', true)}}
                  >
                    <i className="fas fa-eye" />
                  </button>
                </div>
              </div>
              <div className="column">
                <input
                  type="text"
                  className="formsInput"
                  placeholder="Email"
                  value={email}
                  readOnly
                />
                <input
                  type="text"
                  className='formsInput'
                  placeholder="First Name"
                  value={first_name}
                  name='first_name'
                  onChange={this.onChangeValue}
                />
                <input
                  type="text"
                  className='formsInput'
                  placeholder="Last Name"
                  value={last_name}
                  name='last_name'
                  onChange={this.onChangeValue}
                />
                <select
                  className={`formsSelect ${this.showErrorBorder('gender') ? 'error' : ''}`}
                  value={gender}
                  name='gender'
                  onChange={this._onChangeValueAndConvert}
                >
                  <option value="">Select Gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </select>

                <div className="date-birth-row">
                  <SelectBirth
                    type="days"
                    data={days}
                    value={this.state.pickedDay}
                    showErrorBorder={this.showErrorBorder('date_birth')}
                    name='pickedDay'
                    onChange={this.onChangeValue}
                  />
                  <SelectBirth
                    type="months"
                    data={months}
                    value={this.state.pickedMonth}
                    showErrorBorder={this.showErrorBorder('date_birth')}
                    name='pickedMonth'
                    onChange={this.onChangeValue}
                  />
                  <SelectBirth
                    type="years"
                    value={this.state.pickedYear}
                    showErrorBorder={this.showErrorBorder('date_birth')}
                    name='pickedYear'
                    onChange={this.onChangeValue}
                  />
                </div>

                <input
                  type="text"
                  className={`formsInput ${this.showErrorBorder('parent') ? 'error' : ''}`}
                  placeholder="Parent/Guardian Full Name"
                  value={parent}
                  name='parent'
                  onChange={this.onChangeValue}
                />
              </div>
            </div>
            <input
              type="text"
              className={`formsInput ${this.showErrorBorder('address') ? 'error' : ''}`}
              placeholder="Address"
              value={address}
              name='address'
              onChange={this.onChangeValue}
            />
            <div className="date-birth-row">
              <SelectLocation
                data={locations}
                value={city}
                type="city"
                showErrorBorder={this.showErrorBorder('city')}
                name='city'
                onChange={this._onChangeValueAndConvert}
              />
              <SelectLocation
                data={provinces}
                value={province}
                type="province"
                showErrorBorder={this.showErrorBorder('province')}
                name='province'
                onChange={this._onChangeValueAndConvert}
              />
              <input
                type="text"
                className={`formsInput postal-code-style ${this.showErrorBorder('postal_code') ? 'error' : ''}`}
                placeholder="Postal code"
                value={postal_code}
                name='postal_code'
                onChange={this.onChangeValue}
              />
            </div>
            <textarea
              className={`formsInput ${this.showErrorBorder('description') ? 'error' : ''}`}
              cols="30" rows="2"
              placeholder="About Player"
              value={description}
              name='description'
              onChange={this.onChangeValue}
            />
            <span className="error_field">{error_text}</span>
            {/*<div className={team !== null ? 'myTeam' : 'notVisible'}>*/}
              {/*<h4>Team: {team}</h4>*/}
              {/*<h4>Position: {main_position} / {position}</h4>*/}
              {/*<h4>Number: {number}</h4>*/}
            {/*</div>*/}
            <button className="formsButton" onClick={this.postDataRequest}>Save</button>
          </div>
        </div>
      </div>
    );
  }
}
