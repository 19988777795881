let apiUrl;

if (process.env.NODE_ENV === 'production') {
    apiUrl = 'https://api.academysuperleague.com/api/v1';

    if(window.location.port === '3000') {
        apiUrl = 'http://157.230.172.174:5000/api/v1';
    }

} else {
  apiUrl = 'http://127.0.0.1:8000/api/v1';
  // apiUrl = 'http://192.168.1.131:8000/api/v1';
  // apiUrl = 'https://api.bcasl.com/api/v1'
}

export const API_URL = apiUrl;
