import React, { Component } from 'react';

import {
  Link
} from 'react-router-dom';

import Loader from '../../components/Loader';

import "react-image-gallery/styles/css/image-gallery.css";

import ImageGallery from 'react-image-gallery';

import {API, fetchData} from '../../services';

class SliderItem extends Component {
  render () {
    let images=[];
    for(let item in this.props.data) {
      images.push({
        original: this.props.data[item].photo,
        thumbnail: this.props.data[item].photo,
        description: this.props.data[item].description
      });
    }
    return (
      <ImageGallery
        items={images}
        showPlayButton={false}
        showFullscreenButton={false}
        slideInterval={2000}
      />
    )
  }
}

class GalleryList extends Component {
  render () {
    let index =0,
      list = this.props.data.map((item) => {
        return (
          <div className="album" key={index++}>
            <h2>{item.name}</h2>
            <SliderItem data={item.photos}/>
          </div>
        )
      });
    return(
      <div className="gallery">
        {list}
      </div>
    )
  }
}

class GalleryPhotos extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      list: ''
    }
  }

  componentDidMount() {
    fetchData(API.galleryPhotos)
      .then((response) => this.setState({list: response, isLoading: false}));
  }

  render() {
    if(this.state.isLoading) {
      return <Loader/>
    }
    return (
      <div className="base">
        <div className="emptyHeader" />

        <div className="title">
          <h2 className="header2 container">GALLERY PHOTOS</h2>
        </div>

        <div className="container">
          <div className="goToLink">
            <Link to="/gallery/videos" className="formsButton">go to Video</Link>
          </div>
          <GalleryList data={this.state.list}/>
        </div>
      </div>
    );
  }
}

export default GalleryPhotos;
