import React from 'react';
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';

import logo from '../../images/ASL_logo_transparent_background.png';

Font.register(
  'https://cdn.jsdelivr.net/npm/@typopro/web-open-sans@3.7.5/TypoPRO-OpenSans-Regular.ttf',
  { family: 'Regular' }
);

Font.register(
  'https://cdn.jsdelivr.net/npm/@typopro/web-open-sans@3.7.5/TypoPRO-OpenSansCondensed-Bold.ttf',
  { family: 'Bold' }
);

const WIDTHROSTERFIELDS = {
  1: '6%',
  2: '22%',
  3: '22%',
  4: '19%',
  5: '17%',
  6: '14%'
};

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 14,
    // lineHeight: '1.6',
    color: '#000',
    // fontFamily: 'Regular',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: 'Bold'
  },
  fontWeightText: {
    fontFamily: 'Bold'
  },
  logo: {
    width: 70,
    height: 'auto',
    marginBottom: 20
  },
  labelContainer: {
    width: '19%',
    // borderRight: 1
  },
  label: {
    // paddingTop: 2,
    // paddingBottom: 2,
    paddingLeft: 5,
    color: '#000'
    // borderRight: 1,
    // width: '22%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 1,
    borderColor: '#000'
  },
  fieldDef: {
    // borderWidth: 1,
    // borderRight: 1,
    // borderLeft: 1,
    display: 'flex',
    justifyContent: 'center',
    borderColor: '#000'
  },
  tableContainer: {
    border: 1,
    borderColor: '#000',
    borderBottomWidth: 0
  }
});

export const GameFormPDF = (props) => {
  const {
    gameDate,
    teamData
  } = props;

  let rosterSize = teamData.teamplayer_profiles.length;
  let emptyImportRoster = [{}, {}, {}];
  let emptyGuestRoster = [{}, {}];

  return <Document>
    <Page size="A4" style={styles.page}>
      <Image src={logo} style={styles.logo}/>
      <View style={styles.tableContainer}>
        <View style={styles.row}>
          <Text style={styles.title}>ASL GAMEDAY TEAM ROSTER</Text>
        </View>
        <View style={styles.row}>
          <View style={[styles.labelContainer, { borderRight: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>ACADEMY:</Text>
          </View>
          <View style={[styles.fieldDef, { width: '31%',  }]}>
            <Text style={styles.label}>{teamData.academy}</Text>
          </View>

          <View style={[styles.labelContainer, { borderLeft: 1, borderRight: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>ACADEMY TD:</Text>
          </View>
          <View style={[styles.fieldDef, { width: '31%',  }]}/>
        </View>
        <View style={styles.row}>
          <View style={[styles.labelContainer, { borderRight: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>TEAM COACH:</Text>
          </View>
          <View style={[styles.fieldDef, { width: '31%', }]}>
            <Text style={styles.label}>{teamData.head_coach ? `${teamData.head_coach.first_name} ${teamData.head_coach.last_name}` : ''}</Text>
          </View>

          <View style={[styles.labelContainer, { borderLeft: 1, borderRight: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>TEAM NAME:</Text>
          </View>
          <View style={[styles.fieldDef, { width: '31%', }]}>
            <Text style={styles.label}>{teamData.short_title}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.labelContainer, { borderRight: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>DIVISION:</Text>
          </View>
          <View style={[styles.fieldDef, { width: '31%', }]}>
            <Text style={styles.label}>{teamData.age}</Text>
          </View>

          <View style={[styles.labelContainer, { borderLeft: 1, borderRight: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>DATE:</Text>
          </View>
          <View style={[styles.fieldDef, { width: '31%'}]}>
            <Text style={styles.label}>{gameDate.date} {gameDate.time} {gameDate.am_pm}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={[{ width: `${WIDTHROSTERFIELDS[1]}`, }]}>
            <Text style={styles.label}/>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[2]}`, borderLeft: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>FIRST NAME</Text>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[3]}`, borderLeft: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>LAST NAME</Text>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[4]}`, borderRight: 1, borderLeft: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>NUMBER</Text>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[5]}`, borderRight: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>D.O.B</Text>
          </View>
          <View style={[{ width: `${WIDTHROSTERFIELDS[6]}` }]}>
            {/*<Text>#</Text>*/}
          </View>
        </View>
        {
          teamData.teamplayer_profiles.map((item, index) => <RosterItem key={`index_row_${index}`} item={item} index={index}/>)
        }

        <View style={[styles.row, { height: 20 }]}>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[1]}`, }]}>
            <Text style={[styles.label, { textAlign: 'right', paddingRight: 2 }]}>{rosterSize + 1}</Text>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[2]}`, borderLeft: 1 }]}>
            <Text style={styles.label}/>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[3]}`, borderLeft: 1 }]}>
            <Text style={styles.label}/>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[4]}`, borderRight: 1, borderLeft: 1 }]}>
            <Text style={styles.label}/>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[5]}`, borderRight: 1 }]}>
            <Text style={styles.label}/>
          </View>
          <View style={[{ width: `${WIDTHROSTERFIELDS[6]}` }]}>
            {/*<Text>#</Text>*/}
          </View>
        </View>
        <View style={[styles.row, { height: 20 }]}>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[1]}`, }]}>
            <Text style={[styles.label, { textAlign: 'right', paddingRight: 2 }]}>{rosterSize + 2}</Text>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[2]}`, borderLeft: 1 }]}>
            <Text style={styles.label}/>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[3]}`, borderLeft: 1 }]}>
            <Text style={styles.label}/>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[4]}`, borderRight: 1, borderLeft: 1 }]}>
            <Text style={styles.label}/>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[5]}`, borderRight: 1 }]}>
            <Text style={styles.label}/>
          </View>
          <View style={[{ width: `${WIDTHROSTERFIELDS[6]}` }]}>
            {/*<Text>#</Text>*/}
          </View>
        </View>

        <View style={styles.row}>
          <Text style={[styles.label, styles.fontWeightText, { textAlign: 'center' }]}>IMPORT PLAYERS FROM ACADEMY</Text>
        </View>
        <View style={styles.row}>
          <View style={[{ width: `${WIDTHROSTERFIELDS[1]}`, }]}>
            <Text style={styles.label}/>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[2]}`, borderLeft: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>FIRST NAME</Text>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[3]}`, borderLeft: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>LAST NAME</Text>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[4]}`, borderRight: 1, borderLeft: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>DIVISION</Text>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[5]}`, borderRight: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>NUMBER</Text>
          </View>
          <View style={[{ width: `${WIDTHROSTERFIELDS[6]}` }]}>
            <Text style={[styles.label, styles.fontWeightText]}>D.O.B</Text>
          </View>
        </View>

        {
          emptyImportRoster.map((item, index) => <RosterItem key={`index_row_${index}`} item={item} index={index} template={true}/>)
        }

        <View style={styles.row}>
          <Text style={[styles.label, styles.fontWeightText, { textAlign: 'center' }]}>GUEST PLAYER</Text>
        </View>
        <View style={styles.row}>
          <View style={[{ width: `${WIDTHROSTERFIELDS[1]}`, }]}>
            <Text style={styles.label}/>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[2]}`, borderLeft: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>FIRST NAME</Text>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[3]}`, borderLeft: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>LAST NAME</Text>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[4]}`, borderRight: 1, borderLeft: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>DIVISION</Text>
          </View>
          <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[5]}`, borderRight: 1 }]}>
            <Text style={[styles.label, styles.fontWeightText]}>NUMBER</Text>
          </View>
          <View style={[{ width: `${WIDTHROSTERFIELDS[6]}` }]}>
            <Text style={[styles.label, styles.fontWeightText]}>D.O.B</Text>
          </View>
        </View>

        {
          emptyGuestRoster.map((item, index) => <RosterItem key={`index_row_${index}`} item={item} index={index} template={true}/>)
        }
      </View>
    </Page>
  </Document>
}

const RosterItem = (props) => {
  const { item, index, template } = props;

  if(template) {
    return <View style={[styles.row, { height: 20 }]}>
      <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[1]}`, }]}>
        <Text style={[styles.label, { textAlign: 'right', paddingRight: 2 }]}>{index + 1}</Text>
      </View>
      <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[2]}`, borderLeft: 1 }]}>
        <Text style={styles.label}/>
      </View>
      <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[3]}`, borderLeft: 1 }]}>
        <Text style={styles.label}/>
      </View>
      <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[4]}`, borderRight: 1, borderLeft: 1 }]}>
        <Text style={styles.label}/>
      </View>
      <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[5]}`, borderRight: 1 }]}>
        <Text style={styles.label}/>
      </View>
      <View style={[{ width: `${WIDTHROSTERFIELDS[6]}` }]}>
        {/*<Text>#</Text>*/}
      </View>
    </View>
  }

  return <View style={[styles.row, { height: 20 }]}>
    <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[1]}`, }]}>
      <Text style={[styles.label, { textAlign: 'right', paddingRight: 2 }]}>{index + 1}</Text>
    </View>
    <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[2]}`, borderLeft: 1 }]}>
      <Text style={styles.label}>{item.player.first_name}</Text>
    </View>
    <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[3]}`, borderLeft: 1 }]}>
      <Text style={styles.label}>{item.player.last_name}</Text>
    </View>
    <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[4]}`, borderRight: 1, borderLeft: 1 }]}>
      <Text style={styles.label}>{item.number}</Text>
    </View>
    <View style={[styles.fieldDef, { width: `${WIDTHROSTERFIELDS[5]}`, borderRight: 1 }]}>
      <Text style={styles.label}>{item.player.date_birth}</Text>
    </View>
    <View style={[{ width: `${WIDTHROSTERFIELDS[6]}` }]}>
      {/*<Text>#</Text>*/}
    </View>
  </View>
};
