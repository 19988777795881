import React, { Component } from 'react';
import { SwatchesPicker } from 'react-color';

import Loader from '../../../components/Loader';
import Modal from '../../../components/ModalSuccessful';

import DefaultAvatar from '../../../images/default-avatar.png';
import DefaultBackground from '../../../images/backgroundDefaultAcademy.jpg';

import {API, fetchData, updateObject, createObject, getUser, userData} from '../../../services';


export class ProfileAcademy extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      academy: '',
      imgSrc: '',
      backgroundSrc: '',
      title: '',
      link: '',
      address: '',
      logo: '',
      backgroundLogo: '',
      color: '#ffffff',
      clothesType: 'home',
      home_color: '#ffffff',
      guest_color: '#ffffff',
      description: '',
      method: '',
    }
  }

  componentDidMount() {
     if (this.props.user.academy_exists) {
        fetchData(API.profile.academyOwner.academy)
          .then((response) => {
            this.setState({academy: response, isLoading: false})
          })
          .then(() => {
            let defaultAvatar = DefaultAvatar,
              defaultBackground = DefaultBackground,
              defaultHomeColor = '#ffffff',
              defaultGuestColor = '#ffffff';
            if(this.state.academy.logo !== null) {
              defaultAvatar = this.state.academy.logo;
            }
            if(this.state.academy.background_image !== null) {
              defaultBackground = this.state.academy.background_image;
            }
            if(this.state.academy.home_color !== '') {
              defaultHomeColor = this.state.academy.home_color;
            }
            if(this.state.academy.guest_color !== '') {
              defaultGuestColor = this.state.academy.guest_color;
            }
            if(this.state.academy.hasOwnProperty('title')) {
              this.setState({
                title: this.state.academy.title,
                link: this.state.academy.link,
                address: this.state.academy.address,
                logo: defaultAvatar,
                backgroundLogo: defaultBackground,
                color: this.state.academy.color,
                description: this.state.academy.description,
                home_color: defaultHomeColor,
                guest_color: defaultGuestColor
              })
            } else {
              this.setState({logo: DefaultAvatar, method: 'post'})
            }
          })
          .catch(() => {
            this.setState({
              logo: DefaultAvatar,
              method: 'post',
              isLoading: false
            })
          })
      } else {
        this.setState({isLoading: false, logo: DefaultAvatar, method: 'post'})
      }
  }

  showErrors = (error) => {
    let errors = [];
    for(let item in error) {
      errors.push(item);
      this.setState({errors: errors, error_text: error[item]});
    }

  }

  showErrorBorder = (item) => {
    for(let key in this.state.errors) {
      if(this.state.errors[key] === item) {
        return true;
      }
    }
  }

  closeModal = () => {
    this.setState(({ modalVisible }) => ({
      modalVisible: !modalVisible
    }));
  };

  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };

  handleDressColor = (color) => {
    if(this.state.clothesType === 'home') {
      this.setState({ home_color: color.hex });
    } else {
      this.setState({ guest_color: color.hex });
    }

  };

  pickDress = (string) => {
    this.setState({clothesType: string});
  }

  postDataRequest = () => {
    let dataRequest = {};
    if(this.state.imgSrc !== '') {
      dataRequest = {
        title: this.state.title,
        link: this.state.link,
        description: this.state.description,
        address: this.state.address,
        color: this.state.color,
        logo: this.state.imgSrc.imagePreviewUrl,
        home_color: this.state.home_color,
        guest_color: this.state.guest_color
      };
    } else {
      dataRequest = {
        title: this.state.title,
        address: this.state.address,
        link: this.state.link,
        color: this.state.color,
        description: this.state.description,
        home_color: this.state.home_color,
        guest_color: this.state.guest_color
      };
    }
    if(this.state.backgroundSrc !== '') {
      dataRequest['background_image'] = this.state.backgroundSrc.imagePreviewUrl
    }

    if(this.state.method === 'post') {
      createObject(API.profile.academyOwner.academy, dataRequest)
        .then((response) => {
          this.setState({modalVisible: true, errors: [], error_text: '', method: ''});
          const user = getUser();
          user.academy_exists = true;
          userData(user);
        })
        .catch((error) => this.showErrors(error));
      } else {
        updateObject(API.profile.academyOwner.academy, dataRequest)
        .then((response) => this.setState({modalVisible: true, errors: [], error_text: ''}))
        .catch((error) => this.showErrors(error));
      // }
    }

  };

  onChangeValue = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    })
  };

  render() {
    const {
      isLoading,
      modalVisible,
      imgSrc,
      logo,
      clothesType,
      home_color,
      guest_color,
      title,
      link,
      backgroundSrc,
      backgroundLogo,
      address,
      description,
      color,
      error_text
    } = this.state;

    if(isLoading) {
      return <Loader/>
    }

    return (
      <div>
        <div className="container">
          <Modal modalVisible={modalVisible} onClick={ this.closeModal}/>

          <div className="info_profile">
            <div className="row">
              <div className="avatarUser">
                <img
                  src={imgSrc ? imgSrc.imagePreviewUrl : logo}
                  className={this.showErrorBorder('logo') ? 'error' : ''}
                  alt=""
                />
                <span>Logo must be round</span>
                <div className="pickImg">
                  <button className="formsButton">Pick image</button>
                  <input type="file" onChange={(img) =>{
                    let reader = new FileReader(),
                      imgSrc = img.target.files[0];
                    reader.onloadend = () => {
                      this.setState({imgSrc: {
                        file: imgSrc,
                        imagePreviewUrl: reader.result
                      }});
                    };
                    reader.readAsDataURL(imgSrc);
                  }}
                  />
                </div>
                <div className="colorsPicker">
                  <span className="helpText">Select Home/Guest before choosing the colour</span>
                  <div className="teamsColors">
                    <div className={clothesType === 'home' ? 'homeTshirt active' : 'homeTshirt'} style={{fill: home_color}} onClick={() => this.pickDress('home')}>
                      <span>Home</span>
                      <svg viewBox="0 0 512 512">
                        <path className="homeTshirt__border" d="M362.672,42.66l-53.328,0c0,29.414 -23.938,53.336 -53.344,53.336c-29.406,
                          0 -53.328,-23.922 -53.328,-53.336l-53.336,0l-149.336,85.336l42.672,117.336l64,-21.336l0,245.344l298.672,0l0,
                          -245.344l64,21.336l42.656,-117.336l-149.328,-85.336Zm93.703,175.867l-44.297,-14.766l-28.078,-9.366l0,
                          253.602l-256,0l0,-224l0,-29.602l-28.078,9.367l-44.297,14.766l-29.43,-80.93l128.805,-73.602l29.43,
                          0c9.18,30.844 37.75,53.336 71.57,53.336c33.828,0 62.391,-22.492 71.562,-53.336l29.438,
                          0l128.812,73.602l-29.437,80.929Z"
                        />
                        <path d="M456.375,218.527l-44.297,-14.766l-28.078,-9.366l0,253.602l-256,0l0,-224l0,-29.602l-28.078,
                          9.367l-44.297,14.766l-29.43,-80.93l128.805,-73.602l29.43,0c9.18,30.844 37.75,53.336 71.57,53.336c33.828,
                          0 62.391,-22.492 71.562,-53.336l29.438,0l128.812,73.602l-29.437,80.929Z"
                        />
                      </svg>
                    </div>
                    <div className={this.state.clothesType === 'guest' ? 'guestTshirt active' : 'guestTshirt'} style={{fill: guest_color}} onClick={() => this.pickDress('guest')}>
                      <span>Guest</span>
                      <svg viewBox="0 0 512 512">
                        <path className="homeTshirt__border" d="M362.672,42.66l-53.328,0c0,29.414 -23.938,53.336 -53.344,53.336c-29.406,
                          0 -53.328,-23.922 -53.328,-53.336l-53.336,0l-149.336,85.336l42.672,117.336l64,-21.336l0,245.344l298.672,0l0,
                          -245.344l64,21.336l42.656,-117.336l-149.328,-85.336Zm93.703,175.867l-44.297,-14.766l-28.078,-9.366l0,
                          253.602l-256,0l0,-224l0,-29.602l-28.078,9.367l-44.297,14.766l-29.43,-80.93l128.805,-73.602l29.43,
                          0c9.18,30.844 37.75,53.336 71.57,53.336c33.828,0 62.391,-22.492 71.562,-53.336l29.438,
                          0l128.812,73.602l-29.437,80.929Z"
                        />
                        <path d="M456.375,218.527l-44.297,-14.766l-28.078,-9.366l0,253.602l-256,0l0,-224l0,-29.602l-28.078,
                          9.367l-44.297,14.766l-29.43,-80.93l128.805,-73.602l29.43,0c9.18,30.844 37.75,53.336 71.57,53.336c33.828,
                          0 62.391,-22.492 71.562,-53.336l29.438,0l128.812,73.602l-29.437,80.929Z"
                        />
                      </svg>
                    </div>
                  </div>
                  <SwatchesPicker
                    color={ clothesType === 'home' ? home_color : guest_color }
                    width='158px'
                    height='150px'
                    onChangeComplete={ this.handleDressColor }
                  />
                </div>
              </div>
              <div className="column">
                <input
                  type="text"
                  className={`formsInput ${this.showErrorBorder('title') ? 'error' : ''}`}
                  placeholder="Academy Name"
                  value={title}
                  name='title'
                  onChange={this.onChangeValue}
                />
                <input
                  type="text"
                  className={`formsInput ${this.showErrorBorder('link') ? 'error' : ''}`}
                  placeholder="Website"
                  value={link}
                  name='link'
                  onChange={this.onChangeValue}
                />
                <input
                  type="text"
                  className={`formsInput ${this.showErrorBorder('address') ? 'error' : ''}`}
                  placeholder="City"
                  value={address}
                  name='address'
                  onChange={this.onChangeValue}
                />
                <textarea
                  className={`formsInput ${this.showErrorBorder('description') ? 'error' : ''}`}
                  cols="30" rows="2"
                  placeholder=" Please provide a brief description of the Academy"
                  value={description}
                  name='description'
                  onChange={this.onChangeValue}
                />
                <div className="row adaptationColumn">
                  <div className="avatarUser">
                    <img
                      src={backgroundSrc ? backgroundSrc.imagePreviewUrl : backgroundLogo}
                      className={this.showErrorBorder('logo') ? 'error' : ''}
                      alt=""
                    />
                    <div className="pickImg">
                      <button className="formsButton">Pick background image</button>
                      <input type="file" style={{ width: '225px' }} onChange={(img) =>{
                        let reader = new FileReader(),
                          imgSrc = img.target.files[0];
                        reader.onloadend = () => {
                          this.setState({backgroundSrc: {
                            file: imgSrc,
                            imagePreviewUrl: reader.result
                          }});
                        };
                        reader.readAsDataURL(imgSrc);
                      }}
                      />
                    </div>
                  </div>
                  <SwatchesPicker
                    color={color}
                    width="225px"
                    onChangeComplete={ this.handleChangeComplete }
                  />
                </div>
              </div>
            </div>
            <span className="error_field">{error_text}</span>
            <button className="formsButton paddingBottom" onClick={this.postDataRequest}>Save</button>
          </div>
        </div>
      </div>
    );
  }
}