import React, { Component } from 'react';

import Loader from '../components/Loader';
import Modal from '../components/ModalSuccessful';
import {API, fetchData, createObject} from '../services';

class ContactsItem extends Component {
  render() {
     const listItems = this.props.data.map((item, index) =>{
      return (<span key={index}>{item}</span>)
    });
    return (
      <div className="info_contact">
        <h4 className="header4">{this.props.title}</h4>
        {listItems}
      </div>
    )
  }
}

export class Contacts extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      contacts: '',
      name: '',
      email: '',
      phone: '',
      message: '',
      modalVisible: false
    }
  }

  componentDidMount() {
    fetchData(API.contacts)
      .then((response) => {
        this.setState({contacts: response, isLoading: false});
    });
  }

  closeModal = () => {
    this.setState({modalVisible: !this.state.modalVisible});
  }

  showErrors = (error) => {
    let errors = [];
    if(error.hasOwnProperty('non_field_errors')){
      this.setState({error_text: error.non_field_errors[0], errors: []});
    } else{
      for(let item in error) {
        errors.push(item);
        this.setState({errors: errors, error_text: error[item]});
      }
    }
  };

  showErrorBorder = (item) => {
    for(let key in this.state.errors) {
      if(this.state.errors[key] === item) {
        return true;
      }
    }
  };

  onChangeValue = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    })
  };

  postDataSupport = () => {
    createObject(API.contacts, {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message
    })
      .then(() => this.setState({modalVisible: true, name: '', email: '', phone: '', message: ''}))
      .catch((error) => this.showErrors(error));
  };

  render() {
    const {
      isLoading,
      modalVisible,
      contacts,
      name,
      email,
      phone,
      message,
      error_text
    } = this.state;
    if(isLoading) {
      return <Loader/>
    }
    return (
      <div className="base">
        <Modal modalVisible={modalVisible} onClick={this.closeModal}/>
        <div className="emptyHeader" />

        <div className="title">
          <h2 className="header2 container">CONTACT US</h2>
        </div>

        <div className="container">
          <div className="howToContactUs">
            <div className="item_contact">
              <div className="icon_place">
                <i className="fas fa-envelope"/>
              </div>
              <ContactsItem data={contacts.emails} title={'Email'}/>
            </div>
            <div className="item_contact">
               <div className="icon_place">
                <i className="fas fa-phone"/>
              </div>
              <ContactsItem data={contacts.phones} title={'Phone'} />
            </div>
            <div className="item_contact">
               <div className="icon_place">
                <i className="fas fa-map-marker"/>
              </div>
              <ContactsItem data={contacts.addresses} title={'Location'} />
            </div>
          </div>
          <div className="contact_form">
            <h3 className="header3">
              PLEASE FILL OUT THE FORM BELOW <span>and someone
              from ASL will contact you as soon as possible</span>

            </h3>
            <div className="contactUs">
              <div className="row">
                <input
                  type="text"
                  className={this.showErrorBorder('name') ? 'formsInput error' : 'formsInput'}
                  placeholder="Name"
                  value={name}
                  name='name'
                  onChange={this.onChangeValue}
                  // onChange={(text) => this.setState({name: text.target.value})}
                />
                <input
                  type="text"
                  className={this.showErrorBorder('email') ? 'formsInput error' : 'formsInput'}
                  placeholder="Email"
                  value={email}
                  name='email'
                  onChange={this.onChangeValue}
                  // onChange={(text) => this.setState({email: text.target.value})}
                />
              </div>
              <input
                type="text"
                className={this.showErrorBorder('phone') ? 'formsInput error' : 'formsInput'}
                placeholder="Phone"
                value={phone}
                name='phone'
                onChange={this.onChangeValue}
                // onChange={(text) => this.setState({phone: text.target.value})}
              />
              <textarea
                className={this.showErrorBorder('message') ? 'formsInput error' : 'formsInput'}
                cols="30" rows="2"
                placeholder="Your message"
                value={message}
                name='message'
                onChange={this.onChangeValue}
                // onChange={(text) => this.setState({message: text.target.value})}
              />
              <span className="error_field">{error_text}</span>
              <button className="formsButton" onClick={this.postDataSupport}>Submit Your Message</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}