import React, {Component} from 'react';

import Loader from '../../components/Loader';
import Modal from '../../components/ModalSuccessful';

import DefaultAvatar from '../../images/default-avatar.png';

import {API, fetchData, updateObject} from '../../services';
import {catchErrors} from "../../services/catchErrors";


export class ProfileForOther extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      imgSrc: '',
      first_name: '',
      last_name: '',
      phone: '',
      email: '',

      old_password: '',
      password: '',
      confirm_password: '',
      errors_password: [],
      error_text_password: ''
    }
  }

  componentDidMount() {
    fetchData(API.profile)
      .then((response) => {
        this.setState({
          isLoading: false,
          first_name: response.first_name,
          last_name: response.last_name,
          email: response.email,
          img: response.img || DefaultAvatar,
          phone: response.phone
        })
      })
      .catch(e => catchErrors(e))
  }

  closeModal = () => {
    this.setState(({modalVisible}) => ({
      modalVisible: !modalVisible
    }))
  };

  showErrors = (error, type='') => {
    if(type) {
      if(error.hasOwnProperty('non_field_errors')){
        if(error.non_field_errors[0] === 'wrong password') {
          this.setState({error_text_password: error.non_field_errors[0], errors_password: ["old_password"]});
        }
        if(error.non_field_errors[0] === 'different passwords') {
          this.setState({error_text_password: error.non_field_errors[0], errors_password: ["password", "confirm_password"]});
        }
      }
    } else {
      let errors = [];
      for (let item in error) {
        errors.push(item);
        this.setState({errors: errors, error_text: error[item]});
      }
    }
  };

  showErrorBorder = (item, type='') => {
    if(type) {
      for (let key in this.state.errors_password) {
        if (this.state.errors_password[key] === item) {
          return true;
        }
      }
    } else {
      for (let key in this.state.errors) {
        if (this.state.errors[key] === item) {
          return true;
        }
      }
    }
  };

  onChangeValue = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  };

  postDataRequest = () => {
    let dataRequest = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      img: this.state.imgSrc.imagePreviewUrl,
      phone: this.state.phone
    };

    updateObject(API.profile, dataRequest)
      .then(() => this.setState({modalVisible: true, errors: [], error_text: ''}))
      .catch(e => this.showErrors(e));
  };

  postDataRequest_password = () => {
    let dataRequest = {
      old_password: this.state.old_password,
      password: this.state.password,
      confirm_password: this.state.confirm_password
    };
    updateObject(API.changePassword, dataRequest)
      .then(() => this.setState({
        modalVisible: true,
        password: '',
        old_password: '',
        confirm_password: '',
        error_text_password: '',
        errors_password: []
      }))
      .catch((error) => this.showErrors(error, 'password'));
  };

  render() {
    const {
      isLoading,
      modalVisible,
      imgSrc,
      img,
      email,
      first_name,
      last_name,
      phone,
      error_text,

      old_password,
      password,
      confirm_password,
      error_text_password
    } = this.state;

    if (isLoading) {
      return <Loader/>
    }

    return (
      <div>
        <div className="container">
          <Modal modalVisible={modalVisible} onClick={this.closeModal}/>
          <div className="info_profile">
            <div className="row">
              <div className="avatarUser">
                <img src={imgSrc ? imgSrc.imagePreviewUrl : img} alt=""/>
                <div className="pickImg">
                  <button className="formsButton">Upload photo</button>
                  <input type="file" id="upload-photo" onChange={(image) => {
                    let reader = new FileReader(),
                      imgSrc = image.target.files[0];
                    reader.onloadend = () => {
                      this.setState({
                        imgSrc: {
                          file: imgSrc,
                          imagePreviewUrl: reader.result
                        }
                      });
                    };
                    reader.readAsDataURL(imgSrc);
                  }}
                  />
                </div>
              </div>
              <div className="column">
                <input type="text"
                       className="formsInput"
                       placeholder="Email"
                       value={email}
                       readOnly={true}
                />
                <input type="text"
                       className='formsInput'
                       placeholder="First Name"
                       value={first_name}
                       name='first_name'
                       onChange={this.onChangeValue}
                />
                <input type="text"
                       className='formsInput'
                       placeholder="Last Name"
                       name='last_name'
                       value={last_name}
                       onChange={this.onChangeValue}
                />
                <input type="text"
                       className={`formsInput ${this.showErrorBorder('phone') ? 'error' : ''}`}
                       placeholder="Phone"
                       name='phone'
                       value={phone}
                       onChange={this.onChangeValue}
                />
              </div>
            </div>
            <span className="error_field">{error_text}</span>
            <button className="formsButton" onClick={this.postDataRequest}>Save</button>
          </div>

          <div className="info_profile">
            <h4 className='title_for_block'>Change password</h4>
            <input type="password"
                   className={`formsInput ${this.showErrorBorder('old_password', 'forPassword') ? 'error' : ''}`}
                   placeholder="Old Password"
                   value={old_password}
                   name='old_password'
                   onChange={this.onChangeValue}
            />
            <input type="password"
                   className={`formsInput ${this.showErrorBorder('password', 'forPassword') ? 'error' : ''}`}
                   placeholder="New Password"
                   value={password}
                   name='password'
                   onChange={this.onChangeValue}
            />
            <input type="password"
                   className={`formsInput ${this.showErrorBorder('confirm_password', 'forPassword') ? 'error' : ''}`}
                   placeholder="Confirm Password"
                   value={confirm_password}
                   name='confirm_password'
                   onChange={this.onChangeValue}
            />

            <span className="error_field">{error_text_password}</span>
            <button className="formsButton" onClick={this.postDataRequest_password}>Save</button>
          </div>
        </div>
      </div>
    )
  }
}