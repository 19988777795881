import React from 'react';
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';

import logo from '../../../images/ASL_logo_transparent_background.png';
import defaultAvatar from '../../../images/default-avatar.png';
import { hackySplitString as splitString } from '../../../services/stringSplit';

Font.register(
  'https://cdn.jsdelivr.net/npm/@typopro/web-open-sans@3.7.5/TypoPRO-OpenSans-Regular.ttf',
  { family: 'Regular' }
);

Font.register(
  'https://cdn.jsdelivr.net/npm/@typopro/web-open-sans@3.7.5/TypoPRO-OpenSansCondensed-Bold.ttf',
  { family: 'Bold' }
);

const teamNameMaxLength = 19;
const styles = StyleSheet.create({
  page: {
    fontSize: 8,
    lineHeight: '1.6',
    color: '#000',
    fontFamily: 'Regular',
  },
  contentGroup: {
    padding: 10,
    height: 153,
    width: 243,
    top: '0',
    left: '0',
    position: 'absolute',
    zIndex: 2,
  },
  logo: {
    width: 70,
    height: 'auto',
  },
  h1: {
    fontSize: 14,
    fontFamily: 'Bold',
    textAlighn: 'right',
    position: 'absolute',
    right: 10,
    top: 10,
  },
  h1SecondRow: {
    position: 'relative',
    left: '50.5',
  },
  value: {
    fontFamily: 'Bold',
  },
  teamNameSecondRow: {
    fontFamily: 'Bold',
    maxWidth: 84,
    position: 'relative',
    left: 48,
  },
  portrait: {
    width: 90,
    height: 'auto',
    position: 'absolute',
    right: 10,
    bottom: 10,
  },
  textBlock: {
    position: 'absolute',
    left: 10,
    bottom: 20,
  },
  coachTextBlock: {
    position: 'relative',
    top: 20,
    textAlighn: 'center',
    fontSize: 10,
    bottom: 20,
  },
});

const Card = ({ team, person, coach = false }) => {
  const [teamNameHead, teamNameTail] = splitString(
    team.title,
    teamNameMaxLength
  );

  return (
    <Page size={[243.78, 153.071]} /** 324 x 204 */ style={styles.page}>
      <View style={styles.contentGroup}>
        <Image src={logo} style={styles.logo} />
        <View style={styles.h1}>
          <Text>Academy Super League</Text>
          <Text style={styles.h1SecondRow}>
            {coach ? 'Coach ID card' : 'Player ID card'}
          </Text>
        </View>
        <View style={coach ? styles.coachTextBlock : styles.textBlock}>
          <Text>
            Name:{' '}
            <Text style={styles.value}>
              {coach ? `${person.first_name} ${person.last_name}` : person.name}
            </Text>
          </Text>

          {!coach && (
            <Text>
              Date of Birth:{' '}
              <Text style={styles.value}>{person.date_birth}</Text>
            </Text>
          )}

          <Text>
            Academy: <Text style={styles.value}>{team.academy}</Text>
          </Text>

          {!coach && teamNameTail && (
            <Text style={styles.teamNameSecondRow}>{teamNameTail}</Text>
          )}

        </View>
        <Image
          src={person.img ? person.img : defaultAvatar}
          // src={defaultAvatar}
          style={styles.portrait}
        />
      </View>
    </Page>
  );
};

const TeamPDF = ({ team }) => {
  // return (
  //   <Document>
  //     {team.head_coach && <Card team={team} person={team.head_coach} coach />}
  //     {team.assistant_coach && (
  //       <Card team={team} person={team.assistant_coach} coach />
  //     )}
  //     {team.teamplayer_profiles.map(player => (<Card team={team} person={player.player} key={player.player.id} />))}
  //   </Document>
  // );
  return (
    <Document>
      {team.head_coach && team.head_coach_picked && <Card team={team} person={team.head_coach} coach />}
      {team.assistant_coach && team.assistant_coach_picked && (
        <Card team={team} person={team.assistant_coach} coach />
      )}
      {team.teamplayer_profiles.map(player => (
        player.picked ?
          <Card team={team} person={player.player} key={player.player.id} />
        :
          null
      ))}
    </Document>
  );
};

export default TeamPDF;
