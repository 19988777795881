import React, { Component } from 'react';

import Loader from '../../../components/Loader';
import Modal from '../../../components/ModalSuccessful';

import {API, fetchData, createObject} from '../../../services/index';


class ListRefereeForSending extends Component {

  addColorForPicked(item) {
    for(let key in this.props.receivers) {
      if(this.props.receivers[key].receiver === item.id) {
        return true;
      }
    }
  }

  render () {
    const list = this.props.data.map((item, index) => {
      return (
        <div
          className={this.addColorForPicked(item) ? 'send-email-referees-item picked' : 'send-email-referees-item'}
          key={index}
          onClick={(() => this.props.onClick(item))}
        >
          <span className="checkmark"></span>
          <span className="name-margin-right">{item.first_name}</span>
          <span>{item.last_name}</span>
        </div>
      )
    });

    return (
      <div className="send-email-list-referees">
        {list}
        <div className="select-all-for-email">
          <div
            className= 'send-email-referees-item'
            onClick={(() => this.props.pickAll())}
          >
            <span className="checkmark"></span>
            <span className="name-margin-right" style={{fontWeight: 'bold'}}>Select All</span>
          </div>
        </div>
      </div>
    )
  }
}

class SendEmail extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      data: '',
      title: '',
      text: '',
      receivers: [],

    }
  }

  componentDidMount() {
    fetchData(API.profile.moderator.referees)
    .then((response) =>this.setState({data: response, isLoading: false}));
  }

  showErrorBorder (item) {
    for(let key in this.state.errors) {
      if(this.state.errors[key] === item) {
        return true;
      }
    }
  }

  pickedReferee = (item) => {
    let array = this.state.receivers, consisted = false;
    if(array.length > 0) {
      array.forEach((i) => {
        if(i.receiver === item.id) {
          consisted = true;
        }
      });

      if(consisted === true) {
        array = array.filter((i) => i.receiver !== item.id);
      } else {
        array.push({receiver: item.id});
      }
    } else {
      array.push({receiver : item.id});
    }
    this.setState({receivers: array});
  };

  pickAll = () => {
    let array = [];
    const { data } = this.state;
    data.map(({ id }) => array.push({receiver: id}));
    this.setState({ receivers: array });
  };

  closeModal = () => {
    this.setState({modalVisible: !this.state.modalVisible});
  };

  showErrors(error) {
    let errors = [];
    for(let item in error) {
      errors.push(item);
      this.setState({errors: errors, error_text: error[item]});
    }
  }

  postDataRequest = () => {
    let dataRequest = {
      title: this.state.title,
      text: this.state.text,
      receivers: this.state.receivers
    };

    createObject(API.profile.moderator.send_email, dataRequest)
      .then((response) => this.setState({modalVisible: true, errors: [], error_text: '', title: '', text: '', receivers: []}))
      .catch((error) => this.showErrors(error));
  };

  render() {
    if(this.state.isLoading) {
      return (<Loader/>)
    }
    return (
      <div>
        <div className="container send-email">
          <Modal modalVisible={this.state.modalVisible} onClick={() => this.closeModal()}/>
          <h1>Send email</h1>
          <div className="form-to-send">
            <input type="text" className={this.showErrorBorder('title') ? 'formsInput error' : 'formsInput'}
              placeholder="Theme"
              value={this.state.title}
              onChange={(text) => this.setState({title: text.target.value})}
            />
            <textarea className={this.showErrorBorder('text') ? 'formsInput error' : 'formsInput'}
              cols="30" rows="2"
              placeholder="Message"
              value={this.state.text}
              onChange={(text) => this.setState({text: text.target.value})}
            />
          </div>
          <h3>Select referees:</h3>
          <ListRefereeForSending
            data={this.state.data}
            receivers={this.state.receivers}
            onClick={this.pickedReferee}
            pickAll={this.pickAll}
          />
          <span className="error_field">{this.state.error_text}</span>
          <button className="formsButton" onClick={() => this.postDataRequest()}>Send email</button>
        </div>
      </div>
    )
  }
}

export default SendEmail;
