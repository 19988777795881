import React, { Component } from 'react';
import {Link} from "react-router-dom";
import logo from "../../images/ASL_logo_transparent_background.png";


export class HeaderNav extends Component {
  render() {
    const {
      toggleMenuList,
      menuList,
      pages,
      loggedIn,
      logout,
      toggleModal,
      isModalOpen,
      postDataLogin,
      postDataRegistration,
      user_type,
      login_email,
      login_password,
      regist_email,
      regist_password,
      regist_newPassword,
      error_text,
      error_text_registration,
      onChangeValue,
      showErrorBorder,
      toggleModalForcibly,
      is_roster_view_allowed
    } = this.props;

    return <header>
      <div className="backgroundHeader">
        <div className="container headerNavigation">
          <div className="row">
            <Link to="/" className="logo">
              <img src={logo} alt="" />
            </Link>
            <div
              className="forMobileMenu"
              onClick={toggleMenuList}
            >
              <h4>
                <i className="fas fa-bars" /> Menu
              </h4>
            </div>
          </div>
          <nav
            className={`mobile_menu ${menuList ? 'visible' : ''}`}
          >
            <ul>
              {/*<li>*/}
              {/*<Link to="/" onClick={this.toggleMenuList}>*/}
              {/*Home*/}
              {/*</Link>*/}
              {/*</li>*/}
              <li className="otherPages">
                <span className="prototypeLink">About</span>
                <div
                  className={pages.length !== 0 ? 'dropdownList' : 'notVisible'}
                >
                  {pages.length !== 0
                    ? pages.map((page, index) => (
                      <Link to={'/pages/' + page.id} key={index}>
                        {page.title}
                      </Link>
                    ))
                    : ''}
                </div>
              </li>
              {is_roster_view_allowed === true ? (
              <li>
                <Link
                  to="/league/current"
                  onClick={toggleMenuList}
                >
                  League
                </Link>
              </li>) : null}
                <li className="otherPages">
                  <span className="prototypeLink">Academies</span>
                  <div className="dropdownList teamDrop">
                    <Link to="/academies" onClick={toggleMenuList}>
                      Academies
                    </Link>
                    {/*<Link to="/teams" onClick={toggleMenuList}>*/}
                    {/*Teams*/}
                    {/*</Link>*/}
                  </div>
                </li>
              <li>
                <Link to="/gallery" onClick={toggleMenuList}>
                  Gallery
                </Link>
              </li>
              <li>
                <Link to="/news" onClick={toggleMenuList}>
                  News
                </Link>
              </li>
              <li>
                <Link to="/archive" onClick={toggleMenuList}>
                  Archive
                </Link>
              </li>
              <li>
                <Link to="/contacts" onClick={toggleMenuList}>
                  Contacts
                </Link>
              </li>
              {loggedIn ? (
                <li>
                  <Link
                    to="/profile"
                    className="forRequests"
                    onClick={toggleMenuList}
                  >
                    Profile
                  </Link>
                </li>
              ) : (
                ''
              )}
              <li className={loggedIn ? 'logout' : 'login'}>
                        <span
                          className="prototypeLink"
                          onClick={() => {
                            loggedIn
                              ? logout()
                              : toggleModal();
                            toggleMenuList();
                          }}
                        >
                          {loggedIn ? 'Logout' : 'Log In'}
                        </span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div
        className={isModalOpen ? 'signIn' : 'notVisible'}
        ref="item"
      >
        <div className="container">
          <div className="item_sigIn">
            <h4 className="header4">Log In</h4>
            <form
              onSubmit={postDataLogin}
            >
              <input
                type="text"
                className={`formsInput ${showErrorBorder('login_email') ? 'error' : ''}`}
                value={login_email}
                placeholder="Email"
                name='login_email'
                onChange={onChangeValue}
              />
              <input
                type="password"
                className={`formsInput ${showErrorBorder('login_password') ? 'error' : ''}`}
                value={login_password}
                placeholder="Password"
                name='login_password'
                onChange={onChangeValue}
              />
              <span className="error_field">
                        {error_text}
                      </span>
              <button className="formsButton">Login</button>

              <div className="forgot_password">
                <Link
                  className="formsButton"
                  to="/password/recovery"
                  onClick={() => toggleModalForcibly(false)}
                >
                  Forgot password
                </Link>
              </div>
            </form>
          </div>

          <div className="item_sigIn">
            <h4 className="header4">Registration</h4>
            <div>
              <input
                type="text"
                className={`formsInput ${showErrorBorder('email') ? 'error' : ''}`}
                value={regist_email}
                placeholder="Email"
                name='regist_email'
                onChange={onChangeValue}
              />
              <select
                className={`formsInput ${showErrorBorder('user_type') ? 'error' : ''}`}
                value={user_type}
                name='user_type'
                onChange={onChangeValue}
              >
                <option value="">choose</option>
                {/*<option value="1">Academy Administrator</option>*/}
                <option value="2">Referee</option>
              </select>
              <input
                type="password"
                className={`formsInput ${showErrorBorder('password') ? 'error' : ''}`}
                value={regist_password}
                placeholder="Password"
                name='regist_password'
                onChange={onChangeValue}
              />
              <input
                type="password"
                className={`formsInput ${showErrorBorder('newPassword') ? 'error' : ''}`}
                value={regist_newPassword}
                placeholder="Confirm Password"
                name='regist_newPassword'
                onChange={onChangeValue}
              />
              <span className="error_field">
                        {error_text_registration}
                      </span>
              <button
                className="formsButton"
                onClick={postDataRegistration}

              >
                Registration
              </button>
            </div>
          </div>
          <div className="closeSignIn">
            <button
              className="close"
              onClick={toggleModal}
            >
              <i className="fas fa-times" />
            </button>
          </div>
        </div>
      </div>
    </header>
  }
}
