import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import Loader from '../../../components/Loader';

import {API, fetchData} from '../../../services/index';


class HistoryEmails extends Component {
  render () {
    const list = this.props.data.map((item, index) => {
      return (
        <Link to={'/profile/email/' + item.id} className="history-emails-item" key={index}>
          <span>{item.title}</span>
          <span>{item.created}</span>
        </Link>
      )
    })

    return (
      <div className="history-emails">
        {list}
      </div>
    )
  }
}

class Emails extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      data: ''
    }
  }

  componentDidMount() {
    fetchData(API.profile.moderator.emails)
    .then((response) =>this.setState({data: response, isLoading: false}));
  }
  render() {
    if(this.state.isLoading) {
      return (<Loader/>)
    }
    return (
      <div>
        <div className="container history-send-emails">
          <h1>emails history</h1>
          <HistoryEmails
            data={this.state.data}
          />
          <div className="referees-head-block">
            <Link to='/profile/send-email'>Send to email</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Emails;
