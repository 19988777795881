import React, { Component } from 'react';
import {API, setAgreementPlayer, updateObject} from "../services";

import { days, months } from "../constants";


export class AgreementModal extends Component {
  state = {
    pickedDay: '',
    pickedMonth: '',
    pickedYear: '',
    namePlayer: '',
    surnamePlayer: '',
    allow_public: true,
    errorPopUp: ''
  };

  changeValue = (e, type, boolean = '') => {
    const { value } = e.target;

    if (type === 'name') {
      this.setState({ namePlayer: value });
    }
    if (type === 'surname') {
      this.setState({ surnamePlayer: value });
    }
    if (type === 'day') {
      this.setState({ pickedDay: value });
    }
    if (type === 'month') {
      this.setState({ pickedMonth: value });
    }
    if (type === 'year') {
      this.setState({ pickedYear: value });
    }
    if (type === 'accessibility') {
      this.setState({ allow_public: boolean });
    }
  };

  submitInfo = () => {
    const { togglePopUp } = this.props;
    if (
      this.state.pickedDay ||
      this.state.pickedMonth ||
      this.state.pickedYear ||
      this.state.namePlayer ||
      this.state.surnamePlayer
    ) {
      let data = {
        date_birth:
          this.state.pickedDay +
          '/' +
          this.state.pickedMonth +
          '/' +
          this.state.pickedYear,
        first_name: this.state.namePlayer,
        last_name: this.state.surnamePlayer,
        allow_public: this.state.allow_public,
      };

      updateObject(API.giveAccess, data).then(response => {
        setAgreementPlayer(true);
        togglePopUp();
        this.setState({ errorPopUp: '' });
      });
    } else {
      this.setState({
        errorPopUp: 'All fields are required',
      });
    }
  };

  render() {
    const { toggleVisible } = this.props;
    const {
      namePlayer, surnamePlayer, pickedDay, pickedMonth, pickedYear, errorPopUp
    } = this.state;
    return (
      <div className={toggleVisible ? 'bg_modal' : 'notVisible'}>
        <div className="modal modal-player-give-access">
          <h5>Photo Release Waiver.
            By entering BCSAA and participating in the ASL, I hereby grant BCSAA on behalf of myself and my ward(s),
            the irrevocable right and permission to photograph and/or record me in connection with BCSAA and ASL and to use the photograph
            and/or recording for all purposes, including advertising and promotional purposes, in any manner and in any media now or hereafter known,
            in perpetuity throughout the world, without restriction as to alteration. I waive any right to inspect or approve
            the use of the photograph and/or recording and acknowledge and agree that the rights granted to this release are without
            compensation of any kind.</h5>
          {/*<div className="formsButton" onClick={this.props.onClick}>Go to profile</div>*/}
          <input type="text" className='formsInput'
                 placeholder="First Name"
                 value={namePlayer}
                 onChange={(text) => this.changeValue(text, 'name')}/>
          <input type="text" className='formsInput'
                 placeholder="Last Name"
                 value={surnamePlayer}
                 onChange={(text) => this.changeValue(text, 'surname')}/>
          <div className="date-birth-row">
            <AgreementModalSelectBirth
              type="days"
              data={days}
              value={pickedDay}
              // showErrorBorder={this.showErrorBorder('date_birth')}
              onChange={(text) => this.changeValue(text, 'day')}
            />
            <AgreementModalSelectBirth
              type="months"
              data={months}
              value={pickedMonth}
              // showErrorBorder={this.showErrorBorder('date_birth')}
              onChange={(text) => this.changeValue(text, 'month')}
            />
            <AgreementModalSelectBirth
              type="years"
              value={pickedYear}
              // showErrorBorder={this.showErrorBorder('date_birth')}
              onChange={(text) => this.changeValue(text, 'year')}
            />
          </div>
          <div className="item-row">
            <p>
              <input type="radio" id="agree" name="radio-group" onChange={(e) => this.changeValue(e, 'accessibility', true)}/>
              <label htmlFor="agree">Agree</label>
            </p>
            <p>
              <input type="radio" id="disagree" name="radio-group" onChange={(e) => this.changeValue(e, 'accessibility', false)} />
              <label htmlFor="disagree">Disagree</label>
            </p>
          </div>
          {/*<Link to="/profile" className="formsButton">Go to profile</Link>*/}
          <p style={{ color: 'red' }}>{errorPopUp}</p>
          <div className="formsButton" onClick={this.submitInfo}>Submit</div>
        </div>
      </div>
    )
  }
}

export class AgreementModalSelectBirth extends Component {
  render () {
    let list, propsValue = '', titleText = '', value = '';
    if(this.props.value !== null) {
      propsValue = this.props.value;
    }

    if(this.props.type === 'days') {
      titleText = 'Day';
      value = propsValue;
      list = this.props.data.map((item, index) => <option value={item.value} key={index}>{item.text}</option>);
    }
    if(this.props.type === 'months') {
      titleText = 'Month';
      value = propsValue;
      list = this.props.data.map((item, index) => <option value={item.value} key={index}>{item.text}</option>);
    }
    if(this.props.type === 'years') {
      titleText = 'Year';
      let date = new Date();
      let currentYear = date.getFullYear();
      let year = date.getFullYear()-50;
      let years = [];

      while (currentYear !== year) {
        years.push({text: currentYear, value: currentYear});
        currentYear--;
      }

      value = parseInt(propsValue, 10);

      list = years.map((item, index) => <option value={item.value} key={index} >{item.text}</option>);
    }
    return (
      <select
        className={this.props.showErrorBorder ? 'formsSelect error' : 'formsSelect'}
        value={!!value && value}
        // value={value}
        onChange={this.props.onChange}
      >
        <option value="">{titleText}</option>
        {list}
      </select>
    )
  }
}

