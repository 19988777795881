import React, { Component } from 'react';

import Loader from '../../../components/Loader';

import {API, fetchDetail} from '../../../services/index';


class ReceiversList extends Component {
  render () {
    const list = this.props.data.map((item, index) => {
      return (
        <div className="receivers-block-item" key={index}>
          <span>{item.first_name}</span>
          <span>{item.last_name}</span>
        </div>
      )
    })

    return (
      <div className="receivers-block">
        <h4>Receivers:</h4>
        {list}
      </div>
    )
  }
}

class EmailDetail extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      data: ''
    }
  }

  componentDidMount() {
    fetchDetail(API.profile.moderator.emails, this.props.match.params.id)
    .then((response) =>this.setState({data: response, isLoading: false}));
  }
  render() {
    if(this.state.isLoading) {
      return (<Loader/>)
    }
    return (
      <div>
        <div className="container email-details">
          <h1>Email Detail</h1>
          <h3>{this.state.data.title}</h3>
          <p>{this.state.data.text}</p>
          <div className="receivers-block-and-date">
            <ReceiversList
              data={this.state.data.receivers}
            />
            <div className="date-block">
              <span>Created: {this.state.data.created}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EmailDetail;
