import React, {Component, Fragment} from 'react';
import {SwatchesPicker} from "react-color";


export class TeamInfoForm extends Component {
  render() {
    const {
      showTeamInfoForm,
      formEdit,
      user_type,

      clothesType,
      home_color,
      guest_color,
      title,
      short_title,
      gender,
      coaches,
      head_coach,
      assistant_coach,
      manager,
      description,
      error_text,
      ages,
      age,

      pickTShirt,
      handleClothesColor,
      showErrorBorder,
      onChangeValue,
      _onChangeValueAndConvert,
      onChangeValueFromArray,
      postDataRequest,
      _toggleTeamForm,
      deleteTeam,
      cleanTeamInfoForm
    } = this.props;

    if(!showTeamInfoForm) {
      return null
    }


    return (
      <Fragment>
        <div className="info_profile teamInfoForm-container">
          <div className="closeSignIn">
            <button
              className="close"
              onClick={() => {
                _toggleTeamForm();
                cleanTeamInfoForm();
              }}
            >
              <i className="fas fa-times"/>
            </button>
          </div>
          <div className="row">
            <div className="avatarUser">
              <div className="colorsPicker">
                <span className="helpText">Select Home/Guest before choosing the colour</span>
                <div className="teamsColors">
                  <div className={clothesType === 'home' ? 'homeTshirt active' : 'homeTshirt'}
                       style={{fill: home_color}} onClick={() => pickTShirt('home')}>
                    <span>Home</span>
                    <svg viewBox="0 0 512 512">
                      <path className="homeTshirt__border" d="M362.672,42.66l-53.328,0c0,29.414 -23.938,53.336 -53.344,53.336c-29.406,
                            0 -53.328,-23.922 -53.328,-53.336l-53.336,0l-149.336,85.336l42.672,117.336l64,-21.336l0,245.344l298.672,0l0,
                            -245.344l64,21.336l42.656,-117.336l-149.328,-85.336Zm93.703,175.867l-44.297,-14.766l-28.078,-9.366l0,
                            253.602l-256,0l0,-224l0,-29.602l-28.078,9.367l-44.297,14.766l-29.43,-80.93l128.805,-73.602l29.43,
                            0c9.18,30.844 37.75,53.336 71.57,53.336c33.828,0 62.391,-22.492 71.562,-53.336l29.438,
                            0l128.812,73.602l-29.437,80.929Z"
                      />
                      <path d="M456.375,218.527l-44.297,-14.766l-28.078,-9.366l0,253.602l-256,0l0,-224l0,-29.602l-28.078,
                            9.367l-44.297,14.766l-29.43,-80.93l128.805,-73.602l29.43,0c9.18,30.844 37.75,53.336 71.57,53.336c33.828,
                            0 62.391,-22.492 71.562,-53.336l29.438,0l128.812,73.602l-29.437,80.929Z"
                      />
                    </svg>
                  </div>
                  <div className={clothesType === 'guest' ? 'guestTshirt active' : 'guestTshirt'}
                       style={{fill: guest_color}} onClick={() => pickTShirt('guest')}>
                    <span>Guest</span>
                    <svg viewBox="0 0 512 512">
                      <path className="homeTshirt__border" d="M362.672,42.66l-53.328,0c0,29.414 -23.938,53.336 -53.344,53.336c-29.406,
                            0 -53.328,-23.922 -53.328,-53.336l-53.336,0l-149.336,85.336l42.672,117.336l64,-21.336l0,245.344l298.672,0l0,
                            -245.344l64,21.336l42.656,-117.336l-149.328,-85.336Zm93.703,175.867l-44.297,-14.766l-28.078,-9.366l0,
                            253.602l-256,0l0,-224l0,-29.602l-28.078,9.367l-44.297,14.766l-29.43,-80.93l128.805,-73.602l29.43,
                            0c9.18,30.844 37.75,53.336 71.57,53.336c33.828,0 62.391,-22.492 71.562,-53.336l29.438,
                            0l128.812,73.602l-29.437,80.929Z"
                      />
                      <path d="M456.375,218.527l-44.297,-14.766l-28.078,-9.366l0,253.602l-256,0l0,-224l0,-29.602l-28.078,
                            9.367l-44.297,14.766l-29.43,-80.93l128.805,-73.602l29.43,0c9.18,30.844 37.75,53.336 71.57,53.336c33.828,
                            0 62.391,-22.492 71.562,-53.336l29.438,0l128.812,73.602l-29.437,80.929Z"
                      />
                    </svg>
                  </div>
                </div>
                <SwatchesPicker
                  color={clothesType === 'home' ? home_color : guest_color}
                  width='158px'
                  height='150px'
                  onChangeComplete={handleClothesColor}
                />
              </div>
            </div>
            <div className="column">
              <input type="text" className={showErrorBorder('title') ? 'formsInput error' : 'formsInput'}
                     placeholder="Team Name"
                     value={title}
                     name='title'
                     onChange={onChangeValue}
              />
              <div className='row three-in-row'>
                <input type="text"
                       className={showErrorBorder('short_title') ? 'formsInput error' : 'formsInput'}
                       placeholder="Abbreviation"
                       value={short_title}
                       name='short_title'
                       onChange={onChangeValue}
                />
                <select className={showErrorBorder('gender') ? 'formsSelect error' : 'formsSelect'}
                        value={gender}
                        name='gender'
                        onChange={_onChangeValueAndConvert}
                >
                  <option value="">Gender</option>
                  <option value="1">Boys</option>
                  <option value="2">Girls</option>
                </select>
                <SelectAge
                  data={ages}
                  value={age}
                  showErrorBorder={showErrorBorder('age')}
                  name='age'
                  onChange={_onChangeValueAndConvert}
                />
              </div>
              <div className='coachesSelectWrapper'>
                <div>
                  <SelectHeadCoach
                    coaches={coaches}
                    value={head_coach && head_coach.id}
                    onChange={(e) => onChangeValueFromArray(e, 'head_coach')}
                  />
                </div>
                <div>
                  <SelectAssistantCoach
                    coaches={coaches}
                    value={assistant_coach && assistant_coach.id}
                    onChange={(e) => onChangeValueFromArray(e, 'assistant_coach')}
                  />
                </div>
                <div>
                  <SelectManager
                    coaches={coaches}
                    value={manager && manager.id}
                    onChange={(e) => onChangeValueFromArray(e, 'manager')}
                  />
                </div>
              </div>
              <textarea className={showErrorBorder('description') ? 'formsInput error' : 'formsInput'}
                        cols="30" rows="2"
                        placeholder="Description"
                        value={description}
                        name='description'
                        onChange={onChangeValue}
              />
              <div className="row" style={{'justifyContent': 'space-around'}}>
                <button
                  style={{
                    ...stylesTeamInfoForm.coachActionBtn,
                    // flex: 1,
                    backgroundColor: '#04a303',
                    color: '#fff',
                    width: '30%'
                  }}
                  onClick={(e)=> postDataRequest()}
                >
                  Save
                </button>
                {formEdit && user_type !== 5 ?
                  <button
                    style={{
                      ...stylesTeamInfoForm.coachActionBtn,
                      // flex: 1,
                      backgroundColor: '#ff0000',
                      color: '#fff',
                      width: '30%'
                    }}
                    onClick={() => {
                      deleteTeam();
                    }
                    }
                  >
                    Delete Team
                  </button>
                  : null
                }
              </div>
            </div>
          </div>
          <span className="error_field">{error_text}</span>
        </div>
      </Fragment>
    )
  }
}

export const SelectHeadCoach = props => {
  let filteredCoaches = props.coaches.filter((item) => item.coach_subtype !== 2);
  const list = filteredCoaches.map((coach, i) => <option key={`${coach.id}${i}`}
                                                       value={coach.id}>{coach.first_name} {coach.last_name}</option>);
  return (
    <select className={props.showErrorBorder ? 'formsSelect error' : 'formsSelect'}
            value={props.value}
            onChange={props.onChange}
    >
      <option value=''>Head Coach</option>
      {list}
    </select>
  )
};

export const SelectAssistantCoach = props => {
  let filteredCoaches = props.coaches.filter((item) => item.coach_subtype !== 2);
  const list = filteredCoaches.map((coach, i) => <option key={`${i}${coach.id}`}
                                                       value={coach.id}>{coach.first_name} {coach.last_name}</option>);
  return (
    <select className={props.showErrorBorder ? 'formsSelect error' : 'formsSelect'}
            value={props.value}
            onChange={props.onChange}
    >
      <option value=''>Assistant Coach</option>
      {list}
    </select>
  )
};

export const SelectManager = props => {
  let filteredCoaches = props.coaches.filter((item) => item.coach_subtype !== 1);
  const list = filteredCoaches.map((coach, i) => <option key={`${i}${coach.id}`}
                                                       value={coach.id}>{coach.first_name} {coach.last_name}</option>);
  return (
    <select className={props.showErrorBorder ? 'formsSelect error' : 'formsSelect'}
            value={props.value}
            onChange={props.onChange}
    >
      <option value=''>Manager</option>
      {list}
    </select>
  )
};

export const SelectAge = props => {
  const list = props.data.map((item, index) => <option key={index} value={item.id}>{item.title}</option>);
  const value = props.data.filter(age => age.id === props.value)[0];
  return (
    <select className={props.showErrorBorder ? 'formsSelect error' : 'formsSelect'}
            value={value ? value.id : ''}
            name={props.name}
            onChange={props.onChange}
    >
      <option value="">Age Group</option>
      {list}
    </select>
  )
};

const stylesTeamInfoForm = {
  coachActionBtn: {
    // width: '100%',
    borderRadius: '3px',
    backgroundColor: '#fec722',
    padding: 5,
    cursor: 'pointer',
    transition: 'all 0.5s',
    outline: 'none',
    boxShadow: 'none',
    border: 'none',
    marginBottom: 5
  },
};
