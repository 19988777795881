import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../../components/Loader';
import {API, fetchData} from '../../services';

import newsLogo from '../../images/bg.jpg';


class NewsItem extends Component {
  render() {
    const listItems = this.props.data.map((item, index) =>{
      return (
        <Link to={'/news/' +item.id} className="newsItem" key={index}>
          <img src={item.square_image ? item.square_image : newsLogo} alt="" />
          <div className="item_description">
            <h4 className="header4">{item.name}</h4>
            <p className="paragraph">{item.text}</p>
            <div className="dateItem">
              <h5><i className="fas fa-clock" /> {item.datetime.date} - {item.datetime.time} {item.datetime.am_pm}</h5>
            </div>
          </div>
        </Link>
      )
    });

    return (
      <div className="newsList">
        {listItems}
      </div>
    )
  }
}

class NewsList extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      news: ''
    }
  }

  componentDidMount() {
    fetchData(API.news)
      .then((response) => this.setState({news: response, isLoading: false}));
  }

  render() {
    if(this.state.isLoading) {
      return <Loader/>
    }

    return (
      <div className="base">
        <div className="emptyHeader" />

        <div className="title">
          <h2 className="header2 container">NEWS</h2>
        </div>

        <div className="container">
          <NewsItem data={this.state.news}/>
        </div>
      </div>
    );
  }
}

export default NewsList;
