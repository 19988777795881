import {
  authenticate,
  login,
  logout,
  isLoggedIn,
  userData,
  getUser,
  setAgreementPlayer,
  getAgreementPlayer
} from './auth';
import {
  registration,
  fetchData,
  createObject,
  fetchDetail,
  updateObject,
  patchObject,
  deleteObject
} from './utils';


export const API = {
  contacts: {
    data: 'contacts',
    create: 'support-request'
  },
  auth: {
    login: 'auth'
  },
  registration: {
    login: 'user/create'
  },
  news: {
    data: 'news',
    details: 'news/{id}',
    sidebar: {
      data: 'news/bottom'
    },
    header: {
      data: 'news/top'
    }
  },
  social_network: {
    data: 'social-networking-site-links'
  },
  teams: {
    data: 'teams',
    details: 'teams/{id}'
  },
  weeks: {
    data: 'weeks'
  },
  ages: {
    data: 'age/list'
  },
  ages_league: {
    data: 'age-league/'
  },
  academiesTeams: {
    data: 'team/list'
  },
  partners: {
    data: 'partners'
  },
  academies: {
    data: 'academies',
    details: 'academies/{id}'
  },
  galleryPhotos: {
    data: 'photo-galleries'
  },
  galleryVideos: {
    data: 'video-galleries'
  },
  refereesList: {
    data: 'referee/list'
  },
  academyList: {
    data: 'academy/list',
    details: 'academy/list/{id}'
  },
  insurance: {
    data: 'insurance-contract'
  },
  myInsurance: {
    data: 'profile/player/insurance',
    create: 'profile/player/insurance',
    update: 'profile/player/insurance'
  },
  profile: {
    data: 'profile',
    update: 'profile',
    moderator: {
      data: 'profile/moderator/games',
      update: 'profile/moderator/games/referee/{id}',
      referees: {
        data: 'profile/moderator/list'
      },
      emails: {
        data: 'profile/moderator/messages',
        details: 'profile/moderator/messages/{id}'
      },
      send_email: {
        create: 'profile/moderator/message'
      },
      games: {
        update: 'profile/moderator/games/save/{id}'
      },
      statistic: {
        details: 'profile/moderator/referee/{id}'
      }
    },
    referee: {
      data: 'profile/referee/games',
      update: 'profile/referee/games/{id}',
    },
    // player_team: {
    //   data: 'profile/player/team',
    //   create: 'profile/player/team'
    // },
    academyOwner: {
      academy: {
        data: 'profile/academyowner/academy',
        create: 'profile/academyowner/academy',
        update: 'profile/academyowner/academy',
        patch: 'profile/academyowner/academy',
      },
      teams: {
        data: 'profile/teams',
        details: 'profile/teams/{id}',
        create: 'profile/teams',
        delete: 'profile/teams/{id}',
        update: 'profile/teams/{id}',
        defaultColor: {
          data: 'academy/color'
        },
        import: 'player-import/'
      },
      requests: {
        data: 'profile/academyowner/requests',
        update: 'profile/academyowner/requests/{id}'
      },
      editPlayer: {
        update: 'profile/academyowner/player/update/{id}',
        delete: 'profile/academyowner/player/delete/{id}'
      }
    }
  },
  locations: {
    data: 'cities'
  },
  provinces: {
    data: 'provinces'
  },
  isMember: {
    data: 'profile/team/member'
  },
  changePassword: {
    update: 'user/change/password'
  },
  details: {
    player: {
      details: 'player/detail/{id}'
    },
    referee: {
      details: 'referee/detail/{id}'
    }
  },
  tournaments: {
    details: 'tournaments/{id}',
    data: 'tournaments',
  },
  home: {
    data: 'home'
  },
  password: {
    recovery: 'reset',
    reset: 'reset/confirm'
  },
  sidebar: {
    data: 'sidebar'
  },
  requests: {
    data: 'profile/academyowner/requests/quantity'
  },
  pages: {
    data: 'pages',
    details: 'pages/{id}'
  },
  giveAccess: {
    update: 'profile/player/personal'
  },
  coaches: {
    create: 'profile/academyowner/coach/create',
    list: 'profile/academyowner/coach/list',
    coach: 'profile/academyowner/coach',
    createPlayer: 'profile/player/create',
    player: 'profile/player',
    playerEdit: {
      details: 'profile/player/{id}'
    }
  },
  importFile: 'profile/player/import',
  deletePlayer: 'profile/player/delete',
  schedule: {
    data: 'tournaments-schedule'
  },
  league_change_score: {
    patch: 'games/{id}'
  }
};


function getAges() {
  return new Promise((resolve, reject) => {

    fetchData(API.ages)
      .then((ages) => {
        sessionStorage.setItem('ages', JSON.stringify(ages));
        resolve(ages)
      })
      .catch((error) => {
        reject(error)
      });

  });
}


function getAgesForLeague(leagueId) {
  return new Promise((resolve, reject) => {

    fetchData(`${API.ages_league.data}${leagueId}`)
      .then((ages) => {
        sessionStorage.setItem('ages', JSON.stringify(ages));
        resolve(ages)
      })
      .catch((error) => {
        reject(error)
      });

  });
}


function getReferees() {
  return new Promise((resolve, reject) => {
    fetchData(API.refereesList)
      .then((referees) => {
        sessionStorage.setItem('referees', JSON.stringify(referees));
        resolve(referees)
      })
      .catch((error) => {
        reject(error)
      });
  });
}

export {
  authenticate,
  userData,
  getUser,
  login,
  logout,
  isLoggedIn,
  registration,
  fetchData,
  createObject,
  fetchDetail,
  updateObject,
  patchObject,
  getAges,
  getAgesForLeague,
  getReferees,
  deleteObject,
  setAgreementPlayer,
  getAgreementPlayer
};
