import React, {Component} from 'react';
import {API, createObject, deleteObject, fetchData, updateObject} from "../../../services";
import {validateEmail} from "../../../services/utils";
import DefaultAvatar from '../../../images/default-avatar.png';
import Modal from '../../../components/ModalSuccessful';


export class ProfileCoaches extends Component {
  _isMounted = false;
  state = {
    coaches: [],

    email: '',
    first_name: '',
    last_name: '',
    img: null,
    imgPrev: null,
    coach_subtype: '',
    // imgSrc: null,

    errors: {},
    error_text: '',
    editingForm: false,
    modalVisible: false
  };

  componentDidMount() {
    this._isMounted = true;
      if (this.props.user.academy_exists) {
        this.fetchCoaches();
      }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleModal = () => this.setState(state => ({modalVisible: !state.modalVisible}));

  fetchCoaches = () => {
    fetchData(API.coaches.list)
      .then(coaches => {
        if (this._isMounted) {
          this.setState({coaches})
        }
      })
      .catch(e => {})
  };

  handleChange = input => e => this.setState({
    [input]: e.target.value,
    errors: {},
    error_text: ''
  });

  _onChangeValueAndConvert = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: parseInt(value, 10)
    });
  };

  handleImgPick = img => {
    let reader = new FileReader(),
      imgSrc = img.target.files[0];
    if (imgSrc.size > 1000000) {
      alert('Wow! Why do you need so big size image?')
    } else {
      reader.onloadend = () => {
        this.setState({
          // imgSrc,
          img: reader.result
        });
      };
      reader.readAsDataURL(imgSrc);
    }
  };

  showErrors = error => {
    let errors = [];
    for (let item in error) {
      errors.push(item);
      this.setState({errors: errors, error_text: error[item]});
    }
  };

  showErrorBorder = item => {
    for (let key in this.state.errors) {
      if (this.state.errors[key] === item) {
        return true;
      }
    }
  };

  submitForm = () => {
    if (this.validForm()) {
      const {
        first_name,
        last_name,
        email,
        img,
        editingForm,
        coach_subtype,
        imgPrev,
        id
      } = this.state;
      const body = {
        first_name,
        last_name,
        email,
        coach_subtype
      };
      if (img !== imgPrev) {
        body.img = img
      }
      if (editingForm) {
        updateObject(`${API.coaches.coach}/${id}`, body)
          .then(res => {
            if (res) {
              this.toggleModal();
              this.setState({
                editingForm: false,
                email: '',
                first_name: '',
                last_name: '',
                img: null,
                imgPrev: null,
                coach_subtype: '',
              }, this.fetchCoaches)
            }
          }).catch(e => this.setState({errors: e}))
      } else {
        createObject(API.coaches.create, body)
          .then(res => {
            if (res) {
              this.setState(state => ({
                email: '',
                first_name: '',
                last_name: '',
                img: null,
                imgPrev: null,
                coach_subtype: '',
              }), this.fetchCoaches)
            }
          })
          .catch(e => this.setState({errors: e}))
      }
    }
  };

  validForm = () => {
    const {email} = this.state;
    if (validateEmail(email)) {
      return true
    } else {
      this.setState(({errors}) => ({errors: {...errors, email: 'Please enter correct email'}}));
      return false
    }
  };

  deleteCoach = param => {
    if (window.confirm('Do you want to delete coach?')) {
      deleteObject(`${API.coaches.coach}/${param}`)
        .then(res => {
          if (res) {
            this.setState(state => ({
              coaches: state.coaches.filter(coach => coach.id !== param)
            }))
          }
        })
    }
  };

  editCoach = id => {
    const coach = this.state.coaches.filter(c => c.id === id)[0];
    this.setState({
      email: coach.email,
      first_name: coach.first_name,
      last_name: coach.last_name,
      imgPrev: coach.img,
      img: coach.img,
      coach_subtype: coach.coach_subtype,
      id,
      editingForm: true
    }, () => {window.scroll(0, 0)})
  };

  render() {
    const {
      email,
      first_name,
      last_name,
      img,
      imgPrev,
      coach_subtype,
      coaches,
      errors,
      editingForm,
      modalVisible
    } = this.state;

    const {user} = this.props;
    const values = {email, first_name, last_name, img, imgPrev, coach_subtype};

    if (!user.academy_exists) {
      return (
      <div className="container">
        <h2 className="header2 notCreated">Academy not created</h2>
      </div>
    )}
    return (
      <div className="container">
        <Modal modalVisible={modalVisible} onClick={this.toggleModal}/>
        <CoachForm
          values={values}
          handleChange={this.handleChange}
          handleImgPick={this.handleImgPick}
          submitForm={this.submitForm}
          errors={errors}
          showErrorBorder={this.showErrorBorder}
          editingForm={editingForm}
          _onChangeValueAndConvert={this._onChangeValueAndConvert}
        />
        <br/>
        <div className='minContainer'>
          <CoachList
            data={coaches}
            deleteCoach={this.deleteCoach}
            editCoach={this.editCoach}
          />
        </div>
      </div>
    );
  }
}


export class CoachForm extends Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.submitForm()
  };

  render() {

    const {
      values,
      handleChange,
      handleImgPick,
      errors,
      showErrorBorder,
      _onChangeValueAndConvert
    } = this.props;

    return (
      <div className='minContainer'>
        <form style={styles.form}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <img src={values.img || values.imgPrev || DefaultAvatar} alt="" style={styles.imgPreview}/>
            <label htmlFor="imgInput" style={styles.label}>Choose image for Staff
              <input id='imgInput' type="file"
                     onChange={handleImgPick}
                     style={styles.imgInput}/>
            </label>
          </div>
          <div>
            <input type="text"
                   className='formsInput'
                   placeholder='Name'
                   value={values.first_name}
                   onChange={handleChange('first_name')}
            />
            <input type="text"
                   className='formsInput'
                   placeholder='Surname'
                   value={values.last_name}
                   onChange={handleChange('last_name')}
            />
            <input type="text"
                   className={showErrorBorder('email') ? 'formsInput error' : 'formsInput'}
                   placeholder='Email'
                   value={values.email}
                   onChange={handleChange('email')}

            />
            <select className={showErrorBorder('coach_subtype') ? 'formsSelect error' : 'formsSelect'}
                    value={values.coach_subtype}
                    name='coach_subtype'
                    onChange={_onChangeValueAndConvert}
            >
              <option value="">Staff type</option>
              <option value="1">Coach</option>
              <option value="2">Manager</option>
            </select>
            {errors && errors.email && <p style={styles.error}>{errors.email}</p>}
            <button className="formsButton" onClick={this.handleSubmit}>Save</button>
          </div>
        </form>
      </div>
    );
  }
}

const CoachList = props => {
  const coach_subtypes = {
    1: 'Coach',
    2: 'Manager'
  };
  return props.data.map(({id, email, first_name, last_name, img, coach_subtype}, i) => {
    return (
      <div key={`${first_name}${i}`} style={styles.coachItem}>
        <div style={styles.coachLogo}>
          {img && <img src={img} alt={first_name} style={styles.coachLogoImg}/>}
        </div>
        <div style={styles.coachInfo}>
          <h3>{first_name}</h3>
          <h5>{last_name}</h5>
          <h5>{coach_subtype ? coach_subtypes[coach_subtype] : ''}</h5>
        </div>
        <div>
          <button style={{...styles.coachActionBtn, width: '100%'}} onClick={() => props.editCoach(id)}>Edit</button>
          <button style={{...styles.coachActionBtn, width: '100%',
            backgroundColor: '#ff0000',
            color: '#fff'}} onClick={() => props.deleteCoach(id)}>Delete</button>
        </div>
      </div>
    )
  })
};

export const styles = {
  form: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 10,
    paddingBottom: 10
  },
  imgInput: {
    position: 'absolute',
    zIndex: -1,
    top: -9999
  },
  label: {
    marginRight: 20,
    border: '1px solid #ccc',
    padding: 10,
    width: 220
  },
  imgPreview: {
    width: 150,
    margin: 'auto',
    padding: 10
  },
  error: {
    color: 'red'
  },
  coachItem: {
    border: '2px solid #ccc',
    display: 'flex',
    padding: 20,
    marginBottom: 20
  },
  coachLogo: {
    width: 100,
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  coachLogoImg: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  coachInfo: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center'
  },
  coachActionBtn: {
    // width: '100%',
    borderRadius: '3px',
    backgroundColor: '#fec722',
    padding: 5,
    cursor: 'pointer',
    transition: 'all 0.5s',
    outline: 'none',
    boxShadow: 'none',
    border: 'none',
    marginBottom: 5
  },
  teamBtn: {
    width: 150,
    borderRadius: '3px',
    backgroundColor: '#fec722',
    padding: 5,
    cursor: 'pointer',
    transition: 'all 0.5s',
    outline: 'none',
    boxShadow: 'none',
    border: 'none',
    marginBottom: 5,
    textAlign: 'center'
  }
};