import React, { Component } from 'react';
import '../style/style.css';


class SidebarGames extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 0,
      games: props.games
    }
  }

  render() {
    let data = [], games = [];
    if(this.state.games !== null && this.state.games.length > 0) {
      data = this.state.games[this.state.active];
      games = data.games;
    }

    return (
      <div className="section recent_Result">
        <div className="title_section">
          <h3>Recent Results {data.age}</h3>
          <div className="recent_Navigation">
            <span onClick={() => {
              if (this.state.active > 0) {
                this.setState({active: this.state.active - 1})
              }
            }}><i className="fas fa-chevron-left" /></span>
            <span onClick={() => {
              if (this.state.active < this.state.games.length - 1) {
                this.setState({active: this.state.active + 1})
              }
            }}><i className="fas fa-chevron-right" /></span>
          </div>
        </div>

        <div className="list">
          {games.map((game, index) => (
            <div className="result_item" key={index}>
              <div className="date">
                <span>{game.datetime}</span>
              </div>
              <div className="infoGame">
                <div className="team">
                  <h3>{game.team1}</h3>
                  <div className="logo">
                    <div className="logo_border">
                      <img src={game.team1_logo} alt={game.team1} />
                    </div>
                  </div>
                </div>
                <div className="score">
                  <h3>{game.team1_goals} - {game.team2_goals}</h3>
                </div>
                <div className="team">
                  <div className="logo">
                    <div className="logo_border">
                      <img src={game.team2_logo} alt={game.team2} />
                    </div>
                  </div>
                  <h3>{game.team2}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default SidebarGames;
