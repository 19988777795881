import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import Home from './Home';
import League from './Leagues/League';
import { Contacts } from './Contacts';
import News from './News/News';
import Academy from './Academy/Academy';
import Gallery from './Gallery/Gallery';
import Profile from './Profile/Profile';
import Teams from './Teams/Teams';
import PlayerDetail from './Details/PlayerDetail';
import RefereeDetail from './Details/RefereeDetail';
import NotFound from './NotFound';
import Archive from './Leagues/LeaguesArchive';
import PasswordRecovery from './Profile/PasswordRecovery';
import PasswordReset from './Profile/PasswordReset';
import SimplePage from './SimplePage';

import Modal from '../components/ModalSuccessful';
import { AgreementModal } from "../components/AgreementModal";
import { HeaderNav } from "../components/Base_header/Header_nav";
import { createBrowserHistory } from 'history';

import {
  API,
  authenticate,
  login,
  registration,
  isLoggedIn,
  logout,
  userData,
  getUser,
  fetchData,
  setAgreementPlayer,
  getAgreementPlayer,
} from '../services';

import '../style/style.css';
import { catchErrors } from '../services/catchErrors';

import { OLD_URL, months, days } from "../constants";

export const history = createBrowserHistory();

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = isLoggedIn();
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

class App extends Component {
  constructor() {
    super();
    if(window.location.origin.includes(OLD_URL)) {
      window.location.href = 'https://academysuperleague.com/';
    }
    this.state = {
      isModalOpen: false,
      menuList: false,
      modalVisible: false,
      typeModal: 'not confirmed',
      popUp: 'give Access',
      popUpVisible: false,
      errors: [],

      login_email: '',
      login_password: '',
      error_text: '',

      regist_email: '',
      regist_password: '',
      regist_newPassword: '',
      error_text_registration: '',

      user: {},
      user_type: '',
      type_action: '',
      is_roster_view_allowed: false,
      pages: [],
      months: months,
      days: days,
      loggedIn: false,
    };
  }

  componentDidMount() {
    const user = getUser();
    const loggedIn = isLoggedIn();
    this.setState(
      {
        loggedIn,
        is_roster_view_allowed: user ? user.is_roster_view_allowed : false,
        user_type: user && user.user_type ? user.user_type : '',
        user,
      },
      () => {
        if (
          this.state.loggedIn === true &&
          user &&
          user.user_type &&
          user.user_type === 3
        ) {
          if (getAgreementPlayer() === 'false') {
            this.setState({ popUpVisible: true });
          }
        }
        fetchData(API.pages)
          .then(pages => this.setState({ pages }))
          .catch(e => catchErrors(e));
      }
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    let localUser = getUser();
    if(localUser) {
      if(nextState.user) {
        if(localUser.hasOwnProperty('id') && localUser.id !== nextState.user.id) {
          this.setState({
            user: localUser
          });
        }
      } else {
        this.setState({
          user: localUser
        })
      }
    }
    return true;
  }

  showErrors = (error) => {
    let errors = [];
    this.setState({ error_text: '', error_text_registration: '' });
    if (this.state.type_action === 'sign in') {
      if (error.hasOwnProperty('non_field_errors')) {
        this.setState({ error_text: error.non_field_errors[0], errors: [] });
      } else {
        for (let item in error) {
          errors.push('login_' + item);
          this.setState({ errors: errors, error_text: error[item] });
        }
      }
    }
    if (this.state.type_action === 'registration') {
      if (error.hasOwnProperty('non_field_errors')) {
        this.setState({
          error_text_registration: error.non_field_errors[0],
          errors: [],
        });
      } else {
        for (let item in error) {
          errors.push(item);
          if (item === 'password') {
            errors.push('newPassword');
          }
          this.setState({
            errors: errors,
            error_text_registration: error[item],
          });
        }
      }
    }
  }

  showErrorBorder = (item) => {
    for (let key in this.state.errors) {
      if (this.state.errors[key] === item) {
        return true;
      }
    }
  }

  toggleModal = () =>
    this.setState(state => ({ isModalOpen: !state.isModalOpen }));

  toggleModalForcibly = (value) => {
    this.setState({
      isModalOpen: value
    })
  };

  togglePopUp = () =>
    this.setState(state => ({ popUpVisible: !state.popUpVisible }));

  toggleMenuList = () =>
    this.setState(state => ({ menuList: !state.menuList }));

  logout = () => {
    logout();
    this.setState({
      loggedIn: false,
      is_roster_view_allowed: false
    });
  };

  onChangeValue = (e) => {
    const {name, value} = e.target;

    this.setState({
      [name]: value
    });
  };

  postDataLogin = (event) => {
    event.preventDefault();
    let dataRequest = {
      email: this.state.login_email,
      password: this.state.login_password,
    };
    this.setState({ type_action: 'sign in' });
    authenticate(dataRequest)
      .then(response => {
        this.setState({ error_text: '' });
        if (response.hasOwnProperty('token')) {
          login(response.token);
          userData(response.user);

          this.setState({
            is_roster_view_allowed: response.user.is_roster_view_allowed || false,
            loggedIn: true,
            isModalOpen: false,
            menuList: false,
            login_email: '',
            login_password: '',
            user_type: response.user.user_type,
          });
          setAgreementPlayer(response.user.agreement);
          if (
            response.user.user_type === 3 &&
            response.user.agreement === false
          ) {
            this.setState({
              popUpVisible: true,
            });
          }
        }
      })
      .catch(error => this.showErrors(error));
  }

  postDataRegistration = () => {
    if (this.state.regist_password === this.state.regist_newPassword) {
      this.setState({ type_action: 'registration' });
      let dataRequest = {
        email: this.state.regist_email,
        user_type: parseInt(this.state.user_type, 10),
        password: this.state.regist_password,
      };
      registration(API.registration, dataRequest)
        .then(response => {
          if (response.hasOwnProperty('token')) {
            login(response.token);
            userData(response.user);
            this.setState({
              loggedIn: true,
              isModalOpen: false,
              menuList: false,
              user_type: '',
              errors: [],
              error_text_registration: '',
              regist_email: '',
              regist_password: '',
              regist_newPassword: '',
            });
            setAgreementPlayer(response.user.agreement);
            if (
              response.user.user_type === 3 &&
              response.user.agreement === false
            ) {
              this.setState({
                popUpVisible: true,
              });
            }
          }
        })
        .catch(error => {
          if (error.hasOwnProperty('non_field_errors')) {
            if (error.non_field_errors[0] === 'User account is disabled.') {
              this.setState({
                isModalOpen: false,
                menuList: false,
                modalVisible: true,
                user_type: '',
                errors: [],
                error_text_registration: '',
                regist_email: '',
                regist_password: '',
                regist_newPassword: '',
              });
            }
          } else {
            this.showErrors(error);
          }
        });
    } else {
      this.setState({
        errors: ['password', 'newPassword'],
        error_text_registration: 'Passwords do not match',
      });
    }
  };

  closeModal = () => this.setState({ modalVisible: !this.state.modalVisible });

  render() {
    const {
      loggedIn,
      user_type,
      user,
      login_email,
      login_password,
      regist_email,
      regist_password,
      regist_newPassword,
      error_text,
      error_text_registration,
      is_roster_view_allowed,

      menuList,
      pages,
      isModalOpen,

    } = this.state;

    return (
        <Router>
          <div className="App">

            <Modal
              modalVisible={this.state.modalVisible}
              type={this.state.typeModal}
              onClick={this.closeModal}
            />

            <AgreementModal
              toggleVisible={this.state.popUpVisible}
              togglePopUp={this.togglePopUp}
            />

            <HeaderNav
              toggleMenuList={this.toggleMenuList}
              menuList={menuList}
              pages={pages}
              is_roster_view_allowed={is_roster_view_allowed}
              loggedIn={loggedIn}
              logout={this.logout}
              toggleModal={this.toggleModal}
              isModalOpen={isModalOpen}
              postDataLogin={this.postDataLogin}
              postDataRegistration={this.postDataRegistration}
              user_type={user_type}
              login_email={login_email}
              login_password={login_password}
              regist_email={regist_email}
              regist_password={regist_password}
              regist_newPassword={regist_newPassword}
              error_text={error_text}
              error_text_registration={error_text_registration}
              onChangeValue={this.onChangeValue}
              showErrorBorder={this.showErrorBorder}
              toggleModalForcibly={this.toggleModalForcibly}
            />

            <main>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/contacts" component={Contacts} />
                <Route path="/news" component={News} />
                <Route path="/academies" component={Academy} />
                <Route path="/gallery" component={Gallery} />

                <PrivateRoute
                  path="/profile"
                  component={props => (
                    <Profile
                      loggedIn={loggedIn}
                      user_type={user_type}
                      user={user}
                      {...props}
                    />
                  )}
                />
                <Route path="/teams" component={Teams} />
                <Route path="/player/detail/:id/:teamId" component={PlayerDetail} />
                <Route path="/referee/detail/:id" component={RefereeDetail} />
                <Route path="/league/:id" component={League} />
                <Route path="/archive" component={Archive} />
                <Route path="/password/recovery" component={PasswordRecovery} />
                <Route path="/password/reset/:id" component={PasswordReset} />
                <Route path="/pages/:id" component={SimplePage} />
                <Route component={NotFound} />
              </Switch>
            </main>

            <footer className="page_footer">
              <span>@copyright</span>
            </footer>
          </div>
        </Router>
    );
  }
}

export default App;
