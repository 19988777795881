import React, { Component } from 'react';

import Loader from '../../../components/Loader';

import {API, fetchDetail} from '../../../services/index';


const GameItem = (props) => {
  const { games } = props;
  const gamesList = games.map(({ team1, team2, age, field, field_url, datetime, team1_img, team2_img }, index) => {
    return (
      <div className="game-item-for-referee" key={index}>
          <div className="team-title">
            <img src={team1_img} alt="" />
            <h2>{team1}</h2>
          </div>
          <span className="important-info">{age}</span>
          <span className="place">{field ? <a href={field_url} target="_blank" rel="noopener noreferrer">{field}</a> : '-'}</span>
          <span>{datetime}</span>
          <div className="team-title">
            <img src={team2_img} alt="" />
            <h2>{team2}</h2>
          </div>
      </div>
    )
  });

  return (
    <div className="game-list-for-referee">
      {gamesList}
    </div>
  )
}

const GameList = (props) => {
  const { weeks } = props;
  const list = weeks.map(({ week, games, statistics }, indexlist) => {
    const statistic = statistics.map(({ referee_type, total }, indexstat) => {
      return (
        <span key={indexstat}>{ referee_type }: { total }</span>
      )
    });
    return (
      <div key={indexlist} className="section">
        <h5 className="">{week}</h5>
        <GameItem games = { games } />
        <div className="stats">{statistic}</div>
      </div>
    )
  });
  return (
    <div>
      {list}
    </div>
  );
}

class RefereeGamesStatistic extends Component {

  state = {
    isLoading: true,
    data: ''
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    fetchDetail(API.profile.moderator.statistic, id)
    .then((response) =>this.setState({data: response, isLoading: false}));
  }
  render() {
    if(this.state.isLoading) {
      return (<Loader/>)
    }
    const { first_name, last_name } = this.state.data;
    return (
      <div className="container games-statistic-about-referee">
        <h1>Statistic for {first_name} {last_name}</h1>
        <GameList weeks = { this.state.data.weeks } />
      </div>
    )
  }
}

export default RefereeGamesStatistic;
