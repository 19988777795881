import React, { Component } from 'react';

import {
  Route
} from 'react-router-dom';

import NewsList from '../News/NewsList';
import NewsDetail from '../News/NewsDetail';

class News extends Component {
  render() {
    return (
        <div>
          <Route exact path="/news" component={NewsList}/>
          <Route path="/news/:id" component={NewsDetail}/>
        </div>
    );
  }
}

export default News;
