import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../../components/Loader';
import {API, fetchDetail, getAgesForLeague, getReferees, getUser, patchObject} from '../../services';
import { fetchData } from '../../services/utils';

class TournamentTable extends Component {
  render() {
    const { gender, data, is_roster_view_allowed } = this.props;
    const groupTable = data;
    if (!this.props.unauth) {
      return (
        <div>
          <table
            className={
              groupTable.length > 0
                ? 'leagueTable headerTable unAuth'
                : 'notVisible'
            }
          >
            <thead>
              <tr>
                <th>Team</th>
                <th>Games</th>
                <th>Won</th>
                <th>Draw</th>
                <th>Lost</th>
                <th>GF</th>
                <th>GA</th>
                <th>GD</th>
                <th>Points</th>
              </tr>
            </thead>
          </table>

          <table className="leagueTable unAuth">
            <thead className="noSeeHeader">
              <tr>
                <th>Team</th>
                <th>Games</th>
                <th>Won</th>
                <th>Draw</th>
                <th>Lost</th>
                <th>GF</th>
                <th>GA</th>
                <th>GD</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {groupTable.map((team, index) => (
                <tr key={index}>
                  <td className="TeamLogoName">
                    {is_roster_view_allowed ?
                        <a
                          href={`/teams/${gender}/${team.id}`}
                          className="tournament-table-td-url"
                        >
                          <span className="numberTeam">{index + 1}</span>
                          <div className="logo">
                            <div className="logo_border">
                              <img src={team.logo} alt={team.title} />
                            </div>
                          </div>
                          <h4>{team.title}</h4>
                        </a> :
                        <span
                          // href={`/teams/${gender}/${team.id}`}
                          className="tournament-table-td-url"
                        >
                          <span className="numberTeam">{index + 1}</span>
                          <div className="logo">
                            <div className="logo_border">
                              <img src={team.logo} alt={team.title} />
                            </div>
                          </div>
                          <h4>{team.title}</h4>
                        </span>
                    }

                  </td>
                  <td>{team.played}</td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div>
          <table
            className={
              groupTable.length > 0 ? 'leagueTable headerTable' : 'notVisible'
            }
          >
            <thead>
              <tr>
                <th>Team</th>
                <th>Games</th>
                <th>Won</th>
                <th>Draw</th>
                <th>Lost</th>
                <th>GF</th>
                <th>GA</th>
                <th>GD</th>
                <th>Points</th>
              </tr>
            </thead>
          </table>

          <table className="leagueTable">
            <thead className="noSeeHeader">
              <tr>
                <th>Team</th>
                <th>Games</th>
                <th>Won</th>
                <th>Draw</th>
                <th>Lost</th>
                <th>GF</th>
                <th>GA</th>
                <th>GD</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {groupTable.map((team, index) => (
                <tr key={index}>
                  <td className="TeamLogoName">
                      {is_roster_view_allowed ?
                          <a
                            href={`/teams/${gender}/${team.id}`}
                            className="tournament-table-td-url"
                          >
                            <span className="numberTeam">{index + 1}</span>
                            <div className="logo">
                              <div className="logo_border">
                                <img src={team.logo} alt={team.title} />
                              </div>
                            </div>
                            <h4>{team.title}</h4>
                          </a> :
                          <span
                            // href={`/teams/${gender}/${team.id}`}
                            className="tournament-table-td-url"
                          >
                            <span className="numberTeam">{index + 1}</span>
                            <div className="logo">
                              <div className="logo_border">
                                <img src={team.logo} alt={team.title} />
                              </div>
                            </div>
                            <h4>{team.title}</h4>
                          </span>
                      }
                  </td>
                  <td>{team.played}</td>
                  <td>{team.won}</td>
                  <td>{team.draw}</td>
                  <td>{team.lost}</td>
                  <td>{team.gf}</td>
                  <td>{team.ga}</td>
                  <td>{team.gd}</td>
                  <td>{team.points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
}

class TournamentGames extends Component {
  state = {
    isModalForEditScore: false,
    currentTeam1_name: '',
    currentTeam2_name: '',
    currentTeam1_logo: '',
    currentTeam2_logo: '',
    team1_goals: '',
    team2_goals: '',
    gameID: '',
    errors_text: '',
    errors_fields: []
  };

  toggleModalForScore = () => {
    this.setState(({ isModalForEditScore }) => ({
      isModalForEditScore: !isModalForEditScore,
      errors_fields: [],
      errors_text: ''
    }))
  };

  dataForModal = (
    game
  ) => {
    this.setState({
      gameID: game.id,
      currentTeam1_name: game.team1_short_title,
      currentTeam2_name: game.team2_short_title,
      currentTeam1_logo: game.team1_logo,
      currentTeam2_logo: game.team2_logo,
      team1_goals: game.team1_goals,
      team2_goals: game.team2_goals
    }, () => this.toggleModalForScore())
  };

  onChangeGoals = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    })
  };

  _saveScoreForGame = () => {
    const { gameID, team1_goals, team2_goals } = this.state;
    const {
      getData,
      gender,
      tournamentId,
      currentAgeId,
    } = this.props;

    if(!team1_goals || !team2_goals) {
      let errors_fields = [];
      if(!team1_goals) {
        errors_fields.push('team1_goals');
      }
      if(!team2_goals) {
        errors_fields.push('team2_goals');
      }

      this.setState({
        errors_text: 'Invalid data',
        errors_fields: errors_fields
      });
    } else {
      patchObject(API.league_change_score,{team1_goals: team1_goals, team2_goals: team2_goals }, gameID)
        .then((response) => {
          this.toggleModalForScore();
          getData(tournamentId, currentAgeId, gender);
        })
        .catch((error) => {
            console.warn('error', error);
            let errors_fields = [];
            if(error.hasOwnProperty('team1_goals')) {
              errors_fields.push('team1_goals');
            }
            if(error.hasOwnProperty('team2_goals')) {
              errors_fields.push('team2_goals');
            }
            this.setState({
              errors_text: 'Invalid data',
              errors_fields: errors_fields
            });
          }
        )
    }
  };

  showErrorBorder = item => {
    for (let key in this.state.errors_fields) {
      if (this.state.errors_fields[key] === item) {
        return true;
      }
    }
  };


  setIdReferee(string) {
    let idReferee = '';
    this.props.refereeList.forEach(referee => {
      if (referee.name === string) {
        idReferee = referee.id;
      }
    });
    return idReferee;
  }

  render() {
    const weeks = this.props.data;
    const {
      isModalForEditScore,
      currentTeam1_name,
      currentTeam2_name,
      currentTeam1_logo,
      currentTeam2_logo,
      team1_goals,
      team2_goals,
      errors_text,
      errors_fields
    } = this.state;
    const {
      getData,
      gender,
      tournamentId,
      currentAgeId,
      has_additional_perms,
    } = this.props;

    return (
      <div>
        <ModalScoreForm
          visible={isModalForEditScore}
          toggleEvent={this.toggleModalForScore}
          currentTeam1_name={currentTeam1_name}
          currentTeam2_name={currentTeam2_name}
          currentTeam1_logo={currentTeam1_logo}
          currentTeam2_logo={currentTeam2_logo}
          team1_goals={team1_goals}
          team2_goals={team2_goals}
          onChangeGoals={this.onChangeGoals}
          _saveScoreForGame={this._saveScoreForGame}
          errors_text={errors_text}
          errors_fields={errors_fields}
          showErrorBorder={this.showErrorBorder}
        />
        {weeks.map((week, index) => (
          <div key={index} className="weekTournament">
            <h2>Week {week.week}</h2>
            {week.dates.map((date, idx) => (
              <div key={idx} className="weekGames">
                <h3>
                  <span
                    className={
                      date.week_day === 'Sunday'
                        ? 'color-day'
                        : 'color-day-other'
                    }
                  >
                    {date.week_day}
                  </span>
                  , {date.all_date}
                </h3>
                <div className="gamesList leagueGames">
                  {date.games.map((game, i) => (
                    <div className="game" key={i}>
                      {/*<span onClick={() => getData(tournamentId, currentAgeId, gender)}>CLICK ME</span>*/}
                      <div className="team">
                        <div className="logo">
                          <div className="logo_border">
                            <img src={game.team1_logo} alt="" />
                          </div>
                        </div>
                        <div className="team_information">
                          <h4>{game.team1}</h4>
                          <h4
                            className={
                              game.team1_best_player ? '' : 'notVisible'
                            }
                          >
                            <span>Best Player: </span>
                          </h4>
                          <h4
                            className={
                              game.team1_best_player ? '' : 'notVisible'
                            }
                          >
                            {game.team1_best_player}
                          </h4>
                        </div>
                      </div>
                      <div className="info_game">
                        <h4 className="dateTime">
                          {game.datetime.time} {game.datetime.am_pm}
                        </h4>
                        <h4 className="score bold-score">
                          {game.team1_goals === null ? '' : game.team1_goals}
                          <span>-</span>
                          {game.team2_goals === null ? '' : game.team2_goals}
                        </h4>
                        {has_additional_perms ? <span className='edit_btn_league' onClick={() => this.dataForModal(game)}>
                          Edit
                        </span> : null}
                        <div className="one_column_fix">
                          <h4 className="place">
                            <span>Location: </span>
                            {game.field ? (
                              <a
                                href={game.field_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {game.field}
                              </a>
                            ) : (
                              '-'
                            )}
                          </h4>
                          <h4 className="referee">
                            <span>Referee: </span>
                            {game.referee !== null ? (
                              <span className='notLink_refereeDetail'>{game.referee}</span>
                            ) : (
                              '-'
                            )}
                          </h4>
                        </div>
                      </div>
                      <div className="team">
                        <div className="team_information">
                          <h4>{game.team2}</h4>
                          <h4
                            className={
                              game.team2_best_player ? '' : 'notVisible'
                            }
                          >
                            <span>Best Player: </span>
                          </h4>
                          <h4
                            className={
                              game.team2_best_player ? '' : 'notVisible'
                            }
                          >
                            {game.team2_best_player}
                          </h4>
                        </div>
                        <div className="logo">
                          <div className="logo_border">
                            <img src={game.team2_logo} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

const ModalScoreForm = (props) => {
  const {
    visible,
    toggleEvent,
    currentTeam1_name,
    currentTeam2_name,
    currentTeam1_logo,
    currentTeam2_logo,
    team1_goals,
    team2_goals,
    onChangeGoals,
    _saveScoreForGame,
    errors_text,
    showErrorBorder
  } = props;

  if(!visible) {
    return null
  }

  // return <div className={this.props.modalVisible ? 'bg_modal' : 'notVisible'}>
  return <div className='bg_modal'>
    <div className="modal score_form_league_editor">
      <div
        className="closeSignIn"
      >
        <button
          className="close"
          onClick={() => toggleEvent()}
        >
          <i className="fas fa-times"/>
        </button>
      </div>
      <div className='score_form_row space-btw'>
        <div className='score_form_row first_level'>
          <span>{currentTeam1_name}</span>
          <img src={currentTeam1_logo} alt=''/>
        </div>
        <div className='score_form_row first_level'>
          <img src={currentTeam2_logo} alt=''/>
          <span>{currentTeam2_name}</span>
        </div>
      </div>
      <div className='score_form_row space-btw'>
        <div className='score_form_row pos_center first_level'>
          <input
            className={`formsInput ${showErrorBorder('team1_goals') ? 'error' : ''}`}
            name='team1_goals'
            value={team1_goals || ''}
            onChange={onChangeGoals}
          />
        </div>
        <div className='score_form_row pos_center first_level'>
          <input
            className={`formsInput ${showErrorBorder('team2_goals') ? 'error' : ''}`}
            name='team2_goals'
            value={team2_goals || ''}
            onChange={onChangeGoals}
          />
        </div>
      </div>
      <div className='score_form_row pos_center error_field'>
        {errors_text || ''}
      </div>
      <div className='score_form_row pos_center'>
        <div className='edit_btn_league' onClick={() => _saveScoreForGame()}>Save</div>
      </div>
    </div>
  </div>
};

class LeagueGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentAgeId: props.currentAge,
      tournamentId: props.tournament,
      gender: props.gender,
      title: '',
      weeks: [],
      table: [],
      activeTab: 'table',
      isLoadingPage: true
    };
  }

  getData = (tournamentId, newAge, newGender) => {
    this.setState({
      isLoadingPage: true
    });
    fetchData(`tournaments/${tournamentId}/${newAge}/${newGender}`).then(
      response => {
        this.setState({
          currentAgeId: newAge,
          tournamentId: tournamentId,
          weeks: response.weeks,
          table: response.table,
          show_table: response.show_table,
          isLoadingPage: false
        });
      }
    );
  }

  componentDidMount() {
    this.getData(
      this.state.tournamentId,
      this.state.currentAgeId,
      this.state.gender
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.currentAgeId !== nextProps.currentAge ||
      this.state.tournamentId !== nextProps.tournament ||
      this.state.gender !== nextProps.gender
    ) {
      this.setState({
        currentAgeId: nextProps.currentAge,
        tournamentId: nextProps.tournament,
        gender: nextProps.gender,
      });
      this.getData(
        nextProps.tournament,
        nextProps.currentAge,
        nextProps.gender
      );
    }
  }

  toggleTab = activeTab => this.setState({ activeTab });

  renderTabContent = () => {
    const { activeTab, table, show_table, weeks, gender, tournamentId, currentAgeId } = this.state;
    const { refereeList, has_additional_perms, is_roster_view_allowed } = this.props;
    const resultList = weeks
      .map(week => ({
        ...week,
        dates: week.dates.map(date => ({
          ...date,
          games: date.games.filter(game => {
            let isGoals = false;
            if (game.team1_goals !== null && game.team2_goals !== null) {
              isGoals = true;
            }
            return isGoals;
          }),
        })),
      }))
      .sort((a, b) => b.week - a.week);

    const gamesList = weeks.map(week => ({
      ...week,
      dates: week.dates.map(date => ({
        ...date,
        games: date.games.filter(game => {
          let isGoals = false;
          if (game.team1_goals === null && game.team2_goals === null) {
            isGoals = true;
          }
          return isGoals;
        }),
      })),
    }));

    const gamesListFiltered = gamesList
      .map(week => ({
        ...week,
        dates: week.dates.filter(date => !!date.games.length),
      }))
      .filter(week => week.dates.length);

    const resultListFiltered = resultList
      .map(week => ({
        ...week,
        dates: week.dates.filter(date => !!date.games.length),
      }))
      .filter(week => week.dates.length);

    switch (activeTab) {
      case 'table':
        return (
          <TournamentTable data={table} unauth={show_table} gender={gender} is_roster_view_allowed={is_roster_view_allowed} />
        );
      case 'games':
        return (
          <TournamentGames
            data={gamesListFiltered}
            refereeList={refereeList}
            getData={this.getData}
            gender={gender}
            tournamentId={tournamentId}
            currentAgeId={currentAgeId}
            has_additional_perms={has_additional_perms}
          />
        );
      case 'results':
        return (
          <TournamentGames
            data={resultListFiltered}
            refereeList={refereeList}
            getData={this.getData}
            gender={gender}
            tournamentId={tournamentId}
            currentAgeId={currentAgeId}
            has_additional_perms={has_additional_perms}
          />
        );
      default:
        return (
          <TournamentTable data={table} unauth={show_table} gender={gender} />
        );
    }
  };

  render() {
    const { activeTab, isLoadingPage } = this.state;
    return (
      <div>
        <div className="nextNavigation navigationCalendar">
          <div
            className={`nextTab ${activeTab === 'table' && 'clicked'}`}
            onClick={() => this.toggleTab('table')}
          >
            Table
          </div>
          <div
            className={`nextTab ${activeTab === 'games' && 'clicked'}`}
            onClick={() => this.toggleTab('games')}
          >
            Games
          </div>
          <div
            className={`nextTab ${activeTab === 'results' && 'clicked'}`}
            onClick={() => this.toggleTab('results')}
          >
            Results
          </div>
        </div>
        <div className='loader_part_of_page'>
          <span className={`text ${isLoadingPage ? 'showText' : 'hideText'}`}>Loading...</span>
        </div>
        {this.renderTabContent()}
      </div>
    );
  }
}

class League extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tournamentId: props.match.params.id,
      gender: 1,
      tournamentTitle: '',
      currentAge: null,
      error: true,
      ages: [],
      tournamentDate: null,
      refereeList: [],
      showLeague: null,
      has_additional_perms: false,
      is_roster_view_allowed: false
    };
  }

  componentDidMount() {
    const user = getUser();
    if (
      user &&
      user.has_additional_perms &&
      user.has_additional_perms === true
    ) {
      this.setState({ has_additional_perms: true });
    }
    if (
      user &&
      user.is_roster_view_allowed &&
      user.is_roster_view_allowed === true
    ) {
      this.setState({ is_roster_view_allowed: true });
    }
    this.loadData(this.state.tournamentId);
  }

  loadData(tournamentId) {
    let refereeList = [];

    getReferees()
      .then(referees => {
        refereeList = referees;
        return getAgesForLeague(this.state.tournamentId);
      })
      .then(ages => {
        fetchDetail(API.tournaments, tournamentId).then(league => {
          this.setState({
            isLoading: false,
            error: false,
            tournamentTitle: league.tournament,
            tournamentDate: league.date_period,
            tournamentId: league.id,
            ages: ages,
            refereeList: refereeList,
            showLeague: league.show_league,
            currentAge: this.state.currentAge || (ages.length && ages[0].id),
          });
        });
      })
      .catch(e => this.setState({ isLoading: false }));
  }

  componentWillReceiveProps(nextProps) {
    const params = nextProps.match.params;
    if (this.state.tournamentId !== params.id) {
      this.loadData(params.id);
    }
  }

  setCurrentAge = id => this.setState({ currentAge: id });

  render() {
    const {
      isLoading,
      error,
      showLeague,
      ages,
      tournamentTitle,
      tournamentDate,
      gender,
      currentAge,
      tournamentId,
      refereeList,
      has_additional_perms,
      is_roster_view_allowed
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (error) {
      return (
        <div className="base">
          <div className="emptyHeader" />
          <div className="title">
            <h1 className="header2 container">League not created</h1>
          </div>
          <div className="container" />
        </div>
      );
    }
    if (showLeague) {
      let defaultAge = ages.length && ages[0].id;
      return (
        <div className="base">
          <div className="emptyHeader" />
          <div className="title">
            <h1 className="header2 container">{tournamentTitle}</h1>
            <h4 className="league-date-title container">{tournamentDate}</h4>
          </div>

          <div className="container">
            <div className="league_detail">
              <div className="nextNavigation navigationCalendar">
                <div
                  className={gender === 1 ? 'nextTab clicked' : 'nextTab'}
                  onClick={() =>
                    this.setState({ gender: 1, currentAge: defaultAge })
                  }
                >
                  Boys
                </div>
                <div
                  className={gender === 2 ? 'nextTab clicked' : 'nextTab'}
                  onClick={() =>
                    this.setState({ gender: 2, currentAge: defaultAge })
                  }
                >
                  Girls
                </div>
              </div>
              <div className="nextNavigation">
                <div className="teams_filter">
                  {ages.map((age, index) => (
                    <div
                      key={index}
                      onClick={() => this.setCurrentAge(age.id)}
                      className={
                        currentAge === age.id ? ' nextTab clicked' : 'nextTab'
                      }
                    >
                      {age.title}
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <LeagueGroup
                  currentAge={currentAge}
                  tournament={tournamentId}
                  gender={gender}
                  refereeList={refereeList}
                  has_additional_perms={has_additional_perms}
                  is_roster_view_allowed={is_roster_view_allowed}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="base">
          <div className="emptyHeader" />
          <div className="title">
            <h1 className="header2 container">{tournamentTitle}</h1>
          </div>
          <div className="container">
            <div className="league_detail">
              <h1 className="text-align">
                You are not a player of one of the teams
              </h1>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default League;
